<app-portlet [headerClass]="'kt-portlet__head--noborder'"
             [header]="'Notifications'"
             [mainClass]="'kt-portlet kt-portlet--solid-dark kt-portlet--height-fluid'">
    <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist" toolbar>
        <li class="nav-item">
            <a (click)="filterNotifications('new')"
               [class.active]="filter === 'new'"
               class="nav-link"
               role="tab">
                New
            </a>
        </li>
        <li class="nav-item">
            <a (click)="filterNotifications('read')"
               [class.active]="filter === 'read'"
               class="nav-link"
               role="tab">
                Read
            </a>
        </li>
    </ul>

    <div body class="tab-content">
        <ng-template [ngForOf]="filteredNotifications" let-notification ngFor>
            <div aria-expanded="true" class="tab-pane active">
                <div class="kt-notification">
                    <a [class.kt-notification__item--read]="notification.is_read"
                       class="kt-notification__item">
                        <!-- Content -->
                        <div class="kt-notification__item-details">
                            <div class="kt-notification__item-title">
                                {{notification.message}}
                            </div>
                            <div class="kt-notification__item-time">
                                {{notification.created | formatDate}}
                            </div>
                        </div>

                        <!-- Action -->
                        <div *ngIf="!notification.is_read" class="notification-action">
                            <button (click)="markAsRead(notification)"
                                    [matTooltipPosition]="'above'"
                                    [matTooltipShowDelay]="500"
                                    [matTooltip]="'Mark as Read'"
                                    class="btn btn-dark btn-sm btn-circle btn-icon"
                                    type="button">
                                <i class="flaticon-alert-off"></i>
                            </button>
                        </div>
                    </a>
                </div>
            </div>
        </ng-template>

        <div *ngIf="showNoData" class="no-notifications">
            No notifications
        </div>
    </div>
</app-portlet>