<table class="table">
    <thead>
    <tr>
        <th>#</th>
        <th>Option 1</th>
        <th>Option 2</th>
        <th>1</th>
        <th>2</th>
        <th>3</th>
        <th>4</th>
        <th>5</th>
        <th>6</th>
        <th>7</th>
        <th>8</th>
        <th>9</th>
    </tr>
    </thead>

    <!-- Answers -->
    <tbody>
    <tr *ngFor="let question of questions; let i = index">
        <td scope="row">{{i + 1}}</td>
        <td [class.kt-font-bold]="question[0].id === actions[i].answer">{{question[0].title}}</td>
        <td [class.kt-font-bold]="question[1].id === actions[i].answer">{{question[1].title}}</td>

        <!-- Markers -->
        <td><img [src]="getIcon(actions[i]?.increments, '1')" alt="dot" class="test-answer-marker"/></td>
        <td><img [src]="getIcon(actions[i]?.increments, '2')" alt="dot" class="test-answer-marker"/></td>
        <td><img [src]="getIcon(actions[i]?.increments, '3')" alt="dot" class="test-answer-marker"/></td>
        <td><img [src]="getIcon(actions[i]?.increments, '4')" alt="dot" class="test-answer-marker"/></td>
        <td><img [src]="getIcon(actions[i]?.increments, '5')" alt="dot" class="test-answer-marker"/></td>
        <td><img [src]="getIcon(actions[i]?.increments, '6')" alt="dot" class="test-answer-marker"/></td>
        <td><img [src]="getIcon(actions[i]?.increments, '7')" alt="dot" class="test-answer-marker"/></td>
        <td><img [src]="getIcon(actions[i]?.increments, '8')" alt="dot" class="test-answer-marker"/></td>
        <td><img [src]="getIcon(actions[i]?.increments, '9')" alt="dot" class="test-answer-marker"/></td>
    </tr>
    </tbody>

    <!-- Counts -->
    <tr class="kt-font-bold">
        <td colspan="3">Count</td>
        <td>{{counts['1']}}</td>
        <td>{{counts['2']}}</td>
        <td>{{counts['3']}}</td>
        <td>{{counts['4']}}</td>
        <td>{{counts['5']}}</td>
        <td>{{counts['6']}}</td>
        <td>{{counts['7']}}</td>
        <td>{{counts['8']}}</td>
        <td>{{counts['9']}}</td>
    </tr>

    <!-- Data -->
    <tfoot>
    <tr class="kt-font-bold">
        <td colspan="3">Result</td>
        <td>{{result['1']}}</td>
        <td>{{result['2']}}</td>
        <td>{{result['3']}}</td>
        <td>{{result['4']}}</td>
        <td>{{result['5']}}</td>
        <td>{{result['6']}}</td>
        <td>{{result['7']}}</td>
        <td>{{result['8']}}</td>
        <td>{{result['9']}}</td>
    </tr>
    </tfoot>
</table>