<div class="row">
    <div *ngFor="let emotion of emotions" [class]="choiceClass">
        <div class="kt-section">
            <div class="kt-widget24 kt-padding-0">
                <div class="kt-widget24__details">
                    <div class="kt-widget24__info">
                        <!-- Title -->
                        <a class="kt-widget24__title kt-font-brand">
                            {{emotion.title}}
                        </a>

                        <!-- Description -->
                        <span class="kt-widget24__desc">
                            {{emotion.description}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>