import {CommonUtils} from '../utils/common-utils';

export enum UserRole {
    ADMIN_USER = 'ADMIN_USER',
    BASIC_USER = 'BASIC_USER',
}

export const UserRoleLabels = new Map<string, string>([
    [UserRole.ADMIN_USER, 'Admin User'],
    [UserRole.BASIC_USER, 'Basic User'],
]);

export const UserRoleItems = CommonUtils.getSelectOptions(UserRoleLabels);
