import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-tests-success',
    templateUrl: './tests-success.component.html',
    styleUrls: ['./tests-success.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestsSuccessComponent {
    get description(): string {
        return `That's all! Congratulations on the completed assessment. We hope you enjoyed it. Your responses
        are automatically saved and someone from our profiling team will contact you. Talk to you
        soon!`;
    }

    navigate(): void {
        window.location.href = 'https://symphony.is/';
    }
}
