import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Location} from '@angular/common';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPageComponent {
    @Input() error: string = null;
    @Input() errorData: any = null;
    @Input() title: string = null;

    constructor(private location: Location,
                private sanitizer: DomSanitizer,
                private router: Router) {
    }

    get mailUrl(): SafeUrl {
        const subject = 'EPI Test Error';
        const body = 'Dear Symphony Team, something went wrong while I was doing my EPI test. Please check what is happening. Thank You.'
        return this.sanitizer.bypassSecurityTrustUrl(`mailto:assessment@symphony.is?subject = ${subject}&body = ${body}`);
    }

    back(): void {
        if (this.errorData && this.errorData.bundle) {
            this.router.navigate(['tests', this.errorData.bundle]);
        } else {
            this.location.back();
        }
    }

}
