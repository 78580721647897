import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {State} from '../../../shared/store';
import {DateLimit} from '../../../shared/constants/constants';
import {FormHelper} from '../../../shared/helpers/form-helper';

@Component({
    selector: 'app-test-schedule-modal',
    templateUrl: './test-schedule-modal.component.html',
    styleUrls: ['./test-schedule-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TestScheduleModalComponent {
    public form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder,
                private dialog: MatDialogRef<TestScheduleModalComponent>,
                private store: Store<State>,
                @Inject(MAT_DIALOG_DATA) private data: any) {

        const email = data.candidate ? data.candidate.email : '';
        this.form = this.formBuilder.group({
            email: [email, [Validators.required, Validators.email]],
            when_to_send: ['', Validators.required],
            when_to_send_time: ['', Validators.required],
        });
    }

    get minDate(): Date {
        return DateLimit.MIN;
    }

    get maxDate(): Date {
        return DateLimit.MAX;
    }

    save(): void {
        if (this.form.valid) {
            this.dialog.close({
                result: 'yes',
                data: this.form.getRawValue()
            });
        } else {
            FormHelper.validateAllFormFields(this.form);
        }
    }
}
