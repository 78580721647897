import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-test-base-option',
    templateUrl: './test-base-option.component.html',
    styleUrls: ['./test-base-option.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestBaseOptionComponent {
    @Input() label: string = null;
    @Input() value: any = null;
    @Input() highlight = false;
    @Input() highlightHover = true;
    @Output() selectOption = new EventEmitter<any>();

    select(): void {
        this.selectOption.emit(this.value);
    }
}
