import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ChartOptions} from 'chart.js';
import Chart from 'chart.js/auto';
import {ColorUtils} from '../../../shared/utils/color-utils';

@Component({
    selector: 'app-candidate-test-epi-diagram',
    templateUrl: './candidate-test-epi-diagram.component.html',
    styleUrls: ['./candidate-test-epi-diagram.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateTestEpiDiagramComponent implements OnChanges {
    public polarAreaChart: Chart = null;

    @Input() data: object = null;
    @ViewChild('epiChart', {static: true}) chart: ElementRef;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue) {
            this.drawGraph(changes.data.currentValue);
        }
    }

    drawGraph(result: any) {
        const ctx = this.chart.nativeElement.getContext('2d');

        const mappedData = [
            result['1'],
            result['2'],
            result['3'],
            result['4'],
            result['5'],
            result['6'],
            result['7'],
            result['8'],
            result['9'],
        ];

        const mappedLabels = [
            `Incorporation [${result['1']}%]`,
            `Orientation [${result['2']}%]`,
            `Protection [${result['3']}%]`,
            `Reintegration [${result['4']}%]`,
            `Rejection [${result['5']}%]`,
            `Exploration [${result['6']}%]`,
            `Destruction [${result['7']}%]`,
            `Reproduction [${result['8']}%]`,
            `Bias [${result['9']}%]`
        ];

        if (this.polarAreaChart) {
            this.polarAreaChart.data.datasets[0].data = mappedData;
            this.polarAreaChart.data.labels = mappedLabels;
            this.polarAreaChart.update();
        } else {
            this.polarAreaChart = new Chart(ctx, {
                type: 'polarArea',
                data: {
                    labels: mappedLabels,
                    datasets: [{
                        label: 'EPI',
                        data: mappedData,
                        backgroundColor: ColorUtils.interpolateColorsDefault(9),
                        borderColor: ColorUtils.interpolateColorsDefault(9),
                        borderWidth: 1
                    }]
                },
                options: {
                    plugins: [{
                        afterRender(c: any) {
                            const context2D = c.chart.ctx;
                            context2D.save();
                            /*
                            This line is apparently essential to getting the fill to go behind the drawn graph,
                            not on top of it. Technique is taken from: https://stackoverflow.com/a/50126796/165164
                            */
                            context2D.globalCompositeOperation = 'destination-over';
                            context2D.fillStyle = 'white';
                            context2D.fillRect(0, 0, c.chart.width, c.chart.height);
                            context2D.restore();
                        }
                    }],
                    tooltips: {
                        mode: 'point'
                    },
                    legend: {
                        display: true,
                        position: 'right'
                    },
                    scale: {
                        ticks: {
                            max: 100,
                            min: 0,
                            stepSize: 10
                        }
                    }
                } as ChartOptions
            });
        }
    }
}
