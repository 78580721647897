import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-home-content]',
    templateUrl: './home-content.component.html',
    styleUrls: ['./home-content.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class HomeContentComponent {
    @Input() header: string;
}
