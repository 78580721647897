<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        CTI Test Instructions
                    </h3>
                </div>
            </div>

            <form class="kt-form">
                <div class="kt-portlet__body">
                    <div class="kt-section kt-margin-0">
                        <div class="kt-section__content kt-section__content__text kt-details-text">
                            <p>
                                This test consists of 4 subtests. They look like 4 different games or puzzles and they
                                contain no text – only drawings. Every test has several examples that you can use to
                                practice in order to understand the logic behind it before taking the actual tests.
                            </p>
                            <p>
                                Each of the tests is time-limited and you will move through them fairly quickly – every
                                minute counts.
                            </p>
                            <p>
                                Tasks near the end of each test can be quite difficult, but try to do the best that you
                                can. Tasks that you cannot solve, you can simply skip. If you have time left, you can
                                return to those tasks after completing the rest.
                            </p>
                            <p>In this test, there are no negative points.</p>

                            <div class="alert alert-secondary" role="alert">
                                <div class="alert-icon"><i class="flaticon-information"></i></div>
                                <div class="alert-text">
                                    Please, do not press the <b>Back</b> button on your browser once when you start the
                                    test.
                                </div>
                            </div>

                            <p>
                                Work carefully and quickly. Good luck!
                            </p>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__foot">
                    <div class="kt-form__actions kt-form__actions--right">
                        <div class="row">
                            <div class="col kt-align-left">
                                <button (click)="previousStep()"
                                        class="btn btn-label-danger btn-wide"
                                        type="button">
                                    Back
                                </button>
                            </div>
                            <div class="col kt-align-right">
                                <button (click)="nextStep()"
                                        class="btn btn-label-brand btn-wide"
                                        type="button">
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
