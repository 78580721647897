<div class="row kt-margin-b-40">
    <div class="col-12">
        <canvas #bfqBarChart></canvas>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <canvas #bfqSubBarChart></canvas>
    </div>
</div>