import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TestsService} from '../../../shared/services/tests.service';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import {State} from '../../../shared/store';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {TestData} from '../../../shared/models/test-data';
import {Test} from '../../../shared/models/test';
import {TestBaseContainer} from '../../test-base/test-base-container';

@Component({
    selector: '[app-test-epq-container]',
    templateUrl: './test-epq-container.component.html',
    styleUrls: ['./test-epq-container.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestEpqContainerComponent extends TestBaseContainer implements OnInit {
    constructor(private testsService: TestsService,
                private route: ActivatedRoute,
                store: Store<State>,
                location: Location) {
        super(store, location);
    }

    get code(): string {
        return this.route.snapshot.params.code;
    }

    startTest(): Observable<Test> {
        return this.testsService.startEpqTest(this.code);
    }

    ngOnInit(): void {
        this.route.data
            .subscribe((data: { data: TestData }) => {
                this.handleTestLoad(data, this.code)
            });
    }
}
