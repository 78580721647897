<div class="navigation-items kt-margin-b-25">
    <a (click)="switchTab(0)"
       [class.active]="selectedTab == 0"
       class="navigation-item"
       role="tab">
        <div class="navigation-item-body">
            <div class="navigation-item-label">
                All answers
            </div>
            <div class="navigation-item-number-placeholder"></div>
        </div>
    </a>

    <a [class.active]="selectedTab == 1"
       class="navigation-item"
       role="tab">
        <div (click)="switchTab(1)"
             class="navigation-item-body">
            <div [style.color]="tabColor(1)"
                 class="navigation-item-label">
                E
            </div>

            <div [style.background-color]="tabColor(1)"
                 class="navigation-item-number">
                {{ result.E_normed }}
            </div>
        </div>

        <div class="navigation-item-subsection">
            <div (click)="switchTabSub(1, 1)"
                 [ngStyle]="subtabColor(1, 1)"
                 class="navigation-item-subsection-item kt-margin-b-5">
                DI: <b>{{ result.DI_normed }}</b>
            </div>

            <div (click)="switchTabSub(1, 2)"
                 [ngStyle]="subtabColor(1, 2)"
                 class="navigation-item-subsection-item">
                DO: <b>{{ result.DO_normed }}</b>
            </div>
        </div>
    </a>

    <a [class.active]="selectedTab == 2"
       class="navigation-item"
       role="tab">
        <div (click)="switchTab(2)"
             class="navigation-item-body">
            <div [style.color]="tabColor(2)"
                 class="navigation-item-label">
                U
            </div>

            <div [style.background-color]="tabColor(2)"
                 class="navigation-item-number">
                {{ result.U_normed }}
            </div>
        </div>

        <div class="navigation-item-subsection">
            <div (click)="switchTabSub(2, 3)"
                 [ngStyle]="subtabColor(2, 3)"
                 class="navigation-item-subsection-item kt-margin-b-5">
                KP: <b>{{ result.KP_normed }}</b>
            </div>

            <div (click)="switchTabSub(2, 4)"
                 [ngStyle]="subtabColor(2, 4)"
                 class="navigation-item-subsection-item">
                SR: <b>{{ result.SR_normed }}</b>
            </div>
        </div>
    </a>

    <a [class.active]="selectedTab == 3"
       class="navigation-item"
       role="tab">
        <div (click)="switchTab(3)"
             class="navigation-item-body">
            <div [style.color]="tabColor(3)"
                 class="navigation-item-label">
                S
            </div>

            <div [style.background-color]="tabColor(3)"
                 class="navigation-item-number">
                {{ result.S_normed }}
            </div>
        </div>

        <div class="navigation-item-subsection">
            <div (click)="switchTabSub(3, 5)"
                 [ngStyle]="subtabColor(3, 5)"
                 class="navigation-item-subsection-item kt-margin-b-5">
                TM: <b>{{ result.TM_normed }}</b>
            </div>

            <div (click)="switchTabSub(3, 6)"
                 [ngStyle]="subtabColor(3, 6)"
                 class="navigation-item-subsection-item">
                US: <b>{{ result.US_normed }}</b>
            </div>
        </div>
    </a>

    <a [class.active]="selectedTab == 4"
       class="navigation-item"
       role="tab">
        <div (click)="switchTab(4)"
             class="navigation-item-body">
            <div [style.color]="tabColor(4)"
                 class="navigation-item-label">
                ES
            </div>

            <div [style.background-color]="tabColor(4)"
                 class="navigation-item-number">
                {{ result.ES_normed }}
            </div>
        </div>

        <div class="navigation-item-subsection">
            <div (click)="switchTabSub(4, 7)"
                 [ngStyle]="subtabColor(4, 7)"
                 class="navigation-item-subsection-item kt-margin-b-5">
                EK: <b>{{ result.EK_normed }}</b>
            </div>

            <div (click)="switchTabSub(4, 8)"
                 [ngStyle]="subtabColor(4, 8)"
                 class="navigation-item-subsection-item">
                KI: <b>{{ result.KI_normed }}</b>
            </div>
        </div>
    </a>

    <a [class.active]="selectedTab == 5"
       class="navigation-item"
       role="tab">
        <div (click)="switchTab(5)"
             class="navigation-item-body">
            <div [style.color]="tabColor(5)"
                 class="navigation-item-label">
                MO
            </div>

            <div [style.background-color]="tabColor(5)"
                 class="navigation-item-number">
                {{ result.MO_normed }}
            </div>
        </div>

        <div class="navigation-item-subsection">
            <div (click)="switchTabSub(5, 9)"
                 [ngStyle]="subtabColor(5, 9)"
                 class="navigation-item-subsection-item kt-margin-b-5">
                OK: <b>{{ result.OK_normed }}</b>
            </div>

            <div (click)="switchTabSub(5, 10)"
                 [ngStyle]="subtabColor(5, 10)"
                 class="navigation-item-subsection-item">
                OI: <b>{{ result.OI_normed }}</b>
            </div>
        </div>
    </a>

    <a (click)="switchTab(6)"
       [class.active]="selectedTab == 6"
       class="navigation-item"
       role="tab">
        <div class="navigation-item-body">
            <div [style.color]="tabColor(6)"
                 class="navigation-item-label">
                L
            </div>

            <div [style.background-color]="tabColor(6)"
                 class="navigation-item-number">
                {{ result.L_normed }}
            </div>
        </div>
    </a>
</div>

<table class="table">
    <thead>
    <tr>
        <th>#</th>
        <th>Question</th>
        <th>Answer</th>
    </tr>
    </thead>

    <!-- Answers -->
    <tbody>
    <tr *ngFor="let question of selectedQuestionIndexes">
        <td class="question-order" scope="row">{{question}}</td>
        <td>{{mapQuestion(question)}}</td>
        <td class="question-answer">{{mapAnswer(question)}}</td>
    </tr>
    </tbody>
</table>