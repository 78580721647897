<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Terms and Conditions
                    </h3>
                </div>
            </div>

            <div class="kt-portlet__body test-epi-terms">
                <!-- Form -->
                <form class="kt-form">
                    <div class="kt-section">
                        <div class="kt-section__content kt-section__content__text kt-details-text">
                            Symphony Group uses the personal information of candidates in the selection process to make
                            employment decisions and assess development potentials.
                            The personal information collected through this processing is used solely for the above
                            purpose.
                            It is our responsibility to protect this information.
                            <br>
                            <br>
                            The provision of personal information is voluntary.
                            Withholding some of the information may lead to misevaluation of the candidate for the job
                            for which they are applying.
                        </div>
                    </div>

                    <!-- I Agree -->
                    <div class="form-group kt-form__group form-group-last">
                        <mat-checkbox (change)="handleSelection($event)">
                            I confirm that I have read the above and that I agree to the Terms and Conditions
                        </mat-checkbox>
                    </div>
                </form>

                <!-- Form -->
                <div *ngIf="agree" class="row">
                    <div class="col-sm-8 offset-2">
                        <form [formGroup]="form" class="kt-form">
                            <!-- Separator -->
                            <div class="kt-separator kt-separator--border-dashed kt-separator--portlet-fit"></div>

                            <!-- Pin -->
                            <div class="form-group kt-form__group">
                                <input class="form-control"
                                       formControlName="pin"
                                       maxlength="8"
                                       minlength="8"
                                       placeholder="Enter PIN"
                                       type="text">

                                <app-validation-error [control]="form.controls.pin"></app-validation-error>
                            </div>

                            <div class="form-group kt-form__group form-group-last">
                                <re-captcha [siteKey]="siteKey"
                                            formControlName="captcha">
                                </re-captcha>

                                <app-validation-error
                                        [control]="form.controls.captcha"></app-validation-error>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="kt-portlet__foot">
                <div class="kt-form__actions kt-form__actions--right">
                    <div class="row">
                        <div class="col kt-align-left">
                            <button (click)="leavePage()"
                                    class="btn btn-label-danger btn-wide"
                                    type="button">
                                Let Me Out
                            </button>
                        </div>
                        <div class="col kt-align-right">
                            <button (click)="nextStep()"
                                    [disabled]="!this.agree"
                                    class="btn btn-label-brand btn-wide btn-test"
                                    type="button">
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
