import {Candidate} from '../models/candidate';
import {TestBundle} from '../models/test-bundle';

export class SearchUtils {
    static searchCandidate(candidate: Candidate, search: string): boolean {
        if (candidate) {
            if (search) {
                const location = candidate.hub ? (candidate.hub.name || '') : '';
                return [
                    candidate.code || '', candidate.email || '', location
                ].some(item => {
                    return item.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                });
            }
            return true;
        }
        return false;
    }

    static searchTestBundle(bundle: TestBundle, search: string): boolean {
        if (bundle) {
            if (search) {
                const candidate = bundle.candidate ? (bundle.candidate.code || '') : '';
                return [
                    bundle.search_code || '', candidate
                ].some(item => {
                    return item.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                });
            }
            return true;
        }
        return false;
    }
}
