import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {Candidate} from '../../../shared/models/candidate';
import {LogUtils} from '../../../shared/utils/log-utils';
import {Test} from '../../../shared/models/test';
import {TestType} from '../../../shared/enums/test-type';
import {CommonUtils} from '../../../shared/utils/common-utils';
import {TestBundle} from '../../../shared/models/test-bundle';
import {Duration} from 'moment';

@Component({
    selector: 'app-candidate-test-epi',
    templateUrl: './candidate-test-epi.component.html',
    styleUrls: ['./candidate-test-epi.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateTestEpiComponent {
    public selectedTab = 0;

    @Input() candidate: Candidate = null;
    @Input() selectedBundle = 0;

    get test(): Test {
        return this.bundle && this.bundle.tests ? this.bundle.tests.find(test => test.type === TestType.EPI) : {} as Test;
    }

    get bundle(): TestBundle {
        if (this.candidate && CommonUtils.isNotEmpty(this.candidate.bundles)) {
            return this.candidate.bundles[this.selectedBundle];
        }
        return {} as TestBundle;
    }

    /**
     * result: { actions: object, counts: object, result: object}
     */
    get result(): { actions: object, counts: object, result: object } {
        try {
            return JSON.parse(this.test.result);
        } catch (e) {
            LogUtils.error(`Parse error for results: ${e.message}`);
            return null;
        }
    }

    get diagramData(): any {
        const result = this.result;
        return result ? result.result : {};
    }

    get actions(): { answer: string, increments: [] }[] {
        const result = this.result;
        return result ? result.actions as { answer: string, increments: [] }[] : [];
    }

    get counts(): any {
        const result = this.result;
        return result ? result.counts : {};
    }

    get analysis(): any {
        const result = this.result;
        return result ? result.result : {};
    }

    get renderData(): boolean {
        return this.actions && this.actions.length > 0;
    }

    get duration(): Duration {
        return CommonUtils.calculateDuration(this.test);
    }

    switchTab(index): void {
        this.selectedTab = index || 0;
    }
}
