import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {EpqQuestions} from '../../../shared/constants/constants';

@Component({
    selector: 'app-candidate-test-epq-answers',
    templateUrl: './candidate-test-epq-answers.component.html',
    styleUrls: ['./candidate-test-epq-answers.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateTestEpqAnswersComponent {
    @Input() answers = [];
    @Input() selectedTab = 0;

    get selectedQuestionIndexes(): number[] {
        if (this.selectedTab === 1) {
            return [1, 5, 8, 10, 17, 21, 25, 29, 32, 36, 42, 45, 49, 52, 56, 60, 64, 66, 70, 73, 75, 78, 82, 86, 89];
        } else if (this.selectedTab === 2) {
            return [0, 4, 9, 13, 16, 20, 24, 28, 31, 35, 39, 41, 44, 48, 51, 55, 59, 63, 69, 81, 85]
        } else if (this.selectedTab === 3) {
            return [2, 5, 11, 14, 18, 22, 26, 30, 33, 37, 40, 46, 53, 57, 61, 65, 67, 71, 74, 76, 79, 83, 87]
        } else if (this.selectedTab === 4) {
            return [3, 7, 12, 15, 19, 23, 27, 34, 38, 43, 47, 50, 54, 58, 62, 68, 72, 77, 80, 84, 88]
        }
        return Array.from({length: 90}, (x, i) => i)
    }

    mapQuestion(index: number): string {
        return EpqQuestions[index].title;
    }

    mapAnswer(index: number): string {
        const answer = this.answers[index];
        return answer === 'True' ? 'Yes' : 'No';
    }
}
