import {Injectable, NgZone} from '@angular/core';
import {User} from '../models/user';
import {Endpoint} from '../constants/constants';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {State} from '../store';
import {CreateUserAction, LoadUsersAction, UpdateUserAction} from '../store/actions/users.actions';
import {StorageHelper} from '../helpers/storage-helper';
import {Router} from '@angular/router';
import {LogUtils} from '../utils/log-utils';
import {NotificationsService} from './notifications.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private http: HttpClient,
                private store: Store<State>,
                private router: Router,
                private zone: NgZone,
                private notificationsService: NotificationsService) {
    }

    fetchUsers(): void {
        this.http
            .get<User[]>(Endpoint.USERS)
            .subscribe((data: User[]) => {
                this.store.dispatch(new LoadUsersAction(data));
            });
    }

    fetchCurrentUserInfo(): Observable<User> {
        return this.http.get<User>(Endpoint.USER_INFO);
    }


    createUser(user: User): void {
        if (user) {
            this.http
                .post<User>(Endpoint.USERS, user)
                .subscribe((data: User) => {
                    this.store.dispatch(new CreateUserAction(data));
                    this.notifyUser(data, 'created');
                });
        } else {
            LogUtils.error('User object is empty');
        }
    }

    updateUser(user: User): void {
        if (user && user.id) {
            this.http
                .put<User>(Endpoint.USERS.concat(user.id.toString()), user)
                .subscribe((data: User) => {
                    this.store.dispatch(new UpdateUserAction(data));
                    this.notifyUser(data, 'updated');
                });
        } else {
            LogUtils.error('User id or object is empty');
        }
    }

    updateUserStatus(user: User, active: boolean): void {
        if (user && user.id) {
            const data = {id: user.id, is_active: active};
            this.http
                .put<User>(Endpoint.USERS.concat(user.id.toString()), data)
                .subscribe(() => {
                    const updated = Object.assign({}, user, data);
                    this.store.dispatch(new UpdateUserAction(updated));
                    this.notifyUser(user, 'disabled');
                });
        } else {
            LogUtils.error('User id or object is empty');
        }
    }

    logout(): void {
        StorageHelper.clear();
        /* Fix for issue with routing when home component stays active for login route. Don't c/p */
        this.zone.run(() => this.router.navigate(['/login']));
    }

    notifyUser(user: User, action: string): void {
        this.notificationsService.success('Success', `User ${user.email} ${action}.`);
    }
}
