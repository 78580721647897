import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Candidate} from '../models/candidate';
import {CandidatesService} from '../services/candidates.service';

@Injectable()
export class CandidateResolve implements Resolve<Candidate> {
    constructor(private candidatesService: CandidatesService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.candidatesService
            .fetchCandidate(route.params.id)
            .pipe(
                map(data => {
                    if (data) {
                        return data;
                    } else {
                        this.router.navigate(['/']);
                        return false;
                    }
                }),
                first()
            );
    }
}
