import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {DashboardService} from '../../../shared/services/dashboard.service';
import {Store} from '@ngrx/store';
import {getNotifications, getStatistics, State} from '../../../shared/store';
import {Notification} from '../../../shared/models/notification';
import {DashboardStatistic} from '../../../shared/models/dashboard-statistic';

const scheduleTask = Promise.resolve(null);

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    public notifications: Observable<Notification[]>;
    public statistics: Observable<DashboardStatistic>;

    constructor(private store: Store<State>,
                private dashboardService: DashboardService) {
        this.notifications = store.select(getNotifications);
        this.statistics = store.select(getStatistics);
    }

    ngOnInit() {
        scheduleTask.then(() => {
            this.dashboardService.fetchNotifications();
            this.dashboardService.fetchStatistics();
        });
    }
}
