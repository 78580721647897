<!-- Subheader -->
<div [header]="'Candidates'" app-home-subheader>
    <div class="kt-subheader__breadcrumbs" header>
        <a class="kt-subheader__breadcrumbs-home">
            <i class="flaticon2-shelter"></i>
        </a>
        <span class="kt-subheader__breadcrumbs-separator"></span>
        <a class="kt-subheader__breadcrumbs-link">
            Preview Candidates
        </a>
    </div>
</div>

<!-- Section -->
<div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <app-portlet [headerClass]="'kt-portlet__head--noborder'"
                 [header]="'Candidates'"
                 [icon]="'kt-font-brand flaticon-interface-3'">

        <!-- Toolbar -->
        <div class="kt-portlet__head-actions" toolbar>
            <a (click)="export()"
               class="btn btn-elevate btn-secondary btn-icon-sm btn-wide mr-2">
                <i class="la la-download"></i>
                Export
            </a>

            <a (click)="createCandidate()"
               class="btn btn-brand btn-elevate btn-icon-sm kt-font-inverse-primary">
                <i class="la la-plus"></i>
                New Candidate
            </a>
        </div>

        <!-- Body -->
        <div body>
            <div class="row">
                <div class="col-md-9">
                    <div class="kt-input-icon kt-input-icon--right kt-subheader__search">
                        <input [formControl]="generalFilter"
                               class="form-control"
                               placeholder="Search by code, email or location ..."
                               type="text">
                        <span class="kt-input-icon__icon kt-input-icon__icon--right">
                            <span>
                                <svg class="kt-svg-icon" height="24px" version="1.1" viewBox="0 0 24 24"
                                     width="24px" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                        <rect height="24" id="bound" width="24" x="0" y="0"></rect>
                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                              fill="#000000" fill-rule="nonzero" id="Path-2"
                                              opacity="0.3"></path>
                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                              fill="#000000" fill-rule="nonzero"
                                              id="Path"></path>
                                    </g>
                                </svg>
                            </span>
                        </span>
                    </div>
                </div>

                <!-- Status -->
                <div class="col-md-3">
                    <div class="form-group kt-form__group">
                        <mat-select [formControl]="stateFilter"
                                    class="form-control kt-input "
                                    placeholder="Filter by status">
                            <mat-option [value]="null">Any</mat-option>
                            <mat-option *ngFor="let status of statuses" [value]="status.key">
                                {{status.label}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>

            <div class="row form-group">
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="kt-input-icon kt-input-icon--right">
                                <input [formControl]="startControl"
                                       [matDatepicker]="startPicker"
                                       class="form-control"
                                       readonly>

                                <span (click)="startPicker.open()"
                                      class="kt-input-icon__icon kt-input-icon__icon--right">
                                    <span>
                                        <i class="flaticon-calendar-2 icon-md"></i>
                                    </span>
                                </span>

                                <mat-datepicker #startPicker [startAt]="startDate" ngDefaultControl></mat-datepicker>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="kt-input-icon kt-input-icon--right">
                                <input [formControl]="endControl"
                                       [matDatepicker]="endPicker"
                                       class="form-control"
                                       readonly>

                                <span (click)="endPicker.open()" class="kt-input-icon__icon kt-input-icon__icon--right">
                                    <span>
                                        <i class="flaticon-calendar-2 icon-md"></i>
                                    </span>
                                </span>

                                <mat-datepicker #endPicker [startAt]="endDate" ngDefaultControl></mat-datepicker>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 text-right pr-0">
                    <a (click)="search()"
                       class="btn candidate-date-range-btn btn-elevate btn-icon-sm kt-font-inverse-primary">
                        <i class="la la-search"></i>
                        Search
                    </a>
                </div>
            </div>

            <div class="candidate-table-container">
                <mat-table [dataSource]="dataSource" class="candidate-table" matSort>
                    <!-- Code Column -->
                    <ng-container matColumnDef="code">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Code</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.code}}</mat-cell>
                    </ng-container>

                    <!-- Gender Column -->
                    <ng-container matColumnDef="gender">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Gender</mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.gender | mapEnum:'Gender'}}</mat-cell>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.email}}</mat-cell>
                    </ng-container>

                    <!-- Hub Column -->
                    <ng-container matColumnDef="hub">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.hub?.name}}</mat-cell>
                    </ng-container>

                    <!-- Status -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <span [innerHtml]="getStatusTag(row)"></span>
                        </mat-cell>
                    </ng-container>

                    <!-- Created -->
                    <ng-container matColumnDef="created">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.created | date: 'dd.MM.yyyy HH:mm'}}</mat-cell>
                    </ng-container>

                    <!-- Actions -->
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <!-- Update -->
                            <a (click)="updateCandidate($event, row)"
                               [matTooltipPosition]="'above'"
                               [matTooltipShowDelay]="500"
                               [matTooltip]="'Edit Candidate'"
                               class="btn btn-sm btn-clean btn-icon btn-icon-md btn-in-table">
                                <i class="la la-pencil"></i>
                            </a>

                            <!-- Change Status -->
                            <a (click)="updateStatusCandidate($event, row)"
                               [matTooltipPosition]="'above'"
                               [matTooltipShowDelay]="500"
                               [matTooltip]="'Change Candidate Status'"
                               class="btn btn-sm btn-clean btn-icon btn-icon-md btn-in-table">
                                <i class="la la-refresh"></i>
                            </a>

                            <!-- Delete -->
                            <a (click)="deleteCandidate($event, row)"
                               [matTooltipPosition]="'above'"
                               [matTooltipShowDelay]="500"
                               [matTooltip]="'Delete Candidate'"
                               class="btn btn-sm btn-clean btn-icon btn-icon-md btn-in-table">
                                <i class="la la-close"></i>
                            </a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row (click)="selectCandidate(row.id)"
                             *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                </mat-table>

                <div [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'"
                     class="candidate-no-results">
                    No candidate found matching filter.
                </div>

                <mat-paginator #paginator
                               [length]="dataSource.filteredData.length"
                               [pageIndex]="0"
                               [pageSizeOptions]="[5, 10, 25, 100]"
                               [pageSize]="25">
                </mat-paginator>
            </div>
        </div>
    </app-portlet>
</div>
