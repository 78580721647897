import {CommonUtils} from '../utils/common-utils';

export enum TestBundleStatus {
    CREATED = 'CREATED',
    SENDING = 'SENDING',
    SENT = 'SENT',
    STARTED = 'STARTED',
    FINISHED = 'FINISHED',
    SCHEDULED = 'SCHEDULED',
    ERROR = 'ERROR'
}

export const TestBundleStatusLabels = new Map<string, string>([
    [TestBundleStatus.CREATED, 'Created'],
    [TestBundleStatus.SENDING, 'Sending'],
    [TestBundleStatus.SENT, 'Sent'],
    [TestBundleStatus.STARTED, 'Started'],
    [TestBundleStatus.FINISHED, 'Finished'],
    [TestBundleStatus.ERROR, 'Error Occurred'],
    [TestBundleStatus.SCHEDULED, 'Scheduled'],
]);

export const TestBundleStatusItems = CommonUtils.getSelectOptions(TestBundleStatusLabels);
