<!-- Title -->
<div class="candidate-modal-title" mat-dialog-title>
    <h5>Send Email</h5>
</div>

<!-- Content -->
<mat-dialog-content class="candidate-modal-content">
    <div [formGroup]="form" class="kt-form kt-form--label-align-right">
        <div class="form-group form-group-last">
            <div class="alert alert-secondary" role="alert">
                <div class="alert-icon">
                    <i class="flaticon-warning kt-font-brand"></i>
                </div>
                <div class="alert-text">
                    A test bundle with a uniquely generated URL code will be sent to the address below.
                </div>
            </div>
        </div>
        <!-- Email -->
        <div class="form-group kt-form__group">
            <input class="form-control kt-input "
                   formControlName="email"
                   maxlength="255"
                   placeholder="Enter Email"
                   type="email">

            <app-validation-error [control]="form.controls.email"></app-validation-error>
        </div>
    </div>
</mat-dialog-content>

<!-- Footer -->
<mat-dialog-actions align="end">
    <button class="btn btn-secondary btn-wide" mat-dialog-close>Cancel</button>
    <button (click)="save()" class="btn btn-brand btn-wide">Send</button>
</mat-dialog-actions>