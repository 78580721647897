import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {Integrations} from '@sentry/tracing';
import * as Sentry from '@sentry/angular';

if (environment?.sentry?.enabled) {
    Sentry.init({
        dsn: environment.sentry.dsn,
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: environment.sentry.tracingOrigins,
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        tracesSampleRate: 1.0,
    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
