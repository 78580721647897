<div *ngIf="renderData" class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head--noborder">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                BFQ Test
                <small><i class="fa flaticon-stopwatch"></i> {{duration | formatDuration}}</small>
            </h3>
        </div>

        <div class="kt-portlet__head-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
                <li class="nav-item">
                    <a (click)="switchTab(0)"
                       [class.active]="selectedTab == 0"
                       class="nav-link"
                       role="tab">
                        Graph View
                    </a>
                </li>
                <li class="nav-item">
                    <a (click)="switchTab(1)"
                       [class.active]="selectedTab == 1"
                       class="nav-link"
                       role="tab">
                        Answers
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="kt-portlet__body">
        <mat-tab-group [selectedIndex]="selectedTab" body class="candidate-tab-group">
            <!-- Diagram -->
            <mat-tab class="candidate-diagram-tab">
                <app-candidate-test-bfq-diagram [result]="analysis"></app-candidate-test-bfq-diagram>
            </mat-tab>

            <!-- Answers -->
            <mat-tab class="candidate-answers-tab">
                <app-candidate-test-bfq-answers [answers]="answers"
                                                [result]="analysis">
                </app-candidate-test-bfq-answers>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div *ngIf="!renderData" class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head--noborder">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                BFQ Test
            </h3>
        </div>
    </div>

    <!-- No Data -->
    <div class="kt-portlet__body">
        <div class="alert alert-secondary alert--marginless" role="alert">
            <div class="alert-icon">
                <i class="flaticon-notes kt-font-dark"></i>
            </div>

            <div class="alert-text">
                No test data
            </div>
        </div>
    </div>
</div>