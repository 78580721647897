import {Component, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {Candidate} from '../../../shared/models/candidate';
import {CandidateModalComponent} from '../candidate-modal/candidate-modal.component';
import {HubsService} from '../../../shared/services/hubs.service';
import {ModalOptions} from '../../../shared/constants/constants';
import {CandidatesService} from '../../../shared/services/candidates.service';
import {MatDialog} from '@angular/material/dialog';
import {Report} from '../../../shared/reports/report';
import {ReportHelper} from '../../../shared/reports/report-helper';
import {TestType, TestTypeLabels} from '../../../shared/enums/test-type';

@Component({
    selector: 'app-candidate-info',
    templateUrl: './candidate-info.component.html',
    styleUrls: ['./candidate-info.component.css']
})
export class CandidateInfoComponent {
    @Input() candidate: Candidate = null;
    @Input() currentTestType = TestType.EPI;
    @Output() testSelected = new EventEmitter<TestType>();

    public testTypeLabels = TestTypeLabels;

    constructor(private dialog: MatDialog,
                private hubsService: HubsService,
                private candidatesService: CandidatesService,
                private renderer: Renderer2) {

    }

    printCandidate(): void {
        const getEpiCanvas = () => {
            try {
                return this.renderer.selectRootElement('app-candidate-test-epi-diagram canvas');
            } catch (error) {
                return null;
            }
        };

        if (this.candidate) {
            const report = new Report('Candidate Information');
            /* Info */
            report.printCandidateInfo(ReportHelper.mapCandidateData(this.candidate));

            /* EPI Canvas*/
            report.printEpiChart(getEpiCanvas());

            /* Save Report */
            report.save(this.candidate.code + '.pdf');
        }
    }

    updateCandidate(): void {
        /* Fetch Data */
        this.hubsService.fetchHubs();

        /* Open Edit Modal */
        const dialogRef = this.dialog.open(CandidateModalComponent, this.modalConfigurationCandidate(this.candidate));
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.candidatesService.updateCandidate(response.data);
            }
        });
    }

    modalConfigurationCandidate(candidate: Candidate): any {
        return Object.assign({}, ModalOptions.CANDIDATE, {
            data: candidate
        });
    }

    selectTest(type: TestType): void {
        this.currentTestType = type;
        this.testSelected.emit(type);
    }
}
