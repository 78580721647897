<div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
         style="background-image: url(assets/media/bg/bg-3.jpg);">
        <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div class="kt-login__container">
                <div class="kt-login__logo">
                    <img src="assets/media/logos/symphony-logo.png">
                </div>

                <div class="kt-login__signin">
                    <div class="kt-login__head">
                        <h3 class="kt-login__title">Sign In To Admin</h3>
                    </div>
                    <form class="kt-form">
                        <div class="kt-login__actions">
                            <asl-google-signin-button size='large' type='standard'></asl-google-signin-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
