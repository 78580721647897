import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
    selector: 'app-doughnut-chart',
    templateUrl: './doughnut-chart.component.html',
    styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements OnChanges {
    public doughnutChart: Chart = null;

    @Input() data: { data: number[], labels: string[], colors: string[] } = null;
    @ViewChild('doughnutChart', {static: true}) chart: ElementRef;

    prepareChart(data: { data: number[], labels: string[], colors: string[] }): void {
        /* Remove previous chart */
        if (this.doughnutChart != null) {
            this.doughnutChart.destroy();
        }

        const ctx = this.chart.nativeElement.getContext('2d');

        this.doughnutChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: data.data,
                    backgroundColor: data.colors
                }],
                labels: data.labels,
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                responsive: true
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue) {
            this.prepareChart(changes.data.currentValue);
        }
    }
}
