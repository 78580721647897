import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormHelper} from '../../../shared/helpers/form-helper';
import {Observable} from 'rxjs';
import {Hub} from '../../../shared/models/hub';
import {Store} from '@ngrx/store';
import {getHubs, State} from '../../../shared/store';

@Component({
    selector: 'app-user-modal',
    templateUrl: './user-modal.component.html',
    styleUrls: ['./user-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UserModalComponent {
    public form: UntypedFormGroup;
    public hubs: Observable<Hub[]>;

    constructor(private formBuilder: UntypedFormBuilder,
                private dialog: MatDialogRef<UserModalComponent>,
                private store: Store<State>,
                @Inject(MAT_DIALOG_DATA) private data: any) {

        this.hubs = store.select(getHubs);

        this.form = this.formBuilder.group({
            id: this.data.id,
            email: [data.email, [Validators.required, Validators.email]],
            role: [data.role, Validators.required],
            hub_id: [data.hub ? data.hub.id : null, Validators.required],
        }, {
            validators: symphonyEmailRequired
        });
    }

    save(): void {
        if (this.form.valid) {
            this.dialog.close({
                result: 'yes',
                data: this.form.getRawValue()
            });
        } else {
            FormHelper.validateAllFormFields(this.form);
        }
    }
}

export const symphonyEmailRequired: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const email = control.get('email').value;

    if (!email?.endsWith('@symphony.is')) {
        control.get('email').setErrors({symphonyEmailRequired: true});
    } else {
        return null;
    }
};
