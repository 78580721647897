import {ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {getUsers, State} from '../../../shared/store';
import {Router} from '@angular/router';
import {UsersService} from '../../../shared/services/users.service';
import {map} from 'rxjs/operators';
import {User} from '../../../shared/models/user';
import {CommonUtils} from '../../../shared/utils/common-utils';
import {ModalOptions} from '../../../shared/constants/constants';
import {merge, Observable} from 'rxjs';
import {DataSource} from '@angular/cdk/table';
import {CollectionViewer} from '@angular/cdk/collections';
import {UserModalComponent} from '../user-modal/user-modal.component';
import {HubsService} from '../../../shared/services/hubs.service';
import {ConfirmModalComponent} from '../../../shared/components/modals/confirm-modal/confirm-modal.component';
import {SortUtils} from '../../../shared/utils/sort-utils';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';

const scheduleTask = Promise.resolve(null);

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent implements OnInit {
    public dataSource: UserDataSource | null;
    public db: UserDatabase;
    public displayedColumns = ['email', 'role', 'hub', 'status', 'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(private store: Store<State>,
                private router: Router,
                private dialog: MatDialog,
                private usersService: UsersService,
                private hubsService: HubsService) {
        this.db = new UserDatabase(store);
    }

    ngOnInit() {
        this.dataSource = new UserDataSource(this.db, this.paginator, this.sort);

        scheduleTask.then(() => {
            this.usersService.fetchUsers();
        });
    }

    getStateTag(user: User): string {
        if (user) {
            if (user.is_active) {
                return '<span class="kt-badge kt-badge--success kt-badge--inline">Active</span>';
            }
            return '<span class="kt-badge kt-badge--danger kt-badge--inline">Deactivated</span>';
        }
        return '';
    }

    createUser(): void {
        /* Fetch Data */
        this.hubsService.fetchHubs();

        /* Show modal */
        const dialogRef = this.dialog.open(UserModalComponent, this.modalConfigurationUser({} as User));
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.usersService.createUser(response.data);
            }
        });
    }

    updateUser(event: Event, user: User): void {
        /* Prevent propagation */
        CommonUtils.preventPropagation(event);

        /* Fetch Data */
        this.hubsService.fetchHubs();

        /* Open Edit Modal */
        const dialogRef = this.dialog.open(UserModalComponent, this.modalConfigurationUser(user));
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.usersService.updateUser(response.data);
            }
        });
    }

    disableUser(event: Event, user: User): void {
        /* Prevent propagation */
        CommonUtils.preventPropagation(event);

        /* Enable user */
        const data = {
            title: 'Disable',
            message: `Are you sure you want to disable ${user.email}?`
        };
        const dialogRef = this.dialog.open(ConfirmModalComponent, this.modalConfigurationConfirm(data));
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'yes') {
                this.usersService.updateUserStatus(user, false);
            }
        });
    }

    enableUser(event: Event, user: User): void {
        /* Prevent propagation */
        CommonUtils.preventPropagation(event);

        /* Enable user */
        const data = {
            title: 'Enable',
            message: `Are you sure you want to enable ${user.email}?`
        };
        const dialogRef = this.dialog.open(ConfirmModalComponent, this.modalConfigurationConfirm(data));
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'yes') {
                this.usersService.updateUserStatus(user, true);
            }
        });
    }

    modalConfigurationConfirm(data: any): any {
        return Object.assign({}, ModalOptions.CONFIRM, {data});
    }

    modalConfigurationUser(user: User): any {
        return Object.assign({}, ModalOptions.USER, {
            data: user
        });
    }
}

export class UserDatabase {
    public users: Observable<User[]>;
    public usersData: User[] = [];

    constructor(private store: Store<State>) {
        this.users = store.select(getUsers);
        this.users.subscribe(data => this.usersData = data);
    }
}

export class UserDataSource extends DataSource<User> {
    public filteredData: User[] = [];
    public renderedData: User[] = [];

    constructor(private db: UserDatabase,
                private paginator: MatPaginator,
                private sort: MatSort) {
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<User[]> {
        const displayDataChanges = [
            this.db.users,
            this.sort.sortChange,
            this.paginator.page,
        ];

        //noinspection TypeScriptValidateTypes
        return merge<any>(...displayDataChanges).pipe(map(() => {
            // Filter data
            this.filteredData = this.db.usersData.slice();

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this.paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        /* Nothing */
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: User[]): User[] {
        if (!this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const direction = this.sort.direction === 'asc' ? 1 : -1;
            switch (this.sort.active) {
                case 'email':
                    return direction * SortUtils.stringComparator(a.email, b.email);
                case 'role':
                    return direction * SortUtils.stringComparator(a.role, b.role);
                case 'hub':
                    return direction * SortUtils.stringComparator(a.hub.name, b.hub.name);
            }
            return 0;
        });
    }
}
