<!-- Bundle selection -->
<app-candidate-bundle-menu (bundleSelected)="selectTestBundle($event)"
                           *ngIf="showBundleMenu | async"
                           [bundle]="selectedBundle | async"
                           [candidate]="candidate | async">
</app-candidate-bundle-menu>

<!-- Subheader -->
<div [header]="'Candidates'" app-home-subheader>
    <div class="kt-subheader__breadcrumbs" header>
        <a class="kt-subheader__breadcrumbs-home">
            <i class="flaticon2-shelter"></i>
        </a>
        <span class="kt-subheader__breadcrumbs-separator"></span>
        <a class="kt-subheader__breadcrumbs-link">
            Preview Candidates
        </a>
    </div>
</div>

<!-- Body -->
<div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

    <!--Begin::App-->
    <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
        <!--Begin:: App Aside-->
        <div class="kt-grid__item">
            <app-candidate-info
                    (testSelected)="selectTestType($event)"
                    [candidate]="candidate | async">
            </app-candidate-info>
        </div>

        <!--Begin:: App Content-->
        <div *ngIf="currentTestType == testTypes.EPI"
             class="kt-grid__item kt-grid__item--fluid kt-app__content">
            <app-candidate-test-epi [candidate]="candidate | async"
                                    [selectedBundle]="selectedBundle | async">
            </app-candidate-test-epi>
        </div>

        <div *ngIf="currentTestType == testTypes.CTI"
             class="kt-grid__item kt-grid__item--fluid kt-app__content">
            <app-candidate-test-cti [candidate]="candidate | async"
                                    [selectedBundle]="selectedBundle | async">
            </app-candidate-test-cti>
        </div>

        <div *ngIf="currentTestType == testTypes.EPQ"
             class="kt-grid__item kt-grid__item--fluid kt-app__content">
            <app-candidate-test-epq [candidate]="candidate | async"
                                    [selectedBundle]="selectedBundle | async">

            </app-candidate-test-epq>
        </div>

        <div *ngIf="currentTestType == testTypes.BFQ"
             class="kt-grid__item kt-grid__item--fluid kt-app__content">
            <app-candidate-test-bfq [candidate]="candidate | async"
                                    [selectedBundle]="selectedBundle | async">

            </app-candidate-test-bfq>
        </div>
        <!--End:: App Content-->
    </div>
</div>


