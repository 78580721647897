import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {State} from '../store';
import {Endpoint} from '../constants/constants';
import {LogUtils} from '../utils/log-utils';
import {NotificationsService} from './notifications.service';
import {TestBundle} from '../models/test-bundle';
import {
    CreateTestBundleAction,
    DeleteTestBundleAction,
    LoadTestBundlesAction
} from '../store/actions/test-bundles.actions';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TestBundlesService {
    constructor(private http: HttpClient,
                private store: Store<State>,
                private notificationsService: NotificationsService) {
    }

    fetchTestBundleByCode(code: string, access: string): Observable<TestBundle> {
        return this.http.get<TestBundle>(Endpoint.TESTS_VALIDATE_ACCESS.concat(`${code}/${access}`));
    }

    fetchTestBundles(): void {
        this.http
            .get<TestBundle[]>(Endpoint.TEST_BUNDLES)
            .subscribe((data: TestBundle[]) => {
                this.store.dispatch(new LoadTestBundlesAction(data));
            });
    }

    fetchTestBundlesSending(): Observable<TestBundle[]> {
        return this.http.get<TestBundle[]>(Endpoint.TEST_BUNDLES_SENDING)
    }

    createTestBundle(bundle: TestBundle): void {
        if (bundle) {
            this.http
                .post<TestBundle>(Endpoint.TEST_BUNDLES, bundle)
                .subscribe((data: TestBundle) => {
                    this.store.dispatch(new CreateTestBundleAction(data));
                    this.notifyUser(data, 'created');
                });
        } else {
            LogUtils.error('Test object is empty');
        }
    }

    validateTestBundle(code: string, pin: string, captcha: string): Observable<TestBundle> {
        if (code && pin && captcha) {
            return this.http.post<TestBundle>(
                Endpoint.TESTS_VALIDATE_CODE.concat(code),
                {pin, captcha}
            );
        } else {
            LogUtils.error('Missing some of the parameters');
        }
    }

    deleteTestBundle(bundle: TestBundle): void {
        if (bundle && bundle.id) {
            this.http
                .delete(Endpoint.TEST_BUNDLES.concat(bundle.id.toString()))
                .subscribe(() => {
                    this.store.dispatch(new DeleteTestBundleAction(bundle));
                    this.notifyUser(bundle, 'deleted');
                });
        } else {
            LogUtils.error('Test bundle id or object is empty');
        }
    }

    notifyUser(bundle: TestBundle, action: string): void {
        this.notificationsService.success('Success', `Test bundle ${bundle.search_code} ${action}.`);
    }
}
