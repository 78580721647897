import {Action} from '@ngrx/store';
import {TestBundleData} from '../../models/test-bundle-data';
import {TestData} from '../../models/test-data';

export enum TestsActionTypes {
    Load = '[Tests] Load Tests Section',
    LoadOne = '[Tests] Load Test Section',
    SelectOne = '[Tests] Select Test Section',
}

export class LoadTestsSectionAction implements Action {
    readonly type = TestsActionTypes.Load;

    constructor(public payload: TestBundleData) {
    }
}

export class LoadTestSectionAction implements Action {
    readonly type = TestsActionTypes.LoadOne;

    constructor(public payload: TestData) {
    }
}

export class SelectTestSectionAction implements Action {
    readonly type = TestsActionTypes.SelectOne;

    constructor(public payload: string) {
    }
}

export type Actions
    = LoadTestsSectionAction
    | LoadTestSectionAction
    | SelectTestSectionAction;

