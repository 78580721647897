import {Pipe, PipeTransform} from '@angular/core';
import {YesNoLabels} from '../enums/yes-no';
import {UserRoleLabels} from '../enums/user-role';
import {GenderLabels} from '../enums/gender';
import {CandidateStatusLabels} from '../enums/candidate-status';
import {TestTypeLabels} from '../enums/test-type';
import {TestStatusLabels} from '../enums/test-status';
import {TestBundleStatusLabels} from '../enums/test-bundle-status';
import {TestErrorLabels} from '../enums/test-error';

@Pipe({
    name: 'mapEnum'
})
export class MapEnumPipe implements PipeTransform {
    transform(value: any, enumName: string): any {
        if (value && enumName) {
            switch (enumName) {
                case 'YesNo':
                    return YesNoLabels.get(value);
                case 'UserRole':
                    return UserRoleLabels.get(value);
                case 'Gender':
                    return GenderLabels.get(value);
                case 'CandidateStatus':
                    return CandidateStatusLabels.get(value);
                case 'TestType':
                    return TestTypeLabels.get(value);
                case 'TestStatus':
                    return TestStatusLabels.get(value);
                case 'TestBundleStatus':
                    return TestBundleStatusLabels.get(value);
                case 'TestError':
                    return TestErrorLabels.get(value);
            }
        }
        return null;
    }
}
