<div class="kt-input-icon m-input-icon--left candidate-search">
    <mat-chip-list [required]="required" class="mat-chip-list-stacked">
        <!-- Selected Users -->
        <mat-chip (removed)="remove($event)"
                  *ngIf="selected"
                  [removable]="!readonly"
                  [selectable]="!readonly"
                  class="candidate-chip">
            <span class="candidate-chip-label">{{formatCandidateData(selected)}}</span>
            <span *ngIf="!readonly" class="candidate-chip-icon" matChipRemove><i class="la la-close"></i></span>
        </mat-chip>
    </mat-chip-list>

    <!-- Input -->
    <input *ngIf="!selected"
           [formControl]="searchControl"
           [matAutocomplete]="tagAutocomplete"
           class="form-control kt-input "
           placeholder="Search Candidates"
           type="text">

    <span *ngIf="!selected" class="kt-input-icon__icon kt-input-icon__icon--right">
        <span><i class="la la-search"></i></span>
    </span>

    <mat-autocomplete #tagAutocomplete="matAutocomplete" (optionSelected)="select($event)">
        <mat-option *ngFor="let candidate of filteredCandidates | async" [value]="candidate">
            [{{candidate.code}}] {{candidate.email}}
        </mat-option>
    </mat-autocomplete>
</div>