/* tslint:disable:component-selector */
import {ActivatedRoute} from '@angular/router';
import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CtiTests} from '../../../shared/constants/constants';
import {
    getTestSectionError,
    getTestSectionErrorData,
    getTestSectionSection,
    getTestSectionTest,
    State
} from '../../../shared/store';
import {LoadTestSectionAction, SelectTestSectionAction} from '../../../shared/store/actions/tests.actions';
import {Location} from '@angular/common';
import {BehaviorSubject, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {TestData} from '../../../shared/models/test-data';
import {TestsService} from '../../../shared/services/tests.service';
import {StorageHelper} from 'src/app/shared/helpers/storage-helper';
import {Test} from '../../../shared/models/test';
import {TestState} from '../../../shared/models/test-state';

@Component({
    selector: '[app-test-cti-container]',
    templateUrl: './test-cti-container.component.html',
    styleUrls: ['./test-cti-container.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class TestCtiContainerComponent implements OnInit {
    public error: Observable<string>;
    public errorData: Observable<any>;
    public section: Observable<string>;
    public test: Observable<Test>;
    public subtest = new BehaviorSubject<number>(0);
    public subtestStart = new BehaviorSubject<Date>(null);

    constructor(private testsService: TestsService,
                private location: Location,
                private store: Store<State>,
                private route: ActivatedRoute) {
        this.error = store.select(getTestSectionError);
        this.errorData = store.select(getTestSectionErrorData);
        this.section = store.select(getTestSectionSection);
        this.test = store.select(getTestSectionTest);
    }

    get code(): string {
        return this.route.snapshot.params.code;
    }

    get testQuantity(): number {
        return CtiTests.length;
    }

    get testDone(): boolean {
        return this.subtest.value && this.subtest.value >= this.testQuantity;
    }

    ngOnInit(): void {
        this.route.data
            .subscribe((result: { data: TestData }) => {
                /* Load data */
                this.store.dispatch(new LoadTestSectionAction(result.data));

                /* Check current subtest and switch to that one */
                if (result.data && result.data.test) {
                    this.prepareTest(result.data.test);
                    this.subtest.next(result.data.test.current_subtest);
                    this.subtestStart.next(result.data.test.current_subtest_started);
                }
            });
    }

    prepareTest(test: Test): void {
        const testState = StorageHelper.getCurrentTestState(test.url_code, test.current_subtest);
        /* If there is something in local storage, load it  */
        if (testState) {
            this.store.dispatch(new SelectTestSectionAction(testState.state));
        } else if (test.current_subtest_started) {
            StorageHelper.setCurrentTestState({
                code: test.url_code,
                subtest: test.current_subtest,
                state: 'examples',
                answers: []
            } as TestState);
            this.store.dispatch(new SelectTestSectionAction('questions'));
        } else if (test.current_subtest && test.current_subtest >= 0) {
            StorageHelper.setCurrentTestState({
                code: test.url_code,
                subtest: test.current_subtest,
                state: 'examples',
                answers: []
            } as TestState);
            this.store.dispatch(new SelectTestSectionAction('examples'));
        }
    }

    handleInstructionsSelection(option: string): void {
        if (option) {
            if (option === 'next') {
                this.store.dispatch(new SelectTestSectionAction('examples'));
            } else if (option === 'previous') {
                this.location.back();
            }
        }
    }

    handleExamplesSelection(option: string): void {
        if (option) {
            if (option === 'next') {
                this.testsService.startCtiTest(this.code, this.subtest.value).subscribe(() => {
                    /* Store test in local storage and mark that it started */
                    StorageHelper.createOrUpdateCurrentTestState(this.code, this.subtest.value, {state: 'questions'});
                    this.store.dispatch(new SelectTestSectionAction('questions'));
                });
            } else if (option === 'previous') {
                this.location.back();
            }
        }
    }

    handleQuestionsSelection(nextTest: number): void {
        if (nextTest !== null && nextTest !== undefined) {
            /* Emit next step */
            this.subtest.next(nextTest);
            this.subtestStart.next(null);

            /* Clear all test data */
            if (this.testDone) {
                StorageHelper.clearTestData(this.code);
            }

            this.store.dispatch(new SelectTestSectionAction(this.testDone ? 'done' : 'examples'));
        }
    }
}
