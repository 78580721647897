import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {CandidateModalComponent} from '../../section/candidates/candidate-modal/candidate-modal.component';
import {HubsService} from '../../shared/services/hubs.service';
import {ModalOptions} from '../../shared/constants/constants';
import {CandidatesService} from '../../shared/services/candidates.service';
import {TestModalComponent} from '../../section/tests/test-modal/test-modal.component';
import {TestBundlesService} from '../../shared/services/test-bundles.service';
import {UserModalComponent} from '../../section/users/user-modal/user-modal.component';
import {UsersService} from '../../shared/services/users.service';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-home-actions',
    templateUrl: './home-actions.component.html',
    styleUrls: ['./home-actions.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class HomeActionsComponent {

    constructor(private bottomSheetRef: MatBottomSheetRef<HomeActionsComponent>,
                private dialog: MatDialog,
                private hubsService: HubsService,
                private candidatesService: CandidatesService,
                private testBundlesService: TestBundlesService,
                private usersService: UsersService) {
    }

    get modalConfigurationCandidate(): any {
        return Object.assign({}, ModalOptions.CANDIDATE, {
            data: {}
        });
    }

    get modalConfigurationTestBundle(): any {
        return Object.assign({}, ModalOptions.TEST, {
            data: {}
        });
    }

    get modalConfigurationUser(): any {
        return Object.assign({}, ModalOptions.USER, {
            data: {}
        });
    }

    createCandidate(): void {
        /* Fetch Data */
        this.hubsService.fetchHubs();

        /* Dismiss panel */
        this.bottomSheetRef.dismiss();

        /* Show modal */
        const dialogRef = this.dialog.open(CandidateModalComponent, this.modalConfigurationCandidate);
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.candidatesService.createCandidate(response.data);
            }
        });
    }

    createTestBundle(): void {
        /* Fetch Data */
        this.candidatesService.fetchSearchCandidates();

        /* Dismiss panel */
        this.bottomSheetRef.dismiss();

        /* Show modal */
        const dialogRef = this.dialog.open(TestModalComponent, this.modalConfigurationTestBundle);
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.testBundlesService.createTestBundle(response.data);
            }
        });
    }

    createUser(): void {
        /* Fetch Data */
        this.hubsService.fetchHubs();

        /* Dismiss panel */
        this.bottomSheetRef.dismiss();

        /* Show modal */
        const dialogRef = this.dialog.open(UserModalComponent, this.modalConfigurationUser);
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.usersService.createUser(response.data);
            }
        });
    }
}
