import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {CtiPiece} from '../../../shared/models/cti-piece';
import {CtiPuzzle} from '../../../shared/models/cti-puzzle';
import {ctiPieceEmpty, CtiTests} from '../../../shared/constants/constants';

@Component({
    selector: 'app-test-cti-puzzle',
    templateUrl: './test-cti-puzzle.component.html',
    styleUrls: ['./test-cti-puzzle.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestCtiPuzzleComponent implements OnInit {
    @Input() puzzle: CtiPuzzle = null;
    @Input() answer: CtiPiece[] = [];
    @Input() solution: CtiPiece[] = null;
    @Input() interactive = true;
    @Input() subtest = 0;
    @Input() answers: CtiPiece[][] = [];
    @Output() puzzleAnswered = new EventEmitter<CtiPiece[]>();

    emptyPiece: CtiPiece = ctiPieceEmpty;

    get addEmptySample(): boolean {
        return this.subtest === 0;
    }

    get padEdges(): boolean {
        return this.subtest > 0;
    }

    get selections(): number {
        return CtiTests[this.subtest].selections;
    }

    isSampleX2_5(sample: CtiPiece): boolean {
        const puzzle = sample.puzzle;
        return puzzle && puzzle.subtest === 2 && puzzle.index < 5;
    }

    isSampleX3_5(sample: CtiPiece): boolean {
        const puzzle = sample.puzzle;
        return puzzle && puzzle.subtest === 2 && puzzle.index >= 5;
    }

    isSelected(option: CtiPiece): boolean {
        return this.interactive ? this.answer.includes(option) :
            this.solution.includes(option);
    }

    isCorrect(option: CtiPiece): boolean {
        return Array.isArray(this.solution) && this.solution.includes(option);
    }

    isIncorrect(option: CtiPiece): boolean {
        return Array.isArray(this.solution) && !this.solution.includes(option);
    }

    selectOption(option: CtiPiece): void {
        if (!this.interactive || this.answer.includes(option)) {
            return;
        }
        this.answer.push(option);
        if (this.answer.length > this.selections) {
            this.answer.shift();
        }
        if (this.answer.length === this.selections) {
            this.puzzleAnswered.emit(this.answer);
        }
    }

    ngOnInit(): void {
        if (this.answers[this.puzzle.index]) {
            this.answer = this.answer.concat(this.answers[this.puzzle.index]);
        }
    }
}
