<!-- Subheader -->
<div [header]="'Tests'" app-home-subheader>
    <div class="kt-subheader__breadcrumbs" header>
        <a class="kt-subheader__breadcrumbs-home">
            <i class="flaticon2-shelter"></i>
        </a>
        <span class="kt-subheader__breadcrumbs-separator"></span>
        <a class="kt-subheader__breadcrumbs-link">
            Preview Tests
        </a>
    </div>
</div>

<!-- Section -->
<div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
    <app-portlet [headerClass]="'kt-portlet__head--noborder'"
                 [header]="'Tests'"
                 [icon]="'kt-font-brand flaticon-trophy'">

        <!-- Toolbar -->
        <div class="kt-portlet__head-actions" toolbar>
            <a (click)="createTestBundle()" class="btn btn-brand btn-elevate btn-icon-sm kt-font-inverse-primary">
                <i class="la la-plus"></i>
                New Test Bundle
            </a>
        </div>

        <!-- Body -->
        <div body>
            <div class="row">
                <div class="col-md-9">
                    <div class="kt-input-icon kt-input-icon--right kt-subheader__search">
                        <input [formControl]="generalFilter"
                               class="form-control"
                               placeholder="Search by code or candidate ..."
                               type="text">
                        <span class="kt-input-icon__icon kt-input-icon__icon--right">
                            <span>
                                <svg class="kt-svg-icon" height="24px" version="1.1" viewBox="0 0 24 24"
                                     width="24px" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                        <rect height="24" id="bound" width="24" x="0" y="0"></rect>
                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                              fill="#000000" fill-rule="nonzero" id="Path-2"
                                              opacity="0.3"></path>
                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                              fill="#000000" fill-rule="nonzero"
                                              id="Path"></path>
                                    </g>
                                </svg>
                            </span>
                        </span>
                    </div>
                </div>

                <!-- Status -->
                <div class="col-md-3">
                    <div class="form-group kt-form__group">
                        <mat-select [formControl]="stateFilter"
                                    class="form-control kt-input "
                                    placeholder="Filter by status">
                            <mat-option [value]="null">Any</mat-option>
                            <mat-option *ngFor="let status of statuses" [value]="status.key">
                                {{status.label}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>

            <div class="test-table-container">
                <mat-table [dataSource]="dataSource"
                           class="test-table"
                           matSort
                           multiTemplateDataRows>

                    <!-- Toggle -->
                    <ng-container matColumnDef="toggle">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <svg [class.rotate]="isExpandedRow(0, row)" class="kt-svg-icon small-icon" height="24px"
                                 version="1.1"
                                 viewBox="0 0 24 24" width="24px"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                    <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
                                    <path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                          fill="#000000" fill-rule="nonzero" id="Path-94"
                                          transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "/>
                                </g>
                            </svg>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="toggleEmpty">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row"></mat-cell>
                    </ng-container>

                    <!-- Code Column -->
                    <ng-container matColumnDef="code">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Code</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.search_code}}</mat-cell>
                    </ng-container>

                    <!-- Candidate Code Column -->
                    <ng-container matColumnDef="candidate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Candidate</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <a (click)="preventPropagation($event)"
                               [routerLink]="createCandidateUrl(row.candidate)"
                               class="kt-link--brand">
                                {{row.candidate?.code}}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <!-- Tests Column -->
                    <ng-container matColumnDef="testInfo">
                        <mat-header-cell *matHeaderCellDef>Tests</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{formatTestShortInfo(row.tests)}}</mat-cell>
                    </ng-container>

                    <!-- Status -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <span [innerHtml]="getStatusTag(row)"></span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="statusEmpty">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row"></mat-cell>
                    </ng-container>

                    <!-- Number of tries -->
                    <ng-container matColumnDef="numberOfTries">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Number of tries</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.number_of_tries}}</mat-cell>
                    </ng-container>

                    <!-- Created -->
                    <ng-container matColumnDef="created">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.created | date: 'dd.MM.yyyy HH:mm'}}</mat-cell>
                    </ng-container>

                    <!-- >Is Reminder Send -->
                    <ng-container matColumnDef="isReminderSend">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Is Reminder Send</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <ng-container *ngIf="row.is_reminder_send; else noSend">Yes</ng-container>
                            <ng-template #noSend>No</ng-template>
                        </mat-cell>
                    </ng-container>

                    <!-- Actions -->
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <!-- Send Email -->
                            <a (click)="sendEmailTestBundle($event, row)"
                               [class.disabled]="row.status !== 'CREATED' && row.status !== 'SCHEDULED' && row.status !== 'ERROR'"
                               [matTooltipPosition]="'above'"
                               [matTooltipShowDelay]="500"
                               [matTooltip]="'Send Email'"
                               class="btn btn-sm btn-clean btn-icon btn-icon-md btn-in-table">
                                <i class="la la-envelope"></i>
                            </a>

                            <!-- Schedule Email -->
                            <a (click)="scheduleEmailTestBundle($event, row)"
                               [class.disabled]="row.status !== 'CREATED' && row.status !== 'SCHEDULED' && row.status !== 'ERROR'"
                               [matTooltipPosition]="'above'"
                               [matTooltipShowDelay]="500"
                               [matTooltip]="'Schedule Email'"
                               class="btn btn-sm btn-clean btn-icon btn-icon-md btn-in-table">
                                <i class="la la-clock-o"></i>
                            </a>

                            <!-- Delete -->
                            <a (click)="deleteTestBundle($event, row)"
                               [matTooltipPosition]="'above'"
                               [matTooltipShowDelay]="500"
                               [matTooltip]="'Delete Test Bundle'"
                               class="btn btn-sm btn-clean btn-icon btn-icon-md btn-in-table">
                                <i class="la la-close"></i>
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actionsEmpty">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row"></mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="tests">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <div class="kt-widget1">
                                <div *ngFor="let test of row.tests" class="kt-widget1__item">
                                    <div class="kt-widget1__info">
                                        <!-- Title -->
                                        <h3 class="kt-widget1__title">
                                            {{formatTestInfo(test)}}
                                        </h3>
                                    </div>
                                    <span [class]="getTaskStatusClass(test)">
                                        {{test.status | mapEnum: 'TestStatus'}}
                                    </span>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!-- Header -->
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                    <!-- Bundle Row -->
                    <mat-row (click)="selectTestBundle(row)"
                             *matRowDef="let row; columns: displayedColumns;"
                             [class.border-bottom-transparent]="isExpandedRow(0, row)">
                    </mat-row>

                    <!-- Bundle Test Row -->
                    <mat-row
                            *matRowDef="let row; columns: displayedColumnsDetails; when: isExpandedRow">
                    </mat-row>
                </mat-table>

                <div [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'"
                     class="test-no-results">
                    No test found matching filter.
                </div>

                <mat-paginator #paginator
                               [length]="dataSource.filteredData.length"
                               [pageIndex]="0"
                               [pageSizeOptions]="[5, 10, 25, 100]"
                               [pageSize]="25">
                </mat-paginator>
            </div>
        </div>
    </app-portlet>
</div>
