<app-portlet [bodyClass]="'kt-portlet__body kt-padding-t-0'"
             [headerClass]="'kt-portlet__head--noborder'"
             [header]="'Statistics'">
    <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist" toolbar>
        <li class="nav-item">
            <a (click)="selectPeriod('all')"
               [class.active]="filter === 'all'"
               class="nav-link"
               role="tab">
                All
            </a>
        </li>
        <li class="nav-item">
            <a (click)="selectPeriod('last_year')"
               [class.active]="filter === 'last_year'"
               class="nav-link"
               role="tab">
                1y
            </a>
        </li>
        <li class="nav-item">
            <a (click)="selectPeriod('last_six_months')"
               [class.active]="filter === 'last_six_months'"
               class="nav-link"
               role="tab">
                6m
            </a>
        </li>
        <li class="nav-item">
            <a (click)="selectPeriod('last_three_months')"
               [class.active]="filter === 'last_three_months'"
               class="nav-link"
               role="tab">
                3m
            </a>
        </li>
        <li class="nav-item">
            <a (click)="selectPeriod('last_month')"
               [class.active]="filter === 'last_month'"
               class="nav-link"
               role="tab">
                1m
            </a>
        </li>
    </ul>

    <div body class="tab-content">
        <div class="row row-no-padding row-col-separator-lg">
            <div class="col-lg-6">
                <app-dashboard-statistic-box
                        [description]="'Candidates grouped by hub location'"
                        [title]="'Hubs'">

                    <div body>
                        <app-doughnut-chart [data]="selectedHubData"></app-doughnut-chart>
                    </div>
                </app-dashboard-statistic-box>
            </div>
            <div class="col-lg-6">
                <app-dashboard-statistic-box
                        [description]="'Candidates grouped by status'"
                        [title]="'Status'">

                    <div body>
                        <app-doughnut-chart [data]="selectedStatusData"></app-doughnut-chart>
                    </div>
                </app-dashboard-statistic-box>
            </div>
        </div>
    </div>
</app-portlet>