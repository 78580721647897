<!-- Subheader -->
<div [header]="'Dashboard'" app-home-subheader>
    <div class="kt-subheader__breadcrumbs" header>
        <a class="kt-subheader__breadcrumbs-home">
            <i class="flaticon2-shelter"></i>
        </a>
        <span class="kt-subheader__breadcrumbs-separator"></span>
        <a class="kt-subheader__breadcrumbs-link">
            Preview Dashboard
        </a>
    </div>
</div>

<!-- Section -->
<div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <div class="row">
        <div class="col-xl-8">
            <app-dashboard-statistic [data]="statistics | async"></app-dashboard-statistic>
        </div>

        <div class="col-xl-4">
            <app-dashboard-notifications [notifications]="notifications | async"></app-dashboard-notifications>
        </div>
    </div>
</div>