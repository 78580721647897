<div class="kt-details-text">
    <p>
        Take a look at <b>example 1</b>. On the left side, there are four squares and the last one is empty.
        Your task is to understand the pattern and cipher which one from the six images on the right side should appear
        next.
        In the first three pictures back on the left side, the tree is increasingly leaning from one square to the
        other.
        The third picture from the right side goes into the empty square on the left side because that tree is tilted
        even
        more than the tree in the last square before the empty one.
    </p>

    <app-test-cti-puzzle
            [interactive]="false"
            [puzzle]="examples[0]"
            [solution]="solutions[0]"
            [subtest]="0">
    </app-test-cti-puzzle>

    <p class="kt-padding-t-20">
        <b>Example 2.</b> The black part gets lower in each square.
        So in the next square, the black part will cover more than half of the square.
        Choose a solution that you think is correct.
    </p>

    <app-test-cti-puzzle
            [puzzle]="examples[1]"
            [solution]="solutions[1]"
            [subtest]="0">
    </app-test-cti-puzzle>
    <p>
        If the square turns red, it means you have selected a wrong answer,
        so try again until you find the right answer. The correct answer will be green.
    </p>

    <p class="kt-padding-t-20">
        <b>Example 3.</b> As you can see, the flower petals are spreading, step by step.
        In the third square, it grows to three and starts at the top, so how many petals of flowers should be in the
        empty
        square?
        Please mark your answer
    </p>

    <app-test-cti-puzzle
            [puzzle]="examples[2]"
            [solution]="solutions[2]"
            [subtest]="0">
    </app-test-cti-puzzle>

    <p class="kt-padding-t-20">
        When you click “Start Test,” you will start working on the actual Test 1.
        Since you are no longer practicing, you will not be told whether you selected the correct or wrong answer.
        When you select an answer, it will turn blue, and you can change it as many times as you want.
        This test contains 13 tasks. The time allocated for this test is limited and will not be displayed while you’re
        taking the test.
        Remember, you have just a few minutes and you need to be as accurate and quick as possible.
    </p>

    <p>
        If the time expires, the test will alert you with the message:
    </p>

    <div class="alert alert-secondary" role="alert">
        <div class="alert-icon"><i class="flaticon-stopwatch"></i></div>
        <div class="alert-text">
            The time for this test has expired. Your answers are automatically submitted. Click
            <b>"Continue"</b> to go to the next test.
        </div>
    </div>

    <p>
        If you complete the test ahead of time, you can click <b>“Next”</b> to move on to the next test
    </p>
</div>