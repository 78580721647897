import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BfqQuestions, BFQResultMapping} from 'src/app/shared/constants/constants';
import {ColorUtils} from '../../../shared/utils/color-utils';
import {BfqResult} from '../../../shared/models/bfq-result';

@Component({
    selector: 'app-candidate-test-bfq-answers',
    templateUrl: './candidate-test-bfq-answers.component.html',
    styleUrls: ['./candidate-test-bfq-answers.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateTestBfqAnswersComponent {
    @Input() answers = [];
    @Input() result: BfqResult = {} as BfqResult;

    public selectedTab = 0;
    public selectedSubTab = -1;

    /* Colors generated for diagram */
    private colors = ColorUtils.interpolateColorsDefault(6);
    private subcolors = ColorUtils.interpolateColorsDefault(12);

    get selectedQuestionIndexes(): number[] {
        /* Method returns selected question indexes, that starts from 1 */
        if (this.selectedSubTab !== -1) {
            return BFQResultMapping[this.selectedSubTab]
        } else if (this.selectedTab !== 0) {
            if (this.selectedTab === 1) {
                return [...BFQResultMapping[1], ...BFQResultMapping[2]].sort((a, b) => a - b);
            } else if (this.selectedTab === 2) {
                return [...BFQResultMapping[3], ...BFQResultMapping[4]].sort((a, b) => a - b);
            } else if (this.selectedTab === 3) {
                return [...BFQResultMapping[5], ...BFQResultMapping[6]].sort((a, b) => a - b);
            } else if (this.selectedTab === 4) {
                return [...BFQResultMapping[7], ...BFQResultMapping[8]].sort((a, b) => a - b);
            } else if (this.selectedTab === 5) {
                return [...BFQResultMapping[9], ...BFQResultMapping[10]].sort((a, b) => a - b);
            } else if (this.selectedTab === 6) {
                return BFQResultMapping[11];
            }
            return [];
        }
        return Array.from({length: 132}, (x, i) => i + 1)
    }

    mapQuestion(index: number): string {
        return BfqQuestions[index - 1].title;
    }

    mapAnswer(index: number): string {
        const answer = this.answers[index - 1];
        return this.answerValue(+answer);
    }

    answerValue(index: number): string {
        if (index === 1) {
            return 'Completely incorrect for me';
        } else if (index === 2) {
            return 'Quite incorrect to me';
        } else if (index === 3) {
            return 'Neither true nor false';
        } else if (index === 4) {
            return 'Mostly true for me';
        } else if (index === 5) {
            return 'Totally true for me';
        } else {
            return '';
        }
    }

    tabColor(index: number): any {
        if (this.selectedTab === index) {
            return this.colors[index - 1];
        }
        return '';
    }

    subtabColor(index: number, subIndex: number): any {
        if (this.selectedTab === index && this.selectedSubTab === subIndex) {
            return {
                backgroundColor: this.subcolors[subIndex - 1],
                color: 'white'
            }
        }
        return null;
    }

    switchTab(index: number): void {
        this.selectedTab = index || 0;
        this.selectedSubTab = -1;
    }

    switchTabSub(index: number, subIndex: number): void {
        this.selectedTab = index || 0;
        this.selectedSubTab = subIndex;
    }
}
