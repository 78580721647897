import {Injectable} from '@angular/core';
import {Endpoint} from '../constants/constants';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {State} from '../store';
import {LoadHubsAction} from '../store/actions/hubs.actions';
import {Hub} from '../models/hub';

@Injectable({
    providedIn: 'root'
})
export class HubsService {
    constructor(private http: HttpClient,
                private store: Store<State>) {
    }

    fetchHubs(): void {
        this.http
            .get<Hub[]>(Endpoint.HUBS)
            .subscribe((data: Hub[]) => {
                this.store.dispatch(new LoadHubsAction(data));
            });
    }
}
