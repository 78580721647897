<div class="kt-grid kt-grid--hor kt-grid--root">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">

                <!-- Background -->
                <div app-tests-background></div>

                <!-- begin:: Content -->
                <div class="kt-container  kt-grid__item kt-grid__item--fluid kt-padding-0">

                    <!-- begin:: Section -->
                    <div class="kt-negative-spacing--9"></div>

                    <!-- Test Content Goes Here -->
                    <div [ngSwitch]="section | async">
                        <!-- Instructions -->
                        <app-test-bfq-instructions
                                (selected)="handleInstructionsSelection($event)"
                                *ngSwitchCase="'instructions'">
                        </app-test-bfq-instructions>

                        <!-- Questions -->
                        <app-test-bfq-questions
                                (selected)="handleQuestionsSelection($event)"
                                *ngSwitchCase="'questions'"
                                [localState]="localState | async">
                        </app-test-bfq-questions>

                        <!-- Done -->
                        <app-test-base-done *ngSwitchCase="'done'"
                                            [test]="test | async"
                                            [title]="'BFQ Test'">
                        </app-test-base-done>

                        <!-- Error -->
                        <app-test-base-error *ngSwitchCase="'error'"
                                             [errorData]="errorData| async"
                                             [error]="error | async"
                                             [title]="'BFQ Test'">
                        </app-test-base-error>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>