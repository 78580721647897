<!-- Title -->
<div class="timeout-modal-title" mat-dialog-title>
    <h5>Time limit</h5>
</div>

<!-- Content -->
<mat-dialog-content class="timeout-modal-content">
    <p *ngIf="!data">
        The time for this test has expired. Your answers are automatically submitted. Click "Continue" to go to the next
        test
    </p>
    <p *ngIf="data">
        The time for this test has expired. Your answers are automatically submitted.
    </p>
</mat-dialog-content>

<!-- Footer -->
<mat-dialog-actions align="end">
    <button [mat-dialog-close]="'yes'" cdkFocusInitial class="btn btn-brand btn-wide">
        {{data ? 'Finish' : 'Continue'}}
    </button>
</mat-dialog-actions>