import {CommonUtils} from '../utils/common-utils';

export enum YesNo {
    YES = 'Y',
    NO = 'N',
    NOT_APPLICABLE = 'X'
}

export const YesNoLabels = new Map<string, string>([
    [YesNo.YES, 'Yes'],
    [YesNo.NO, 'No'],
    [YesNo.NOT_APPLICABLE, 'Not applicable'],
]);

export const YesNoItems = CommonUtils.getSelectOptions(YesNoLabels);
