import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {Test} from '../../../shared/models/test';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Component({
    selector: 'app-test-base-done',
    templateUrl: './test-base-done.component.html',
    styleUrls: ['./test-base-done.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestBaseDoneComponent {
    @Input() test: Test = null;
    @Input() title: string = null;

    /* Random icon */
    public icon: string;

    constructor(private location: Location,
                private router: Router) {
        this.icon = this.randomIcon() || 'fa flaticon-trophy';
    }

    randomIcon(): string {
        const options = [
            'fa flaticon-trophy',
            'fa flaticon-rocket',
            'fa flaticon-confetti',
            'fa flaticon-earth-globe'
        ];
        const index = Math.floor(Math.random() * (options.length + 1));
        return options[index];
    }

    back(): void {
        if (this.test && this.test.bundle) {
            this.router.navigate(['tests', this.test.bundle.url_code]);
        } else {
            this.location.back();
        }
    }
}
