import {CtiPiece} from './cti-piece';

export enum CtiPuzzleType {
    EXAMPLE = 'example',
    QUESTION = 'question'
}

export interface CtiPuzzle {
    subtest: number;
    type: CtiPuzzleType;
    index: number;
    samples: CtiPiece[];
    options: CtiPiece[];
}
