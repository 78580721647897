<!-- Logo -->
<div class="kt-header-mobile__logo">
    <a class="symphony-logo" routerLink="/">
        Symphony
    </a>
</div>

<!-- Toolbar -->
<div class="kt-header-mobile__toolbar">
    <button (click)="toggleLeftMobileSidebar()"
            class="kt-header-mobile__toggler kt-header-mobile__toggler--left">
        <span></span>
    </button>

    <button (click)="toggleRightMobileSidebar()"
            class="kt-header-mobile__toggler">
        <span></span>
    </button>

    <button (click)="toggleTopMobileSidebar()"
            class="kt-header-mobile__topbar-toggler">
        <i class="flaticon-more"></i>
    </button>
</div>