import {Action} from '@ngrx/store';
import {Hub} from '../../models/hub';

export enum HubsActionTypes {
    Load = '[Hubs] Load Hubs',
}

export class LoadHubsAction implements Action {
    readonly type = HubsActionTypes.Load;

    constructor(public payload: Hub[]) {
    }
}

export type Actions
    = LoadHubsAction;

