import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {TestBundle} from '../../../shared/models/test-bundle';
import {Test} from '../../../shared/models/test';
import {Route} from '../../../shared/constants/constants';
import {TestType} from '../../../shared/enums/test-type';
import {SortUtils} from '../../../shared/utils/sort-utils';
import {CommonUtils} from '../../../shared/utils/common-utils';

@Component({
    selector: 'app-tests-selection',
    templateUrl: './tests-selection.component.html',
    styleUrls: ['./tests-selection.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class TestsSelectionComponent {
    @Input() bundle: TestBundle = null;

    get shortVersion(): boolean {
        return this.bundle?.tests?.length === 2;
    }

    get tests(): Test[] {
        if (CommonUtils.isNotEmpty(this.bundle?.tests)) {
            return [
                ...this.bundle.tests
            ].sort((a, b) => TestsSelectionComponent.testComparator(a, b, this.shortVersion));
        }
        return [];
    }

    static testOrder(type: TestType): number {
        if (type === 'CTI') {
            return 4;
        } else if (type === 'EPI') {
            return 3;
        } else if (type === 'EPQ') {
            return 2;
        } else if (type === 'BFQ') {
            return 1;
        }
        return 0;
    }

    static testOrderShort(type: TestType): number {
        if (type === 'EPI') {
            return 4;
        } else if (type === 'CTI') {
            return 3;
        }
        return 0;
    }

    static testComparator(t1: Test, t2: Test, shortOrder: boolean): number {
        if (!t1 && !t2 || t1.type === t2.type) {
            return 0;
        } else if (!t1) {
            return -1;
        } else if (!t2) {
            return 1;
        }

        const o1 = shortOrder ? TestsSelectionComponent.testOrderShort(t1.type) : TestsSelectionComponent.testOrder(t1.type);
        const o2 = shortOrder ? TestsSelectionComponent.testOrderShort(t2.type) : TestsSelectionComponent.testOrder(t2.type);

        return SortUtils.numberComparator(o1, o2);
    }

    getTestUrl(test: Test): string {
        return test ? Route.TEST_ROOT.concat(`/${test.type}/${test.url_code}`) : '';
    }
}
