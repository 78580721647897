import {CommonUtils} from '../utils/common-utils';

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export const GenderLabels = new Map<string, string>([
    [Gender.MALE, 'Male'],
    [Gender.FEMALE, 'Female'],
]);

export const GenderItems = CommonUtils.getSelectOptions(GenderLabels);
