export const environment = {
    production: true,
    logLevel: 1,
    google: {
        captchaSiteKey: '6LcEIbQUAAAAAFeYxm4RtDybrSCOE_hqoXd14fRh',
        oAuthClientId: '44941994353-1gkfqtvur9ov2m1f0pvngr38gv1bpq65.apps.googleusercontent.com',
    },
    homePage: 'https://symphony.is/',
    sentry: {
        enabled: true,
        dsn: 'https://7994d0d9ffb04c7491ad2cd8cd24594f@o1314235.ingest.sentry.io/6565149',
        tracingOrigins: ['www.assessment.symphony.is']
    },
};
