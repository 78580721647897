import {AuthGuard} from './shared/gurads/auth-guard';
import {CandidateComponent} from './section/candidates/candidate/candidate.component';
import {CandidateResolve} from './shared/resolvers/candidate-resolver';
import {CandidatesComponent} from './section/candidates/candidates/candidates.component';
import {DashboardComponent} from './section/dashboard/dashboard/dashboard.component';
import {ErrorNotFoundComponent} from './public/error-not-found/error-not-found.component';
import {HomeComponent} from './core/home/home.component';
import {LoginComponent} from './public/login/login.component';
import {NgModule} from '@angular/core';
import {Route} from './shared/constants/constants';
import {RouterModule, Routes} from '@angular/router';
import {SettingsComponent} from './section/settings/settings/settings.component';
import {TestCtiContainerComponent} from './public/test-cti/test-cti-container/test-cti-container.component';
import {TestEpiContainerComponent} from './public/test-epi/test-epi-container/test-epi-container.component';
import {TestEpqContainerComponent} from './public/test-epq/test-epq-container/test-epq-container.component';
import {TestBfqContainerComponent} from './public/test-bfq/test-bfq-container/test-bfq-container.component';
import {TestsComponent} from './section/tests/tests/tests.component';
import {TestsContainerComponent} from './public/tests/tests-container/tests-container.component';
import {TestsResolve} from './shared/resolvers/tests-resolver';
import {UsersComponent} from './section/users/users/users.component';
import {TestEpiResolve} from './shared/resolvers/test-epi-resolver';
import {TestCtiResolve} from './shared/resolvers/test-cti-resolver';
import {TestEpqResolve} from './shared/resolvers/test-epq-resolver';
import {TestBfqResolve} from './shared/resolvers/test-bfq-resolver';

const homeChildren = [
    {
        path: '',
        component: DashboardComponent
    },
    {
        path: Route.DASHBOARD,
        component: DashboardComponent
    },
    {
        path: Route.CANDIDATES,
        component: CandidatesComponent
    },
    {
        path: Route.CANDIDATE_PREVIEW,
        component: CandidateComponent,
        resolve: {
            candidate: CandidateResolve
        }
    },
    {
        path: Route.USERS,
        component: UsersComponent
    },
    {
        path: Route.SETTINGS,
        component: SettingsComponent
    },
    {
        path: Route.TESTS,
        component: TestsComponent
    },
];

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: homeChildren
    },
    {
        path: Route.HOME,
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: homeChildren
    },
    {
        path: Route.LOGIN,
        component: LoginComponent
    },
    {
        path: Route.TEST_EPI,
        component: TestEpiContainerComponent,
        resolve: {
            data: TestEpiResolve
        },
    },
    {
        path: Route.TEST_EPQ,
        component: TestEpqContainerComponent,
        resolve: {
            data: TestEpqResolve
        },
    },
    {
        path: Route.TEST_BFQ,
        component: TestBfqContainerComponent,
        resolve: {
            data: TestBfqResolve
        },
    },
    {
        path: Route.TEST_CTI,
        component: TestCtiContainerComponent,
        resolve: {
            data: TestCtiResolve
        },
    },
    {
        path: Route.CANDIDATE_TESTS,
        component: TestsContainerComponent,
        resolve: {
            data: TestsResolve
        },
    },
    {
        path: Route.ERROR_404,
        component: ErrorNotFoundComponent
    },
    {
        path: '**',
        component: ErrorNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
