import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpXsrfTokenExtractor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {State} from '../store';
import {StartLoadingAction, StopLoadingAction} from '../store/actions/loader.actions';

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
    public static IGNORE_URLS = ['/api/test-bundles/sending/'];

    constructor(private store: Store<State>,
                private tokenExtractor: HttpXsrfTokenExtractor) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!ProgressInterceptor.IGNORE_URLS.includes(req.url)) {
            /* Load started */
            this.store.dispatch(new StartLoadingAction());

            const headerName = 'X-XSRF-TOKEN';
            const token = this.tokenExtractor.getToken() as string;
            if (token !== null && !req.headers.has(headerName)) {
                req = req.clone({headers: req.headers.set(headerName, token)});
            }
            /* Call next handler */
            return next.handle(req).pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        this.store.dispatch(new StopLoadingAction());
                    }
                }),
                catchError(error => {
                    this.store.dispatch(new StopLoadingAction());
                    return throwError(error);
                }),
            );
        }
        return next.handle(req);
    }
}
