import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {EmotionQuestions} from '../../../shared/constants/constants';
import {EpiOption} from '../../../shared/models/epi-option';
import {TestsService} from '../../../shared/services/tests.service';
import {ActivatedRoute} from '@angular/router';
import {StorageHelper} from '../../../shared/helpers/storage-helper';
import {TestBaseQuestions} from '../../test-base/test-base-questions';
import {Store} from '@ngrx/store';
import {State} from '../../../shared/store';

@Component({
    selector: 'app-test-epi-questions',
    templateUrl: './test-epi-questions.component.html',
    styleUrls: ['./test-epi-questions.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestEpiQuestionsComponent extends TestBaseQuestions<EpiOption> {
    constructor(private testsService: TestsService,
                private route: ActivatedRoute,
                store: Store<State>) {
        super(store);
    }

    get currentQuestion(): EpiOption[] {
        return EmotionQuestions[this.currentIndex];
    }

    get code(): string {
        return this.route.snapshot.params.code;
    }

    get questionsLength(): number {
        return EmotionQuestions.length;
    }

    save(): void {
        this.testsService
            .saveEpiTest(this.code, this.answers)
            .subscribe(x => {
                StorageHelper.clearTestData(this.code);
                this.nextStep();
            }, error => {
                this.handleTestError(error);
            });
    }
}
