<div (click)="dismiss()" [class.spinner-visible]="data.showSpinner" class="notification-panel-content">
    <!-- Body -->
    <div class="notification-panel-body">
        <span class="notification-panel-icon"></span>
        <span class="notification-panel-message">{{data.message}}</span>
    </div>
</div>

<!-- Spinner -->
<div *ngIf="data.showSpinner" class="notification-spinner">
    <mat-spinner [diameter]="20" [strokeWidth]="2"></mat-spinner>
</div>