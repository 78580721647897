import * as layout from '../actions/layout.actions';

export interface State {
    showSidebar: boolean;
    showLeftMobileSidebar: boolean;
    showRightMobileSidebar: boolean;
    showTopMobileSidebar: boolean;
}

const initialState: State = {
    showSidebar: true,
    showLeftMobileSidebar: false,
    showRightMobileSidebar: false,
    showTopMobileSidebar: false
};

export function reducer(state = initialState, action: layout.Actions): State {
    switch (action.type) {
        case layout.LayoutActionTypes.ToggleSidebar:
            return Object.assign({}, state, {showSidebar: !state.showSidebar});
        case layout.LayoutActionTypes.ToggleLeftMobileSidebar:
            return Object.assign({}, state, {showLeftMobileSidebar: !state.showLeftMobileSidebar});
        case layout.LayoutActionTypes.ToggleRightMobileSidebar:
            return Object.assign({}, state, {showRightMobileSidebar: !state.showRightMobileSidebar});
        case layout.LayoutActionTypes.ToggleTopMobileSidebar:
            return Object.assign({}, state, {showTopMobileSidebar: !state.showTopMobileSidebar});
        default:
            return state;
    }
}

export const getShowSidebar = (state: State) => state.showSidebar;
export const getShowLeftMobileSidebar = (state: State) => state.showLeftMobileSidebar;
export const getShowRightMobileSidebar = (state: State) => state.showRightMobileSidebar;
export const getShowTopMobileSidebar = (state: State) => state.showTopMobileSidebar;
