<!-- Title -->
<div class="candidate-modal-title" mat-dialog-title>
    <h5>Candidate Status</h5>
</div>

<!-- Content -->
<mat-dialog-content class="candidate-modal-content">
    <div [formGroup]="form" class="kt-form kt-form--label-align-right">
        <!-- Hub -->
        <div class="form-group kt-form__group">
            <label>Status</label>
            <mat-select class="form-control kt-input "
                        formControlName="status">
                <mat-option *ngFor="let status of statuses" [value]="status.key">
                    {{status.label}}
                </mat-option>
            </mat-select>

            <app-validation-error [control]="form.controls.status"></app-validation-error>
        </div>
    </div>
</mat-dialog-content>

<!-- Footer -->
<mat-dialog-actions align="end">
    <button class="btn btn-secondary btn-wide" mat-dialog-close>Cancel</button>
    <button (click)="save()" class="btn btn-brand btn-wide">Save</button>
</mat-dialog-actions>
