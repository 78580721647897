import {ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-test-cti-instructions',
    templateUrl: './test-cti-instructions.component.html',
    styleUrls: ['./test-cti-instructions.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestCtiInstructionsComponent {
    @Output() selected = new EventEmitter<string>();

    nextStep(): void {
        this.selected.next('next');
    }

    previousStep(): void {
        this.selected.next('previous');
    }
}
