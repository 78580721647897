import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthGuard} from './shared/gurads/auth-guard';
import {AuthInterceptor} from './shared/interceptors/auth-interceptor';
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from '@abacritt/angularx-social-login';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CandidateComponent} from './section/candidates/candidate/candidate.component';
import {CandidateInfoComponent} from './section/candidates/candidate-info/candidate-info.component';
import {CandidateModalComponent} from './section/candidates/candidate-modal/candidate-modal.component';
import {CandidateResolve} from './shared/resolvers/candidate-resolver';
import {CandidatesComponent} from './section/candidates/candidates/candidates.component';
import {CandidateSearchComponent} from './shared/components/candidate-search/candidate-search.component';
import {CandidateStateModalComponent} from './section/candidates/candidate-state-modal/candidate-state-modal.component';
import {CandidateTestCtiComponent} from './section/candidates/candidate-test-cti/candidate-test-cti.component';
import {
    CandidateTestCtiResultComponent
} from './section/candidates/candidate-test-cti-result/candidate-test-cti-result.component';
import {
    CandidateTestEpiAnswersComponent
} from './section/candidates/candidate-test-epi-answers/candidate-test-epi-answers.component';
import {CandidateTestEpiComponent} from './section/candidates/candidate-test-epi/candidate-test-epi.component';
import {
    CandidateTestEpiDiagramComponent
} from './section/candidates/candidate-test-epi-diagram/candidate-test-epi-diagram.component';
import {
    CandidateTestEpqAnswersComponent
} from './section/candidates/candidate-test-epq-answers/candidate-test-epq-answers.component';
import {CandidateTestEpqComponent} from './section/candidates/candidate-test-epq/candidate-test-epq.component';
import {
    CandidateTestBfqAnswersComponent
} from './section/candidates/candidate-test-bfq-answers/candidate-test-bfq-answers.component';
import {CandidateTestBfqComponent} from './section/candidates/candidate-test-bfq/candidate-test-bfq.component';
import {
    CandidateTestBfqDiagramComponent
} from './section/candidates/candidate-test-bfq-diagram/candidate-test-bfq-diagram.component';
import {ConfirmModalComponent} from './shared/components/modals/confirm-modal/confirm-modal.component';
import {DashboardComponent} from './section/dashboard/dashboard/dashboard.component';
import {
    DashboardNotificationsComponent
} from './section/dashboard/dashboard-notifications/dashboard-notifications.component';
import {environment} from '../environments/environment';
import {ErrorHandler, NgModule} from '@angular/core';
import {ErrorNotFoundComponent} from './public/error-not-found/error-not-found.component';
import {FormatDatePipe} from './shared/pipes/format-date.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeActionsComponent} from './core/home-actions/home-actions.component';
import {HomeComponent} from './core/home/home.component';
import {HomeContentComponent} from './core/home-content/home-content.component';
import {HomeHeaderComponent} from './core/home-header/home-header.component';
import {HomeHeaderMobileComponent} from './core/home-header-mobile/home-header-mobile.component';
import {HomeSidebarComponent} from './core/home-sidebar/home-sidebar.component';
import {HomeSubheaderComponent} from './core/home-subheader/home-subheader.component';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {LoggingErrorHandler} from './shared/errors/logging-error-handler';
import {LoginComponent} from './public/login/login.component';
import {MapEnumPipe} from './shared/pipes/map-enum.pipe';
import {metaReducers, reducers} from './shared/store';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {NotificationPanelComponent} from './shared/components/notification-panel/notification-panel.component';
import {PortletComponent} from './shared/components/portlet/portlet.component';
import {ProgressInterceptor} from './shared/interceptors/progress-interceptor';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {SettingsComponent} from './section/settings/settings/settings.component';
import {StoreModule} from '@ngrx/store';
import {TestChoiceComponent} from './section/tests/test-choice/test-choice.component';
import {TestCtiContainerComponent} from './public/test-cti/test-cti-container/test-cti-container.component';
import {TestCtiExamplesComponent} from './public/test-cti/test-cti-examples/test-cti-examples.component';
import {
    TestCtiExamplesTest1Component
} from './public/test-cti/test-cti-examples/test-cti-examples-test-1/test-cti-examples-test-1.component';
import {
    TestCtiExamplesTest2Component
} from './public/test-cti/test-cti-examples/test-cti-examples-test-2/test-cti-examples-test-2.component';
import {
    TestCtiExamplesTest3Component
} from './public/test-cti/test-cti-examples/test-cti-examples-test-3/test-cti-examples-test-3.component';
import {
    TestCtiExamplesTest4Component
} from './public/test-cti/test-cti-examples/test-cti-examples-test-4/test-cti-examples-test-4.component';
import {TestCtiInstructionsComponent} from './public/test-cti/test-cti-instructions/test-cti-instructions.component';
import {TestCtiPieceComponent} from './public/test-cti/test-cti-piece/test-cti-piece.component';
import {TestCtiPuzzleComponent} from './public/test-cti/test-cti-puzzle/test-cti-puzzle.component';
import {TestCtiQuestionsComponent} from './public/test-cti/test-cti-questions/test-cti-questions.component';
import {TestEpiContainerComponent} from './public/test-epi/test-epi-container/test-epi-container.component';
import {TestEpiDefinitionsComponent} from './public/test-epi/test-epi-definitions/test-epi-definitions.component';
import {TestEpiInstructionsComponent} from './public/test-epi/test-epi-instructions/test-epi-instructions.component';
import {TestEpiQuestionsComponent} from './public/test-epi/test-epi-questions/test-epi-questions.component';
import {TestEpqContainerComponent} from './public/test-epq/test-epq-container/test-epq-container.component';
import {TestEpqInstructionsComponent} from './public/test-epq/test-epq-instructions/test-epq-instructions.component';
import {TestEpqQuestionsComponent} from './public/test-epq/test-epq-questions/test-epq-questions.component';
import {TestBfqContainerComponent} from './public/test-bfq/test-bfq-container/test-bfq-container.component';
import {TestBfqInstructionsComponent} from './public/test-bfq/test-bfq-instructions/test-bfq-instructions.component';
import {TestBfqQuestionsComponent} from './public/test-bfq/test-bfq-questions/test-bfq-questions.component';
import {TestModalComponent} from './section/tests/test-modal/test-modal.component';
import {TestsBackgroundComponent} from './public/tests/tests-background/tests-background.component';
import {TestsComponent} from './section/tests/tests/tests.component';
import {TestsContainerComponent} from './public/tests/tests-container/tests-container.component';
import {TestSendModalComponent} from './section/tests/test-send-modal/test-send-modal.component';
import {TestsErrorComponent} from './public/tests/tests-error/tests-error.component';
import {TestsSuccessComponent} from './public/tests/tests-success/tests-success.component';
import {TestsSelectionComponent} from './public/tests/tests-selection/tests-selection.component';
import {TestsValidateComponent} from './public/tests/tests-validate/tests-validate.component';
import {UserModalComponent} from './section/users/user-modal/user-modal.component';
import {UsersComponent} from './section/users/users/users.component';
import {ValidationErrorComponent} from './shared/components/validation-error/validation-error.component';
import {TestsResolve} from './shared/resolvers/tests-resolver';
import {TestEpiResolve} from './shared/resolvers/test-epi-resolver';
import {TestEpqResolve} from './shared/resolvers/test-epq-resolver';
import {TestBfqResolve} from './shared/resolvers/test-bfq-resolver';
import {TestCtiResolve} from './shared/resolvers/test-cti-resolver';
import {TestCtiTimeoutComponent} from './public/test-cti/test-cti-timeout/test-cti-timeout.component';
import {NotificationPageComponent} from './shared/components/notification-page/notification-page.component';
import {CandidateBundleMenuComponent} from './section/candidates/candidate-bundle-menu/candidate-bundle-menu.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DashboardStatisticComponent} from './section/dashboard/dashboard-statistic/dashboard-statistic.component';
import {
    DashboardStatisticBoxComponent
} from './section/dashboard/dashboard-statistic-box/dashboard-statistic-box.component';
import {BarChartComponent} from './shared/components/bar-chart/bar-chart.component';
import {DoughnutChartComponent} from './shared/components/doughnut-chart/doughnut-chart.component';
import {ErrorPageComponent} from './shared/components/error-page/error-page.component';
import {FormatDurationPipe} from './shared/pipes/format-duration.pipe';
import {TestBaseErrorComponent} from './public/test-base/test-base-error/test-base-error.component';
import {TestBaseDoneComponent} from './public/test-base/test-base-done/test-base-done.component';
import {TestBaseOptionComponent} from './public/test-base/test-base-option/test-base-option.component';
import {TestScheduleModalComponent} from './section/tests/test-schedule-modal/test-schedule-modal.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [
        AppComponent,
        CandidateComponent,
        CandidateInfoComponent,
        CandidateModalComponent,
        CandidatesComponent,
        CandidateSearchComponent,
        CandidateStateModalComponent,
        CandidateTestCtiComponent,
        CandidateTestCtiResultComponent,
        CandidateTestEpiAnswersComponent,
        CandidateTestEpiComponent,
        CandidateTestEpiDiagramComponent,
        CandidateTestEpqAnswersComponent,
        CandidateTestEpqComponent,
        CandidateTestBfqAnswersComponent,
        CandidateTestBfqComponent,
        CandidateTestBfqDiagramComponent,
        ConfirmModalComponent,
        DashboardComponent,
        DashboardNotificationsComponent,
        ErrorNotFoundComponent,
        FormatDatePipe,
        HomeActionsComponent,
        HomeComponent,
        HomeContentComponent,
        HomeHeaderComponent,
        HomeHeaderMobileComponent,
        HomeSidebarComponent,
        HomeSubheaderComponent,
        LoginComponent,
        MapEnumPipe,
        NotificationPanelComponent,
        PortletComponent,
        SettingsComponent,
        TestChoiceComponent,
        TestCtiContainerComponent,
        TestCtiExamplesComponent,
        TestCtiExamplesTest1Component,
        TestCtiExamplesTest2Component,
        TestCtiExamplesTest3Component,
        TestCtiExamplesTest4Component,
        TestCtiInstructionsComponent,
        TestCtiPieceComponent,
        TestCtiPuzzleComponent,
        TestCtiQuestionsComponent,
        TestEpiContainerComponent,
        TestEpiDefinitionsComponent,
        TestEpiInstructionsComponent,
        TestEpiQuestionsComponent,
        TestEpqContainerComponent,
        TestEpqInstructionsComponent,
        TestEpqQuestionsComponent,
        TestBfqContainerComponent,
        TestBfqInstructionsComponent,
        TestBfqQuestionsComponent,
        TestModalComponent,
        TestsBackgroundComponent,
        TestsComponent,
        TestsContainerComponent,
        TestSendModalComponent,
        TestsErrorComponent,
        TestsSuccessComponent,
        TestsSelectionComponent,
        TestsValidateComponent,
        UserModalComponent,
        UsersComponent,
        ValidationErrorComponent,
        TestCtiTimeoutComponent,
        NotificationPageComponent,
        CandidateBundleMenuComponent,
        DashboardStatisticComponent,
        DashboardStatisticBoxComponent,
        BarChartComponent,
        DoughnutChartComponent,
        ErrorPageComponent,
        FormatDurationPipe,
        TestBaseErrorComponent,
        TestBaseDoneComponent,
        TestBaseOptionComponent,
        TestScheduleModalComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: '_csrf_token',
            headerName: 'X-CSRFToken'
        }),
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatListModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        SocialLoginModule,
        StoreModule.forRoot(reducers, {metaReducers}),
    ],
    providers: [
        AuthGuard,
        TestsResolve,
        CandidateResolve,
        TestEpiResolve,
        TestCtiResolve,
        TestBfqResolve,
        TestEpqResolve,
        {
            provide: ErrorHandler,
            useClass: LoggingErrorHandler
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {float: 'never'}
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ProgressInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.google.oAuthClientId, {
                            scopes: 'profile email'
                        })
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
