<div class="kt-details-text">
    <p>
        In this test, keep a close eye on where the dot is.
        In example 1, the condition is: the dot is in the circle and in the square, simultaneously.
        Take a look at 5 possible answers and look for a drawing with a dot that you can place both in the circle and in
        the
        square at the same time.
        No answer other than the picture three would be correct.
        This example shows that the dot can be both in the circle and in the square.
    </p>

    <app-test-cti-puzzle
            [interactive]="false"
            [puzzle]="examples[0]"
            [solution]="solutions[0]"
            [subtest]="3">
    </app-test-cti-puzzle>

    <p class="kt-padding-t-20">
        <b>Example 2.</b> The dot is inside the triangle but outside the quadrilateral.
        There is just one other drawing where you can place the dot inside the triangle without putting it inside the
        square
        at the same time.
        The correct answer is picture 4.
    </p>

    <app-test-cti-puzzle
            [interactive]="false"
            [puzzle]="examples[1]"
            [solution]="solutions[1]"
            [subtest]="3">
    </app-test-cti-puzzle>

    <p class="kt-padding-t-20">
        <b>Example 3.</b> The dot is inside the triangle, above the semicircular line.
        In which other square can we do the same?
    </p>

    <app-test-cti-puzzle
            [puzzle]="examples[2]"
            [solution]="solutions[2]"
            [subtest]="3">
    </app-test-cti-puzzle>

    <p class="kt-padding-t-20">
        When you click “Start Test”, you will start work independently last Test 4.
        In this section, you will not be told whether you have selected the correct or wrong answer.
        When you select an answer, it will turn blue.
        You can change the answer as many times as you want.
        This test contains 10 tasks.
        The allocated time for this test is displayed in the background of the test.
        Remember that you have just a few minutes and you need to be as accurate and quick as possible.
    </p>

    <p>
        If your scheduled time expires, the test will alert you with the message:
    </p>

    <div class="alert alert-secondary" role="alert">
        <div class="alert-icon"><i class="flaticon-stopwatch"></i></div>
        <div class="alert-text">
            The time has expired. That's all for this test! We hope you enjoyed taking the test and are satisfied with
            your performance. Thank you for your time and patience.
        </div>
    </div>

    <p>
        If you complete the test ahead of time, you can click <b>“Finish”</b> to close the last test
    </p>
</div>