import {CommonUtils} from '../utils/common-utils';

export enum TestError {
    VA_WRONG_CODE_URL = 'VA_WRONG_CODE_URL',
    VA_NO_BUNDLE = 'VA_NO_BUNDLE',
    VA_ERROR_STATE = 'VA_ERROR_STATE',
    VA_FINISHED_STATE = 'VA_FINISHED_STATE',
    VA_SENT_STATE = 'VA_SENT_STATE',
    VA_WRONG_ACCESS_CODE = 'VA_WRONG_ACCESS_CODE',
    VC_WRONG_CODE_URL = 'VC_WRONG_CODE_URL',
    VC_MISSING_PIN_OR_CAPTCHA = 'VC_MISSING_PIN_OR_CAPTCHA',
    VC_INVALID_CAPTCHA = 'VC_INVALID_CAPTCHA',
    VC_NO_BUNDLE = 'VC_NO_BUNDLE',
    VC_ERROR_STATE = 'VC_ERROR_STATE',
    VC_FINISHED_STATE = 'VC_FINISHED_STATE',
    VC_WRONG_PIN = 'VC_WRONG_PIN',
    TV_WRONG_CODE_URL = 'TV_WRONG_CODE_URL',
    TV_NO_TEST = 'TV_NO_TEST',
    TV_ERROR_STATE = 'TV_ERROR_STATE',
    TV_FINISHED_STATE = 'TV_FINISHED_STATE',
    TV_BUNDLE_STATE = 'TV_BUNDLE_STATE',
    TV_INVALID_STATE = 'TV_INVALID_STATE',
    TV_EXPIRED_STATE = 'TV_EXPIRED_STATE',
    TV_INVALID_BUNDLE_STATE = 'TV_INVALID_BUNDLE_STATE',
}

export const TestErrorLabels = new Map<string, string>([
    [TestError.VA_WRONG_CODE_URL, 'Wrong code or url format'],
    [TestError.VA_NO_BUNDLE, 'Can\'t find bundle with specified code or maximum number of retries is exceeded'],
    [TestError.VA_ERROR_STATE, 'Something is wrong with the test bundle'],
    [TestError.VA_FINISHED_STATE, 'Test bundle is closed'],
    [TestError.VA_SENT_STATE, 'Redirect to code validation'],
    [TestError.VA_WRONG_ACCESS_CODE, 'Wrong code or access code'],
    [TestError.VC_WRONG_CODE_URL, 'Wrong code or url format'],
    [TestError.VC_MISSING_PIN_OR_CAPTCHA, 'Missing pin or captcha'],
    [TestError.VC_INVALID_CAPTCHA, 'Invalid reCAPTCHA. Please try again'],
    [TestError.VC_NO_BUNDLE, 'Can\'t find bundle with specified code or maximum number of retries is exceeded'],
    [TestError.VC_ERROR_STATE, 'Something is wrong with the test bundle'],
    [TestError.VC_FINISHED_STATE, 'Test bundle is closed'],
    [TestError.VC_WRONG_PIN, 'Wrong pin or code'],
    [TestError.TV_WRONG_CODE_URL, 'Wrong code or url format'],
    [TestError.TV_NO_TEST, 'Can\'t find test with specified code'],
    [TestError.TV_ERROR_STATE, 'Something is wrong with the test'],
    [TestError.TV_FINISHED_STATE, 'Test is already done'],
    [TestError.TV_BUNDLE_STATE, 'Bundle is in invalid state'],
    [TestError.TV_INVALID_STATE, 'Test in invalid state'],
    [TestError.TV_EXPIRED_STATE, 'Test expired'],
    [TestError.TV_INVALID_BUNDLE_STATE, 'Test bundle in invalid state'],
]);

export const TestErrorItems = CommonUtils.getSelectOptions(TestErrorLabels);
