import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
    selector: 'app-notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationPanelComponent {
    constructor(public snackBarRef: MatSnackBarRef<NotificationPanelComponent>,
                @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    }

    dismiss(): void {
        this.snackBarRef.dismiss();
    }
}
