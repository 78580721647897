import {ActivatedRoute} from '@angular/router';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {CtiPiece} from '../../../shared/models/cti-piece';
import {CtiPuzzle} from '../../../shared/models/cti-puzzle';
import {CtiTestInfo, CtiTestQuestions, CtiTests, ModalOptions} from 'src/app/shared/constants/constants';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TestsService} from 'src/app/shared/services/tests.service';
import {StorageHelper} from 'src/app/shared/helpers/storage-helper';
import {MappingUtils} from 'src/app/shared/utils/mapping-utils';
import {TestCtiTimeoutComponent} from '../test-cti-timeout/test-cti-timeout.component';
import {LogUtils} from '../../../shared/utils/log-utils';
import {Test} from '../../../shared/models/test';
import {ConfirmModalComponent} from '../../../shared/components/modals/confirm-modal/confirm-modal.component';
import * as moment from 'moment';

@Component({
    selector: 'app-test-cti-questions',
    templateUrl: './test-cti-questions.component.html',
    styleUrls: ['./test-cti-questions.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class TestCtiQuestionsComponent implements OnInit, OnDestroy {
    @Input() subtest = 0;
    @Input() subtestStart: Date;
    @Output() selected = new EventEmitter<number>();

    public answers: CtiPiece[][] = [];
    private timerId = null;
    private confirmationDialog: MatDialogRef<any> = null;
    private timeoutDialog: MatDialogRef<any> = null;

    constructor(private testsService: TestsService,
                private route: ActivatedRoute,
                private dialog: MatDialog) {
    }

    get testInfo(): CtiTestInfo {
        return CtiTests[this.subtest];
    }

    get testQuestions(): CtiPuzzle[] {
        return CtiTestQuestions[this.subtest];
    }

    get code(): string {
        return this.route.snapshot.params.code;
    }

    get lastStep(): boolean {
        return this.subtest && this.subtest === 3;
    }

    get modalConfigurationConfirm(): any {
        return Object.assign({}, ModalOptions.CONFIRM, {
            data: {
                title: 'Confirm',
                message: 'You are about to end this test. Are you sure?'
            }
        });
    }

    private static setupStep(test: Test): void {
        if (test) {
            StorageHelper.setCurrentTestState({
                code: test.url_code,
                subtest: test.current_subtest,
                answers: [],
                question: -1,
                state: 'examples',
                disablePrevious: false
            });
        }
    }

    ngOnInit(): void {
        /* Navigate */
        window.scroll(0, 0);

        const limit = CtiTests[this.subtest].timeSeconds * 1000;
        const now = moment.utc(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'));
        const started = this.subtestStart ? moment.utc(this.subtestStart) : now;
        const duration = now.diff(started, 'milliseconds');

        /* Show modal */
        if (duration > limit) {
            /* Just save and move  */
            this.saveExpiredData();
        } else {
            /* Load answers and setup timer */
            const testState = StorageHelper.getCurrentTestState(this.code, this.subtest);
            if (testState) {
                this.answers = MappingUtils.mapCtiPuzzle(testState.answers || [], this.testQuestions);
            }

            /* Timer */
            const remainingTime = (limit - duration);
            this.timerId = setTimeout(() => {
                this.saveTimeoutData();
            }, remainingTime);

            /* Log Timer */
            LogUtils.warning(`Timer set to: ${remainingTime}ms`);
        }
    }

    ngOnDestroy(): void {
        /* Remove timer */
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
    }

    updateTestState() {
        StorageHelper.setCurrentTestState({
            code: this.code,
            subtest: this.subtest,
            answers: MappingUtils.mapCtiAnswers(this.answers, this.subtest),
            question: -1,
            state: 'questions',
            disablePrevious: false
        });
    }

    recordAnswer(answer: CtiPiece[]): void {
        const questionIndex = answer[0] ? answer[0].puzzle.index : answer[1].puzzle.index;
        this.answers[questionIndex] = answer;
        this.updateTestState();
    }

    saveExpiredData(): void {
        this.testsService
            .saveCtiTest(this.code, this.subtest, this.answers)
            .subscribe(result => this.handleSaveResponse(result));
    }

    saveTimeoutData(): void {
        this.testsService
            .saveCtiTest(this.code, this.subtest, this.answers)
            .subscribe(result => {
                /* Setup next step */
                TestCtiQuestionsComponent.setupStep(result.test);

                /* Open message modal */
                this.showTimeoutModal(result.test.current_subtest, result.finished);
            });
    }

    saveConfirmationData(): void {
        this.testsService
            .saveCtiTest(this.code, this.subtest, this.answers)
            .subscribe(result => this.handleSaveResponse(result));
    }

    handleSaveResponse(data: { success: boolean, test: Test, finished: boolean }): void {
        /* Create next step */
        TestCtiQuestionsComponent.setupStep(data.test);

        /* Move to next step */
        this.selected.next(data.test.current_subtest);

        /* Clear data if done */
        if (data.finished) {
            StorageHelper.clearTestData(this.code);
        }
    }

    showTimeoutModal(next: number, finished: boolean): void {
        if (this.confirmationDialog) {
            this.confirmationDialog.close();
        }

        /*  Show timeout modal */
        this.timeoutDialog = this.dialog.open(TestCtiTimeoutComponent, {
            width: '50vw',
            disableClose: true,
            data: this.lastStep
        });

        this.timeoutDialog.afterClosed().subscribe(_ => {
            this.timeoutDialog = null;

            /* Move to next */
            this.selected.next(next);

            /* Clear data if done */
            if (finished) {
                StorageHelper.clearTestData(this.code);
            }
        });
    }

    nextStep(): void {
        this.confirmationDialog = this.dialog.open(ConfirmModalComponent, this.modalConfigurationConfirm);
        this.confirmationDialog.afterClosed().subscribe(result => {
            this.confirmationDialog = null;
            if (result === 'yes') {
                this.saveConfirmationData();
            }
        });
    }
}
