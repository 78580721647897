import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {CtiPiece} from '../../../../shared/models/cti-piece';
import {CtiPuzzle} from 'src/app/shared/models/cti-puzzle';

@Component({
    selector: 'app-test-cti-examples-test-2',
    templateUrl: './test-cti-examples-test-2.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestCtiExamplesTest2Component {
    @Input() examples: CtiPuzzle[] = null;
    @Input() solutions: CtiPiece[][] = null;
}
