<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        General Instructions and Test Selection
                    </h3>
                </div>
            </div>

            <div class="kt-portlet__body test-epi-terms">
                <!-- Form -->
                <div class="kt-section kt-margin-0">
                    <!-- Test description for normal version -->
                    <div *ngIf="!shortVersion" class="kt-section__content kt-section__content__text kt-details-text">
                        <p>
                            This assessment helps us get to know you as an individual and better understand some of your
                            typical behaviors
                        </p>
                        <p>
                            You can find personality and logical-analytical tests below. Once you select a test, you
                            will receive instructions.
                            We suggest that you go through tests as they are listed below. <span class="kt-font-bold">First, take the
                            logical-analytical test (CTI) and then the personality tests (EPI, EPQ, BFQ).</span>
                        </p>
                        <p>
                            While answering the questionnaire, please remember the following:
                        </p>

                        <!-- List -->
                        <ul>
                            <li>
                                Start the assessment when you are alert and free from interruptions
                            </li>
                            <li>
                                The logical-analytical test requires attention and focus. It follows standardized and
                                clear instructions, so be careful when reading them. There are correct and wrong
                                answers.
                            </li>
                            <li>
                                Our personality tests are designed to find out what you are like as a person and your
                                work-related personality. It tests the ways you react in different situations and how
                                you deal with and work with other people.
                            </li>
                            <li>
                                There are no right or wrong answers in personality tests. Your first answer is probably
                                the most accurate reflection of how you feel about a question. Even if a question does
                                not seem entirely relevant, give the answer you consider most applicable to you.
                            </li>
                            <li>
                                The time for the personality questionnaire itself is not limited; however, this does not
                                mean you can spend all day mulling your responses - answer quickly because the first
                                answer that comes to mind is usually the one most reflective of your true feelings.
                            </li>
                        </ul>
                        <br>
                        <p class="kt-align-right kt-font-bolder">
                            Enjoy getting to know yourself a little better!
                        </p>
                    </div>

                    <!-- Test description for short version -->
                    <div *ngIf="shortVersion" class="kt-section__content kt-section__content__text kt-details-text">
                        <p>
                            This assessment helps us get to know you as an individual and better understand some of your
                            typical behaviors
                        </p>
                        <p>
                            You can find personality and logical-analytical tests below. Once you select a test, you
                            will receive instructions. We suggest that you go through tests as they are listed below.
                            <span class="kt-font-bold">First, take the personality test (EPI) and then logical-analytical test (CTI).</span>
                        </p>
                        <p>
                            While answering the questionnaire, please remember the following:
                        </p>

                        <!-- List -->
                        <ul>
                            <li>
                                Start the assessment when you are alert and free from interruptions
                            </li>
                            <li>
                                Our EPI personality test is designed to find out what you are like as a person and your
                                work-related personality. It tests the ways you react in different situations and how
                                you deal with and work with other people.
                            </li>
                            <li>
                                There are no right or wrong answers in personality test. Your first answer is probably
                                the most accurate reflection of how you feel about a question. Even if a question does
                                not seem entirely relevant, give the answer you consider most applicable to you.
                            </li>
                            <li>
                                The time for the personality questionnaire itself is not limited; however, this does not
                                mean you can spend all day mulling your responses - answer quickly because the first
                                answer that comes to mind is usually the one most reflective of your true feelings.
                            </li>
                            <li>
                                The logical-analytical test requires attention and focus. They follow standardized and
                                clear instructions, so be careful when reading them. There are correct and wrong
                                answers.
                            </li>
                        </ul>
                        <br>
                        <p class="kt-align-right kt-font-bolder">
                            Enjoy getting to know yourself a little better!
                        </p>
                    </div>
                </div>

                <div class="kt-separator kt-separator--border-dashed kt-separator--portlet-fit"></div>

                <div class="kt-section">
                    <div class="kt-section__content kt-section__content__text kt-details-text">
                        <h6 class="kt-margin-0 kt-font-brand">Please select a test</h6>
                        <ul class="kt-nav kt-nav--bold kt-nav--lg-space kt-nav--lg-font">
                            <li *ngFor="let test of tests" class="kt-nav__item">
                                <a [class.test-finished]="test.status == 'FINISHED' || test.status == 'ERROR'"
                                   [routerLink]="getTestUrl(test)"
                                   class="kt-nav__link">
                                    <i [class.flaticon2-attention]="test.status == 'ERROR'"
                                       [class.flaticon2-check-mark]="test.status == 'FINISHED'"
                                       [class.flaticon2-drop]="test.status != 'FINISHED' && test.status != 'ERROR'"
                                       class="kt-nav__link-icon flaticon2-drop">
                                    </i>
                                    <span class="kt-nav__link-text">
                                        {{test.type | mapEnum: 'TestType'}}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
