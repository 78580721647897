import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CandidateStatusItems} from '../../../shared/enums/candidate-status';
import {FormHelper} from '../../../shared/helpers/form-helper';

@Component({
    selector: 'app-candidate-state-modal',
    templateUrl: './candidate-state-modal.component.html',
    styleUrls: ['./candidate-state-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CandidateStateModalComponent {
    public form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder,
                private dialog: MatDialogRef<CandidateStateModalComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any) {
        this.form = this.formBuilder.group({
            id: this.data.id,
            status: [data.status, Validators.required],
        });
    }

    get statuses(): any[] {
        return CandidateStatusItems;
    }

    save(): void {
        if (this.form.valid) {
            this.dialog.close({
                result: 'yes',
                data: this.form.getRawValue()
            });
        } else {
            FormHelper.validateAllFormFields(this.form);
        }
    }
}
