import {CommonUtils} from '../utils/common-utils';

export enum TestType {
    EPI = 'EPI',
    CTI = 'CTI',
    EPQ = 'EPQ',
    BFQ = 'BFQ',
}

export const TestTypeLabels = new Map<string, string>([
    [TestType.EPI, 'EPI'],
    [TestType.CTI, 'CTI'],
    [TestType.EPQ, 'EPQ'],
    [TestType.BFQ, 'BFQ']
]);

export const TestTypeItems = CommonUtils.getSelectOptions(TestTypeLabels);
