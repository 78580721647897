import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {Candidate} from '../../../shared/models/candidate';
import {LogUtils} from '../../../shared/utils/log-utils';
import {Test} from '../../../shared/models/test';
import {TestType} from '../../../shared/enums/test-type';
import {CommonUtils} from '../../../shared/utils/common-utils';
import {TestBundle} from '../../../shared/models/test-bundle';
import {Duration} from 'moment';

@Component({
    selector: 'app-candidate-test-bfq',
    templateUrl: './candidate-test-bfq.component.html',
    styleUrls: ['./candidate-test-bfq.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateTestBfqComponent {
    public selectedTab = 0;

    @Input() candidate: Candidate = null;
    @Input() selectedBundle = 0;

    get test(): Test {
        return this.bundle && this.bundle.tests ? this.bundle.tests.find(test => test.type === TestType.BFQ) : {} as Test;
    }

    get bundle(): TestBundle {
        if (this.candidate && CommonUtils.isNotEmpty(this.candidate.bundles)) {
            return this.candidate.bundles[this.selectedBundle];
        }
        return {} as TestBundle;
    }

    /**
     * result: { answers: object result: object}
     */
    get result(): { answers: object, result: object } {
        try {
            return JSON.parse(this.test.result);
        } catch (e) {
            LogUtils.error(`Parse error for results: ${e.message}`);
            return null;
        }
    }

    get answers(): string[] {
        const test = this.test;
        if (this.test && CommonUtils.isNotEmpty(this.test.answers)) {
            return test.answers.slice(1, -1).split(', ');
        }
        return [];
    }

    get analysis(): any {
        return this.result ? this.result.result : {};
    }

    get renderData(): boolean {
        return this.answers && this.answers.length > 0;
    }

    get duration(): Duration {
        return CommonUtils.calculateDuration(this.test);
    }

    switchTab(index): void {
        this.selectedTab = index || 0;
    }
}
