<div class="kt-subheader kt-grid__item">
    <div class="kt-container  kt-container--fluid ">
        <div class="kt-subheader__main">
            <!-- Header -->
            <h3 class="kt-subheader__title">{{header}} </h3>

            <!-- Separator -->
            <span class="kt-subheader__separator kt-hidden"></span>

            <!-- Breadcrums -->
            <ng-content select="[header]"></ng-content>
        </div>
    </div>
</div>