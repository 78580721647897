import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {ToggleLeftMobileSidebarAction, ToggleSidebarAction} from '../../shared/store/actions/layout.actions';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {getShowLeftMobileSidebar, getShowSidebar, getShowTopMobileSidebar, State} from '../../shared/store';
import {Observable} from 'rxjs';
import {Route} from '../../shared/constants/constants';
import {StorageHelper} from '../../shared/helpers/storage-helper';
import {UserRole} from '../../shared/enums/user-role';

/* tslint:disable:component-selector */
@Component({
    selector: '[app-home-sidebar]',
    templateUrl: './home-sidebar.component.html',
    styleUrls: ['./home-sidebar.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class HomeSidebarComponent {
    public showLeftMobileSidebar: Observable<boolean>;
    public showTopMobileSidebar: Observable<boolean>;
    public showTooltip = true;

    constructor(private router: Router,
                private store: Store<State>) {
        const showSidebar = store.select(getShowSidebar);
        showSidebar.subscribe(value => this.showTooltip = !value);
        this.showLeftMobileSidebar = store.select(getShowLeftMobileSidebar);
        this.showTopMobileSidebar = store.select(getShowTopMobileSidebar);
    }

    get isDashboardRouteActive(): boolean {
        return this.router.url === Route.ROOT ||
            this.router.url.endsWith(Route.HOME_ROOT) ||
            this.isRouteActive(Route.DASHBOARD);
    }

    get hideSection(): boolean {
        return StorageHelper.getUser() && StorageHelper.getUser().role !== UserRole.ADMIN_USER;
    }

    isRouteActive(url: string): boolean {
        return this.router.url && this.router.url.indexOf(url) > -1;
    }

    toggleSidebar(): void {
        this.store.dispatch(new ToggleSidebarAction());
    }

    toggleLeftMobileSidebar(): void {
        this.store.dispatch(new ToggleLeftMobileSidebarAction());
    }
}
