import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, merge, Observable} from 'rxjs';
import {ModalOptions, Route} from '../../../shared/constants/constants';
import {LogUtils} from '../../../shared/utils/log-utils';
import {CommonUtils} from '../../../shared/utils/common-utils';
import {DataSource} from '@angular/cdk/table';
import {CollectionViewer} from '@angular/cdk/collections';
import {getCandidates, State} from '../../../shared/store';
import {Candidate} from '../../../shared/models/candidate';
import {CandidatesService} from '../../../shared/services/candidates.service';
import {CandidateStatus, CandidateStatusItems} from '../../../shared/enums/candidate-status';
import {HubsService} from '../../../shared/services/hubs.service';
import {CandidateModalComponent} from '../candidate-modal/candidate-modal.component';
import {ConfirmModalComponent} from '../../../shared/components/modals/confirm-modal/confirm-modal.component';
import {CandidateStateModalComponent} from '../candidate-state-modal/candidate-state-modal.component';
import {SearchUtils} from '../../../shared/utils/search-utils';
import {SortUtils} from '../../../shared/utils/sort-utils';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatDatepicker} from '@angular/material/datepicker';
import {saveAs as fileSaveAs} from 'file-saver';
import * as moment from 'moment';


const scheduleTask = Promise.resolve(null);

@Component({
    selector: 'app-candidates',
    templateUrl: './candidates.component.html',
    styleUrls: ['./candidates.component.css']
})
export class CandidatesComponent implements OnInit {
    public dataSource: CandidateDataSource | null;
    public db: CandidateDatabase;
    public displayedColumns = ['code', 'email', 'gender', 'hub', 'status', 'created', 'actions'];

    /* Filters */
    public generalFilter = new UntypedFormControl();
    public stateFilter = new UntypedFormControl();
    public startControl: UntypedFormControl;
    public endControl: UntypedFormControl;
    public startDate: moment.Moment;
    public endDate: moment.Moment;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatDatepicker, {static: true}) startPicker: MatDatepicker<Date>;
    @ViewChild(MatDatepicker, {static: true}) endPicker: MatDatepicker<Date>;


    constructor(private store: Store<State>,
                private router: Router,
                private dialog: MatDialog,
                private hubsService: HubsService,
                private candidatesService: CandidatesService) {
        this.db = new CandidateDatabase(store);
    }

    get statuses(): any[] {
        return CandidateStatusItems;
    }

    ngOnInit() {
        this.dataSource = new CandidateDataSource(this.db, this.paginator, this.sort);

        /* Prepare dates for range component */
        const currentDate = moment();
        const startOfTheYearDate = moment().month(0).date(1)
        this.startDate = startOfTheYearDate;
        this.endDate = currentDate;
        this.startControl = new UntypedFormControl(startOfTheYearDate);
        this.endControl = new UntypedFormControl(currentDate);
        this.startPicker.select(startOfTheYearDate.toDate());

        const generalFilterObservable = this.generalFilter.valueChanges
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                startWith('')
            );

        const stateFilterObservable = this.stateFilter.valueChanges
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                startWith('')
            );

        combineLatest([generalFilterObservable, stateFilterObservable])
            .pipe(map(([general, state]) => ({general, state})))
            .subscribe(filter => {
                if (this.dataSource) {
                    this.dataSource.filter = filter;
                }
            });

        this.fetchCandidates();
    }

    fetchCandidates() {
        const {valid, startDate, endDate} = this.checkDates();
        if (valid)
            scheduleTask.then(() => {
                this.candidatesService.fetchCandidates(startDate, endDate);
            });
    }

    getStatusTag(candidate: Candidate): string {
        if (candidate && candidate.status) {
            switch (candidate.status) {
                case CandidateStatus.ACCEPTED:
                    return '<span class="kt-badge kt-badge--success kt-badge--inline">Accepted</span>';
                case CandidateStatus.IN_PROGRESS:
                    return '<span class="kt-badge kt-badge--warning kt-badge--inline">In Progress</span>';
                case CandidateStatus.DENIED:
                    return '<span class="kt-badge kt-badge--danger kt-badge--inline">Denied</span>';
            }
        }
        return '';
    }

    startDateChanged(): void {
        this.startDate = this.startControl.value;
    }

    endDateChanged(): void {
        this.endDate = this.endControl.value;
    }

    selectCandidate(candidateId: number): void {
        if (candidateId) {
            this.router.navigate([Route.HOME_ROOT, Route.CANDIDATES, candidateId, 'preview']);
        } else {
            LogUtils.error('Candidate id is empty');
        }
    }

    createCandidate(): void {
        /* Fetch Data */
        this.hubsService.fetchHubs();

        /* Show modal */
        const dialogRef = this.dialog.open(CandidateModalComponent, this.modalConfigurationCandidate({} as Candidate));
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.candidatesService.createCandidate(response.data);
            }
        });
    }

    updateCandidate(event: Event, candidate: Candidate): void {
        /* Prevent propagation */
        CommonUtils.preventPropagation(event);

        /* Fetch Data */
        this.hubsService.fetchHubs();

        /* Open Edit Modal */
        const dialogRef = this.dialog.open(CandidateModalComponent, this.modalConfigurationCandidate(candidate));
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.candidatesService.updateCandidate(response.data);
            }
        });
    }

    updateStatusCandidate(event: Event, candidate: Candidate): void {
        /* Prevent propagation */
        CommonUtils.preventPropagation(event);

        /* Fetch Data */
        this.hubsService.fetchHubs();

        /* Open Edit Modal */
        const dialogRef = this.dialog.open(CandidateStateModalComponent, this.modalConfigurationCandidate(candidate));
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.candidatesService.updateCandidate(response.data);
            }
        });
    }

    deleteCandidate(event: Event, candidate: Candidate): void {
        /* Prevent propagation */
        CommonUtils.preventPropagation(event);

        /* Delete Candidate */
        const dialogRef = this.dialog.open(ConfirmModalComponent, this.modalConfigurationConfirm(candidate));
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'yes') {
                this.candidatesService.deleteCandidate(candidate);
            }
        });
    }

    modalConfigurationConfirm(candidate: Candidate): any {
        return Object.assign({}, ModalOptions.CONFIRM, {
            data: {
                title: 'Delete',
                message: `Are you sure you want to delete ${candidate.code}?`
            }
        });
    }

    modalConfigurationCandidate(candidate: Candidate): any {
        return Object.assign({}, ModalOptions.CANDIDATE, {
            data: candidate
        });
    }

    search(): void {
        this.fetchCandidates();
    }

    checkDates(): { valid: Boolean, startDate: moment.Moment, endDate: moment.Moment } {
        const startDate = moment(this.startControl.value);
        const endDate = moment(this.endControl.value);
        if (startDate.isSameOrBefore(endDate)) {
            return {valid: true, startDate: startDate, endDate: endDate};
        }
        return {valid: false, startDate: null, endDate: null};
    }

    export(): void {
        const {valid, startDate, endDate} = this.checkDates();
        if (valid)
            scheduleTask.then(() => {
                var title = startDate.format('YYYY-MM-DD') + '_' + endDate.format('YYYY-MM-DD') + '.xlsx';
                this.candidatesService.exportCandidates(
                    startDate, endDate
                ).subscribe((data) => {
                    fileSaveAs(data, title);
                });

            });
    }
}

export class CandidateDatabase {
    public candidates: Observable<Candidate[]>;
    public candidatesData: Candidate[] = [];

    constructor(private store: Store<State>) {
        this.candidates = store.select(getCandidates);
        this.candidates.subscribe(data => this.candidatesData = data);
    }
}

export class CandidateDataSource extends DataSource<Candidate> {
    public filterChange = new BehaviorSubject({general: '', state: ''});
    public filteredData: Candidate[] = [];
    public renderedData: Candidate[] = [];

    constructor(private db: CandidateDatabase,
                private paginator: MatPaginator,
                private sort: MatSort) {
        super();

        // Reset to the first page when the candidate changes the filter.
        this.filterChange.subscribe(() => this.paginator.pageIndex = 0);
    }

    get filter(): CandidateFilter {
        return this.filterChange.value;
    }

    set filter(filter: CandidateFilter) {
        this.filterChange.next(filter);
    }

    connect(collectionViewer: CollectionViewer): Observable<Candidate[]> {
        const displayDataChanges = [
            this.db.candidates,
            this.filterChange,
            this.sort.sortChange,
            this.paginator.page,
        ];

        //noinspection TypeScriptValidateTypes
        return merge<any>(...displayDataChanges).pipe(map(() => {
            // Filter data
            this.filteredData = this.db.candidatesData.slice().filter((item: Candidate) => {
                return this.filterCandidate(this.filter.state, item) &&
                    SearchUtils.searchCandidate(item, this.filter.general);
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this.paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        /* Nothing */
    }

    filterCandidate(status: any, candidate: Candidate): boolean {
        return status ? candidate.status === status : true;
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: Candidate[]): Candidate[] {
        if (!this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const direction = this.sort.direction === 'asc' ? 1 : -1;
            switch (this.sort.active) {
                case 'code':
                    return direction * SortUtils.stringComparator(a.code, b.code);
                case 'email':
                    return direction * SortUtils.stringComparator(a.email, b.email);
                case 'gender':
                    return direction * SortUtils.stringComparator(a.gender, b.gender);
                case 'created':
                    return direction * SortUtils.dateComparator(a.created, b.created);
                case 'hub':
                    const locationA = a.hub ? a.hub.name : '';
                    const locationB = b.hub ? b.hub.name : '';
                    return direction * SortUtils.stringComparator(locationA, locationB);
            }
            return 0;
        });
    }
}

interface CandidateFilter {
    general: string;
    state: string;
}
