import {Action} from '@ngrx/store';
import {TestBundle} from '../../models/test-bundle';

export enum TestBundlesActionTypes {
    Load = '[TestBundles] Load Test Bundles',
    Select = '[TestBundles] Select Test Bundle',
    Create = '[TestBundles] Create Test Bundle',
    Update = '[TestBundles] Update Test Bundle',
    Delete = '[TestBundles] Delete Test Bundle'
}

export class LoadTestBundlesAction implements Action {
    readonly type = TestBundlesActionTypes.Load;

    constructor(public payload: TestBundle[]) {
    }
}

export class SelectTestBundleAction implements Action {
    readonly type = TestBundlesActionTypes.Select;

    constructor(public payload: TestBundle) {
    }
}

export class CreateTestBundleAction implements Action {
    readonly type = TestBundlesActionTypes.Create;

    constructor(public payload: TestBundle) {
    }
}

export class UpdateTestBundleAction implements Action {
    readonly type = TestBundlesActionTypes.Update;

    constructor(public payload: TestBundle) {
    }
}

export class DeleteTestBundleAction implements Action {
    readonly type = TestBundlesActionTypes.Delete;

    constructor(public payload: TestBundle) {
    }
}

export type Actions
    = LoadTestBundlesAction
    | SelectTestBundleAction
    | CreateTestBundleAction
    | UpdateTestBundleAction
    | DeleteTestBundleAction;

