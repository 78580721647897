import {Component, Input} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';
import {CommonUtils} from '../../utils/common-utils';

@Component({
    selector: 'app-validation-error',
    templateUrl: './validation-error.component.html',
    styleUrls: ['./validation-error.component.css']
})
export class ValidationErrorComponent {
    private static readonly errorMessages = {
        required: () => 'This field is required',
        email: () => 'Invalid email address',
        matchPassword: () => 'Password does not match the confirm password.',
        strong: () => 'Your password is not strong enough.',
        minlength: (params) => 'The min number of characters is ' + params.requiredLength,
        maxlength: (params) => 'The max allowed number of characters is ' + params.requiredLength,
        pattern: (params) => 'The required pattern is: ' + params.requiredPattern,
        dateinpast: (params) => 'Selected date is in past',
        positivenumber: (params) => 'Must be positive.',
        symphonyEmailRequired: () => 'The user has to be a Symphony employee.',
    };

    @Input() control: AbstractControlDirective | AbstractControl;

    get showErrors(): boolean {
        return this.control && this.control.errors && (this.control.dirty || this.control.touched);
    }

    get error(): string {
        const keys = Object.keys(this.control.errors);
        if (CommonUtils.isNotEmpty(keys)) {
            return this.getMessage(keys[0], this.control.errors[keys[0]]);
        }
        return null;
    }

    private getMessage(type: string, params: any) {
        return ValidationErrorComponent.errorMessages[type](params);
    }
}
