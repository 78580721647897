import {MapEnumPipe} from '../pipes/map-enum.pipe';
import {DatePipe} from '@angular/common';
import {CtiTests} from '../constants/constants';
import {CtiPiece} from '../models/cti-piece';
import {CtiPuzzle} from '../models/cti-puzzle';

export class MappingUtils {
    public static mapEnumValue(value: any, enumName: string) {
        return new MapEnumPipe().transform(value, enumName);
    }

    public static mapDateTimeValue(value: any) {
        return new DatePipe('en-US').transform(value, 'dd.MM.yyyy HH:mm:ss');
    }

    public static mapDateValue(value: any) {
        return new DatePipe('en-US').transform(value, 'dd.MM.yyyy');
    }

    public static nvl(data: any, def: string = ''): any {
        return data === null ? def : data;
    }

    public static mapCtiAnswers(answers: CtiPiece[][], subtest: number) {
        return [...Array(CtiTests[subtest].questions)].map(
            (_, i) => [...Array(CtiTests[subtest].selections)].map(
                (_, j) => Array.isArray(answers[i]) && answers[i][j] ? answers[i][j].index : -1
            ));
    }

    public static mapCtiPuzzle(answers: number[][], questions: CtiPuzzle[]): CtiPiece[][] {
        return answers.map((answer, index) => {
            return answer.map((p) => {
                return questions[index].options[p];
            });
        });
    }
}
