<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        EPQ Test Instructions
                    </h3>
                </div>
            </div>

            <form class="kt-form">
                <div class="kt-portlet__body">
                    <div class="kt-section kt-margin-0">
                        <div class="kt-section__content kt-section__content__text kt-details-text">
                            <p>
                                Please read each question carefully and answer it by checking <b>YES</b> or <b>NO</b>.
                                The answer "YES" means that this statement is true of you, describes you and your
                                typical behavior. The answer "NO" suggests that these statements do not apply to you and
                                your typical behavior. There are no correct or incorrect answers.
                            </p>
                        </div>

                        <div class="kt-section__content kt-section__content__text kt-details-text">
                            <br>
                            <p>
                                Let's look at one of the statements in the questionnaire together to better understand
                                how you will answer the questions asked. Example: "Do you have many different hobbies?"
                                Everyone associates a different meaning to the words "many" and "different". That
                                suggests that you should approach these questions in a way that gives them your own
                                meaning and your reference frame which you think about particular determinants of the
                                question.
                            </p>
                        </div>

                        <div class="kt-section__content kt-section__content__text kt-details-text">
                            <br>
                            <p>
                                Answer quickly and do not take too long to think about the questions.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__foot">
                    <div class="kt-form__actions kt-form__actions--right">
                        <div class="row">
                            <div class="col kt-align-left">
                                <button (click)="previousStep()"
                                        class="btn btn-label-danger btn-wide"
                                        type="button">
                                    Back
                                </button>
                            </div>
                            <div class="col kt-align-right">
                                <button (click)="nextStep()"
                                        class="btn btn-label-brand btn-wide"
                                        type="button">
                                    Start Test
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
