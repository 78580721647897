import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TestBundlesService} from '../services/test-bundles.service';
import {StorageHelper} from '../helpers/storage-helper';
import {Route} from '../constants/constants';
import {TestBundleData} from '../models/test-bundle-data';
import {LogUtils} from '../utils/log-utils';
import {ErrorHelper} from '../helpers/error-helper';

@Injectable()
export class TestsResolve implements Resolve<TestBundleData> {
    constructor(private testBundlesService: TestBundlesService,
                private router: Router) {
    }

    static handleError(ex): Observable<TestBundleData> {
        return of(ErrorHelper.handleTestsLoadError(ex));
    }

    resolve(route: ActivatedRouteSnapshot): Observable<TestBundleData> {
        const code = route.params.code;

        /* If there is no code reroute to 404 */
        if (!code) {
            this.router.navigate([Route.ERROR_404_ROOT]);
            return of({
                bundle: null,
                error: null,
                success: null,
                section: null
            });
        }

        /* If there is code try to find access */
        const access = StorageHelper.getAccessCode(code);
        if (access) {
            return this.testBundlesService
                .fetchTestBundleByCode(code, access)
                .pipe(
                    map(data => {
                        return {
                            bundle: data,
                            error: null,
                            section: 'selection',
                            success: false
                        };
                    }),
                    /* Something went wrong */
                    catchError(error => TestsResolve.handleError(error))
                );
        }

        LogUtils.info('Validate tests');

        return of({
            bundle: null,
            error: null,
            success: false,
            section: 'validate'
        });
    }
}
