<div class="kt-pricing-1 kt-pricing-1--fixed">
    <div class="kt-pricing-1__items row kt-padding-t-0">
        <div class="kt-pricing-1__item col-lg-8 offset-2">
            <div class="kt-pricing-1__visual">
                <span class="kt-pricing-1__icon kt-font-brand">
                    <i [class]="icon"></i>
                </span>
            </div>

            <span *ngIf="title" class="kt-pricing-1__price">
                {{title}}
            </span>

            <h2 *ngIf="subtitle" class="kt-pricing-1__subtitle">
                {{subtitle}}
            </h2>

            <span *ngIf="description" class="kt-pricing-1__description">
                <span>{{description}}</span>
            </span>

            <div *ngIf="actionLabel" class="kt-pricing-1__btn">
                <button (click)="action()"
                        class="btn btn-pill  btn-brand btn-wide btn-uppercase btn-bolder btn-sm"
                        type="button">
                    {{actionLabel}}
                </button>
            </div>
        </div>
    </div>
</div>