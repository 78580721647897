<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div *ngIf="!isFinished" class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        BFQ Test
                    </h3>
                </div>

                <div class="kt-portlet__head-toolbar">
                    <div class="btn-group">
                        <div class="kt-infobox__badge">Step {{currentStep}} of 132</div>
                    </div>
                </div>
            </div>

            <!--begin::Form-->
            <form class="kt-form">
                <div class="kt-portlet__body">
                    <!-- Tests -->
                    <div class="form-group form-group-last">
                        <h5 class="bfq-question kt-margin-b-20">
                            {{currentQuestion.title}}
                        </h5>

                        <div class="row bfq-questions">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-2 kt-margin-b-20">
                                <app-test-base-option
                                        (selectOption)="selectAnswer($event)"
                                        [label]="'Completely incorrect for me'"
                                        [value]="1">
                                </app-test-base-option>
                            </div>
                            <div class="col-lg-2 kt-margin-b-20">
                                <app-test-base-option
                                        (selectOption)="selectAnswer($event)"
                                        [label]="'Quite incorrect to me'"
                                        [value]="2">
                                </app-test-base-option>
                            </div>
                            <div class="col-lg-2 kt-margin-b-20">
                                <app-test-base-option
                                        (selectOption)="selectAnswer($event)"
                                        [label]="'Neither true nor false'"
                                        [value]="3">
                                </app-test-base-option>
                            </div>
                            <div class="col-lg-2 kt-margin-b-20">
                                <app-test-base-option
                                        (selectOption)="selectAnswer($event)"
                                        [label]="'Mostly true for me'"
                                        [value]="4">
                                </app-test-base-option>
                            </div>
                            <div class="col-lg-2 kt-margin-b-20">
                                <app-test-base-option
                                        (selectOption)="selectAnswer($event)"
                                        [label]="'Totally true for me'"
                                        [value]=5>
                                </app-test-base-option>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="kt-portlet__foot kt-portlet__foot--sm">
                <div class="kt-form__actions kt-form__actions--right">
                    <div class="row">
                        <div class="col kt-align-left">
                            <a (click)="previous()"
                               [class.disabled]="!isPreviousEnabled"
                               class="btn btn-clean">
                                <i class="la la-arrow-left"></i>
                                <span class="kt-hidden-mobile">Previous Question</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
