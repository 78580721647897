<div class="row row__spacing">
    <div *ngIf="padEdges" class="col"></div>

    <app-test-cti-piece
            *ngFor="let sample of puzzle.samples"
            [class.kt-cti-sample-x2_5]="isSampleX2_5(sample)"
            [class.kt-cti-sample-x3_5]="isSampleX3_5(sample)"
            [piece]="sample"
            [selectable]="false"
            class="col">
    </app-test-cti-piece>

    <app-test-cti-piece
            *ngIf="addEmptySample"
            [piece]="emptyPiece"
            [selectable]="false"
            class="col">
    </app-test-cti-piece>

    <div *ngIf="puzzle.samples.length" class="col"></div>

    <app-test-cti-piece
            (optionSelected)="selectOption($event)"
            *ngFor="let option of puzzle.options"
            [correct]="isCorrect(option)"
            [incorrect]="isIncorrect(option)"
            [piece]="option"
            [selectable]="interactive"
            [selected]="isSelected(option)"
            class="col">
    </app-test-cti-piece>

    <div *ngIf="padEdges" class="col"></div>
</div>
