import {ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-error-not-found',
    templateUrl: './error-not-found.component.html',
    styleUrls: ['./error-not-found.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorNotFoundComponent {
    @HostBinding('class') styleClass = 'kt-grid kt-grid--ver kt-grid--root';
}
