import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Route} from '../constants/constants';
import {TestsService} from '../services/tests.service';
import {TestData} from '../models/test-data';
import {ErrorHelper} from '../helpers/error-helper';

@Injectable()
export class TestCtiResolve implements Resolve<TestData> {
    constructor(private testsService: TestsService,
                private router: Router) {
    }

    static handleError(ex): Observable<TestData> {
        return of(ErrorHelper.handleTestLoadError(ex));
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const code = route.params.code;

        /* If there is no code reroute to 404 */
        if (!code) {
            this.router.navigate([Route.ERROR_404_ROOT]);
            return of({test: null, error: null, section: null});
        }

        return this.testsService
            .fetchCtiTest(code)
            .pipe(
                map(data => ({test: data, error: null, section: 'instructions'})),
                catchError(error => TestCtiResolve.handleError(error))
            );
    }
}
