import {Test} from '../models/test';
import * as moment from 'moment';
import {Duration} from 'moment';

export class CommonUtils {
    static isNotEmpty(array: any): boolean {
        return array && array.length > 0;
    }

    static isEmpty(array: any): boolean {
        return !array || array.length === 0;
    }

    static isObjectEmpty(obj: any): boolean {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    static preventPropagation(event: Event): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    static getSelectOptions(labels: Map<any, any>): Array<any> {
        return Array.from(labels, (entry) => {
            return {key: entry[0], label: entry[1]};
        });
    }

    static isNullOrUndefined(field: any): boolean {
        return typeof field === 'undefined' || field === null;
    }

    static nvl(data: any, def: string = ''): any {
        return data === null ? def : data;
    }

    static calculateDuration(test: Test): Duration {
        if (test && test.started && test.finished) {
            const started = moment.utc(test.started);
            const finished = moment.utc(test.finished);
            const duration = finished.diff(started);
            return moment.duration(duration);
        }
        return null;
    }
}
