import jspdf from 'jspdf';
import 'jspdf-autotable';
import {ReportFont} from './report-font';

export class Report {
    public static MARGIN = {
        top: 13,
        bottom: 13,
        left: 13,
        right: 13
    };

    public static DIMENSION = {
        width: 210,
        height: 297
    };

    public static INCREMENT = {
        five: 5,
        ten: 10,
    };

    public static DEFAULT_FONT_SIZE = 10;
    public static PAGE_START = 24;

    private readonly document: any;
    private currentRow: number;

    constructor(title: string) {
        /* Setup report */
        this.document = new jspdf();
        this.document.setFontSize(8);
        this.document.setProperties({title});

        /* UTF-8 support */
        this.document.addFileToVFS('PTSans.ttf', ReportFont.PT_SANS_NORMAL);
        this.document.addFileToVFS('PTSansItalic.ttf', ReportFont.PT_SANS_ITALIC);
        this.document.addFileToVFS('PTSansBold.ttf', ReportFont.PT_SANS_BOLD);
        this.document.addFileToVFS('PTSansBoldItalic.ttf', ReportFont.PT_SANS_BOLD_ITALIC);

        /* Add font */
        this.document.addFont('PTSans.ttf', 'PTSans', 'normal');
        this.document.addFont('PTSansItalic.ttf', 'PTSans', 'normal, italic');
        this.document.addFont('PTSansBold.ttf', 'PTSans', 'bold');
        this.document.addFont('PTSansBoldItalic.ttf', 'PTSans', 'bold, italic');
        this.document.setFont('PTSans');

        /* Setup header and footer */
        this.addHeader();
    }

    public static get leftPoint(): number {
        return Report.MARGIN.left;
    }

    public static get rightPoint(): number {
        return Report.DIMENSION.width - Report.MARGIN.right;
    }

    public static get topPoint(): number {
        return Report.MARGIN.top;
    }

    public static get bottomPoint(): number {
        return Report.DIMENSION.height - Report.MARGIN.bottom;
    }

    public static get availableWidth(): number {
        return Report.DIMENSION.width - Report.MARGIN.left - Report.MARGIN.right;
    }

    public get defaultTableConfiguration(): object {
        return {
            theme: 'grid',
            styles: {
                overflow: 'linebreak',
                fontSize: 7,
                lineColor: [90, 90, 90],
                font: 'PTSans',
                cellPadding: 0.7
            },
            headStyles: {
                fillColor: [225, 225, 225],
                textColor: [0, 0, 0],
                fontSize: 7,
                lineColor: [90, 90, 90],
                lineWidth: 0.1
            },
            bodyStyles: {
                lineColor: [90, 90, 90],
            },
            margin: {horizontal: 13}
        };
    }

    public positionIncrementFive(): void {
        this.currentRow += Report.INCREMENT.five;
    }

    public positionIncrementTen(): void {
        this.currentRow += Report.INCREMENT.ten;
    }

    public addHeading(heading: string, fontSize = 10): void {
        if (heading) {
            /* Heading */
            this.document.setFontSize(fontSize);
            this.document.text(heading, Report.leftPoint, this.currentRow);

            /* Increment and reset */
            this.document.setFontSize(Report.DEFAULT_FONT_SIZE);
            this.positionIncrementFive();
        }
    }

    public addHeader(): void {
        this.document.setFontSize(8);
        this.document.text(Report.leftPoint, 8, 'Symphony');

        /* Draw Line and position cursor */
        this.document.setDrawColor('#5850a6');
        this.document.setLineWidth(0.3);
        this.document.line(Report.leftPoint, Report.topPoint, Report.rightPoint, Report.topPoint);

        /* Position cursor */
        this.currentRow = Report.PAGE_START;
    }

    public printCandidateInfo(data: any): void {
        this.addHeading('Candidate Info');
        const configuration = Object.assign({}, this.defaultTableConfiguration, {
            head: [['a1', 'a2']],
            body: data,
            showHead: 'never',
            tableWidth: 90,
            startY: this.currentRow,
            columnStyles: {
                0: {fillColor: [225, 225, 225]}
            },
        });
        /* Use configuration */
        this.document.autoTable(configuration);
        this.currentRow = this.document.autoTable.previous.finalY;
        this.positionIncrementTen();
    }

    public printEpiChart(canvas: HTMLCanvasElement): void {
        if (canvas) {
            this.addHeading('EPI');
            const canvasImg = canvas.toDataURL('image/jpeg', 1.0);
            this.document.addImage(
                canvasImg,
                'JPEG',
                Report.leftPoint,
                this.currentRow,
                Report.availableWidth - 40,
                70
            );
        }
    }

    public save(name: string): void {
        this.document.save(name);
    }
}
