<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        CTI
                    </h3>
                </div>

                <div class="kt-portlet__head-toolbar">
                    <div class="btn-group">
                        <div class="kt-infobox__badge">Test {{subtest + 1}} of 4</div>
                    </div>
                </div>
            </div>

            <!--begin::Form-->
            <form class="kt-form">
                <div class="kt-portlet__body">
                    <!-- Examples -->
                    <div [ngSwitch]="subtest" class="form-group">
                        <app-test-cti-examples-test-1
                                *ngSwitchCase="0"
                                [examples]="testExamples"
                                [solutions]="testExampleSolutions">
                        </app-test-cti-examples-test-1>

                        <app-test-cti-examples-test-2
                                *ngSwitchCase="1"
                                [examples]="testExamples"
                                [solutions]="testExampleSolutions">
                        </app-test-cti-examples-test-2>

                        <app-test-cti-examples-test-3
                                *ngSwitchCase="2"
                                [examples]="testExamples"
                                [solutions]="testExampleSolutions">
                        </app-test-cti-examples-test-3>

                        <app-test-cti-examples-test-4
                                *ngSwitchCase="3"
                                [examples]="testExamples"
                                [solutions]="testExampleSolutions">
                        </app-test-cti-examples-test-4>
                    </div>
                </div>

                <div class="kt-portlet__foot">
                    <div class="kt-form__actions kt-form__actions--right">
                        <div class="row">
                            <div class="col kt-align-right">
                                <button (click)="nextStep()"
                                        class="btn btn-label-brand btn-wide"
                                        type="button">
                                    Start Test
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
