import {CtiPuzzle} from './cti-puzzle';

export enum CtiPieceType {
    SAMPLE = 'sample',
    OPTION = 'option'
}

export interface CtiPiece {
    puzzle: CtiPuzzle;
    type: CtiPieceType;
    index: number;
    imageUrl: string;
}
