import {MappingUtils} from '../utils/mapping-utils';
import {LogUtils} from '../utils/log-utils';
import {TestError} from '../enums/test-error';
import {TestBundleData} from '../models/test-bundle-data';
import {TestData} from '../models/test-data';

export class ErrorHelper {
    public static extractErrorCode(ex: any): string {
        if (ex && ex.error) {
            if (ex.error.code) return ex.error.code;
            if (ex.error.message) return ex.error.message;
        }
        return 'NONE';
    }

    public static getErrorMessage(code: string): string {
        return MappingUtils.mapEnumValue(code, 'TestError');
    }

    public static handleTestsValidationError(ex: any) {
        /* Output error */
        LogUtils.error(ex);

        /* Only handle Bad Request errors */
        if (ex && ex.status === 400) {
            const code = ErrorHelper.extractErrorCode(ex);
            switch (code) {
                /* Open success page */
                case TestError.VC_FINISHED_STATE:
                    return 'success-page';
                /* Open error page */
                case TestError.VC_WRONG_CODE_URL:
                case TestError.VC_NO_BUNDLE:
                case TestError.VC_ERROR_STATE:
                    return 'error-page';
                /* Show notification */
                case TestError.VC_MISSING_PIN_OR_CAPTCHA:
                case TestError.VC_INVALID_CAPTCHA:
                case TestError.VC_WRONG_PIN:
                    return 'notification';
                default:
                    throw ex;
            }
        } else {
            throw ex;
        }
    }

    public static handleTestsLoadError(ex: any): TestBundleData {
        /* Output error */
        LogUtils.error(ex);

        /* Only handle Bad Request errors */
        if (ex && ex.status === 400) {
            const code = ErrorHelper.extractErrorCode(ex);
            switch (code) {
                /* Test is done */
                case TestError.VA_FINISHED_STATE:
                    return {bundle: null, error: null, section: 'success'};
                /* Navigate to error page */
                case TestError.VA_WRONG_CODE_URL:
                case TestError.VA_NO_BUNDLE:
                case TestError.VA_ERROR_STATE:
                    return {bundle: null, error: code, section: 'error'};
                /* Navigate to validate page again */
                case TestError.VA_SENT_STATE:
                case TestError.VA_WRONG_ACCESS_CODE:
                    return {bundle: null, error: null, section: 'validate'};
            }
        }
        return {bundle: null, error: 'Something went wrong', section: 'error'};
    }

    public static handleTestLoadError(ex: any): TestData {
        /* Output error */
        LogUtils.error(ex);

        /* Only handle Bad Request errors */
        if (ex && ex.status === 400) {
            const code = ErrorHelper.extractErrorCode(ex);
            switch (code) {
                /* Show done message */
                case TestError.TV_FINISHED_STATE:
                    return {test: null, error: null, section: 'done'};
                /* Navigate to error page */
                case TestError.TV_WRONG_CODE_URL:
                case TestError.TV_NO_TEST:
                case TestError.TV_ERROR_STATE:
                case TestError.TV_BUNDLE_STATE:
                case TestError.TV_INVALID_STATE:
                case TestError.TV_INVALID_BUNDLE_STATE:
                    return {test: null, error: code, section: 'error'};
            }
        }
        return {test: null, error: 'Something went wrong', section: 'error'};
    }
}
