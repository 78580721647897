<div class="kt-grid kt-grid--hor kt-grid--root">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">

                <!-- Background -->
                <div app-tests-background></div>

                <!-- begin:: Content -->
                <div class="kt-container kt-grid__item kt-grid__item--fluid kt-padding-0">

                    <!-- begin:: Section -->
                    <div class="kt-negative-spacing--9"></div>

                    <!-- Test Content Goes Here -->
                    <div [ngSwitch]="section | async">
                        <!-- Instructions -->
                        <app-test-cti-instructions
                                (selected)="handleInstructionsSelection($event)"
                                *ngSwitchCase="'instructions'">
                        </app-test-cti-instructions>

                        <!-- Examples -->
                        <app-test-cti-examples
                                (selected)="handleExamplesSelection($event)"
                                *ngSwitchCase="'examples'"
                                [subtest]="subtest | async">
                        </app-test-cti-examples>

                        <!-- Questions -->
                        <app-test-cti-questions
                                (selected)="handleQuestionsSelection($event)"
                                *ngSwitchCase="'questions'"
                                [subtestStart]="subtestStart | async"
                                [subtest]="subtest | async">
                        </app-test-cti-questions>

                        <!-- Done -->
                        <app-test-base-done *ngSwitchCase="'done'"
                                            [test]="test | async"
                                            [title]="'CTI Test'">
                        </app-test-base-done>

                        <!-- Error -->
                        <app-test-base-error *ngSwitchCase="'error'"
                                             [errorData]="errorData| async"
                                             [error]="error | async"
                                             [title]="'CTI Test'">
                        </app-test-base-error>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>