import {ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation} from '@angular/core';
import {StorageHelper} from '../../shared/helpers/storage-helper';
import {getShowRightMobileSidebar, State} from '../../shared/store';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ToggleRightMobileSidebarAction} from '../../shared/store/actions/layout.actions';
import {UsersService} from '../../shared/services/users.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {HomeActionsComponent} from '../home-actions/home-actions.component';

/* tslint:disable:component-selector */
@Component({
    selector: '[app-home-header]',
    templateUrl: './home-header.component.html',
    styleUrls: ['./home-header.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class HomeHeaderComponent {
    public showRightMobileSidebar: Observable<boolean>;
    public color = 'kt-badge--unified-success';

    @HostBinding('class') styleClass = 'kt-header kt-grid__item  kt-header--fixed';

    constructor(private store: Store<State>,
                private usersService: UsersService,
                private bottomSheet: MatBottomSheet) {
        this.showRightMobileSidebar = store.select(getShowRightMobileSidebar);
        this.color = this.randomColor();
    }

    get username(): string {
        return StorageHelper.getFormattedName();
    }

    get initials(): string {
        const name = this.username;
        if (name) {
            const initials = name.match(/\b\w/g) || [];
            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        }
        return '';
    }

    toggleRightMobileSidebar(): void {
        this.store.dispatch(new ToggleRightMobileSidebarAction());
    }

    randomColor(): string {
        const options = [
            'kt-badge--unified-brand',
            'kt-badge--unified-dark',
            'kt-badge--unified-primary',
            'kt-badge--unified-success',
            'kt-badge--unified-info',
            'kt-badge--unified-warning',
            'kt-badge--unified-danger'
        ];
        const index = Math.floor(Math.random() * (options.length + 1));
        return options[index];
    }

    openBottomSheet(): void {
        this.bottomSheet.open(HomeActionsComponent);
    }

    logout(): void {
        // todo add server call here
        this.usersService.logout();
    }
}
