<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        BFQ Test Instructions
                    </h3>
                </div>
            </div>

            <form class="kt-form">
                <div class="kt-portlet__body">
                    <div class="kt-section kt-margin-0">
                        <div class="kt-section__content kt-section__content__text kt-details-text">
                            <p>
                                The statements in this questionnaire are designed to allow each person to self-assess
                                with a reflection on some personality traits. There are no "correct" and "incorrect"
                                answers and therefore it is impossible to achieve a "good" or "bad" result. It is only
                                possible to achieve results that more or less accurately describe your personality.
                                Carefully read each statement in the questionnaire and mark the number that describes
                                you and your behavior according to the following scale:
                            </p>

                            <ol>
                                <li>Completely incorrect for me</li>
                                <li>Quite incorrect to me</li>
                                <li>Neither true nor false</li>
                                <li>Mostly true for me</li>
                                <li>Totally true for me</li>
                            </ol>

                            <p>
                                Make sure you have a number (1 to 5) for each statement that corresponds to your degree
                                of agreement with the statement, or the degree to which that claim resembles your
                                description of your personality.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__foot">
                    <div class="kt-form__actions kt-form__actions--right">
                        <div class="row">
                            <div class="col kt-align-left">
                                <button (click)="previousStep()"
                                        class="btn btn-label-danger btn-wide"
                                        type="button">
                                    Back
                                </button>
                            </div>
                            <div class="col kt-align-right">
                                <button (click)="nextStep()"
                                        class="btn btn-label-brand btn-wide"
                                        type="button">
                                    Start Test
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
