<div class="kt-header kt-grid__item  kt-header--fixed">
    <!-- begin:: Header Menu -->
    <button (click)="toggleRightMobileSidebar()" class="kt-header-menu-wrapper-close">
        <i class="la la-close"></i>
    </button>

    <div [class.kt-header-menu-wrapper--on]="showRightMobileSidebar | async" class="kt-header-menu-wrapper">
        <div class="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default">
            <ul class="kt-menu__nav ">
                <li class="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active">
                    <a (click)="openBottomSheet()" class="kt-menu__link kt-menu__toggle">
                        <span class="kt-menu__link-text">Quick Actions</span>
                        <i class="kt-menu__ver-arrow la la-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <!-- end:: Header Menu -->

    <div class="kt-header__topbar">
        <!--begin: User Bar -->
        <div class="kt-header__topbar-item kt-header__topbar-item--user">
            <div class="kt-header__topbar-wrapper">
                <div class="kt-header__topbar-user">
                    <span class="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
                    <span class="kt-header__topbar-username kt-hidden-mobile">{{username}}</span>
                    <span [ngClass]="color"
                          class="kt-badge kt-badge--username kt-badge--lg kt-badge--rounded kt-badge--bold">
                        {{initials}}
                    </span>
                </div>
            </div>
        </div>

        <div class="kt-header__topbar-item dropdown">
            <div (click)="logout()"
                 [matTooltipPosition]="'below'"
                 [matTooltipShowDelay]="500"
                 [matTooltip]="'Logout'"
                 class="kt-header__topbar-wrapper">
                <span class="kt-header__topbar-icon">
                    <svg class="kt-svg-icon" height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                         xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                            <rect height="24" id="bound" width="24" x="0" y="0"/>
                            <path d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z"
                                  fill="#000000" fill-rule="nonzero" id="Path-103" opacity="0.3"
                                  transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000) "/>
                            <rect fill="#000000" height="12" id="Rectangle"
                                  opacity="0.3"
                                  rx="1"
                                  transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000) "
                                  width="2" x="13" y="6"/>
                            <path d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z"
                                  fill="#000000" fill-rule="nonzero" id="Path-104"
                                  transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000) "/>
                        </g>
                    </svg>
                </span>
            </div>
        </div>
    </div>
</div>