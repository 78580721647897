import {Action} from '@ngrx/store';
import {DashboardStatistic} from '../../models/dashboard-statistic';

export enum StatisticsActionTypes {
    Load = '[Statistics] Load Statistics',
}

export class LoadStatisticsAction implements Action {
    readonly type = StatisticsActionTypes.Load;

    constructor(public payload: DashboardStatistic) {
    }
}

export type Actions
    = LoadStatisticsAction;

