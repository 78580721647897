import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-portlet',
    templateUrl: './portlet.component.html',
    styleUrls: ['./portlet.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PortletComponent {
    @Input() icon: string;
    @Input() header: string;
    @Input() headerClass: string;
    @Input() showToolbar = true;
    @Input() bodyClass = 'kt-portlet__body';
    @Input() mainClass = 'kt-portlet kt-portlet--mobile';
}
