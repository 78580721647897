import {CommonUtils} from '../utils/common-utils';

export enum CandidateStatus {
    ACCEPTED = 'ACCEPTED',
    IN_PROGRESS = 'IN_PROGRESS',
    DENIED = 'DENIED'
}

export const CandidateStatusLabels = new Map<string, string>([
    [CandidateStatus.ACCEPTED, 'Accepted'],
    [CandidateStatus.IN_PROGRESS, 'In Progress'],
    [CandidateStatus.DENIED, 'Denied'],
]);

export const CandidateStatusItems = CommonUtils.getSelectOptions(CandidateStatusLabels);
