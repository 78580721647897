import {Component, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    getLoading,
    getShowLeftMobileSidebar,
    getShowRightMobileSidebar,
    getShowSidebar,
    getShowTopMobileSidebar,
    State
} from './shared/store';
import {NotificationsService} from './shared/services/notifications.service';
import {NotificationPanelComponent} from './shared/components/notification-panel/notification-panel.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {combineLatest, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {StorageHelper} from './shared/helpers/storage-helper';
import {UsersService} from './shared/services/users.service';

/* tslint:disable:component-selector */
@Component({
    selector: 'body[app-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    static BASE_CLASS = `kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed
    kt-header-mobile--fixed kt-subheader--enabled kt-subheader--transparent kt-aside--enabled kt-aside--fixed `;
    static TEST_ROUTES = [
        '/tests/',
        '/test/'
    ];

    public loading: Observable<boolean>;

    @HostBinding('class') styleClass = AppComponent.BASE_CLASS;

    constructor(private snackBar: MatSnackBar,
                private store: Store<State>,
                private router: Router,
                private location: Location,
                private authService: SocialAuthService,
                private usersService: UsersService,
                private notificationsService: NotificationsService) {

        /* Loading indicator */
        this.loading = store.select(getLoading);

        /* Sidebars */
        const sidebar = store.select(getShowSidebar);
        const mobileLeftSidebar = store.select(getShowLeftMobileSidebar);
        const mobileRightSidebar = store.select(getShowRightMobileSidebar);
        const mobileTop = store.select(getShowTopMobileSidebar);

        combineLatest([sidebar, mobileLeftSidebar, mobileRightSidebar, mobileTop])
            .pipe()
            .subscribe(data => {
                this.styleClass = [
                    AppComponent.BASE_CLASS,
                    data[0] ? 'kt-aside--minimize' : '',
                    data[1] ? 'kt-aside--on' : '',
                    data[2] ? 'kt-header-menu-wrapper--on' : '',
                    data[3] ? 'kt-header__topbar--mobile-on' : '',
                    this.testClass
                ].join(' ');
            });

        /* Display notifications */
        this.notificationsService.dispatcher.subscribe((config) => {
            if (config) {
                this.snackBar.openFromComponent(NotificationPanelComponent, config);
            } else {
                this.snackBar.dismiss();
            }
        });
    }

    get testClass(): string {
        if (AppComponent.TEST_ROUTES.some(route => this.isRouteActive(route))) {
            return 'symphony-test-page';
        }
        return '';
    }

    isRouteActive(url: string): boolean {
        const route = this.location.path(false) || '';
        return route.indexOf(url) > -1;
    }

    ngOnInit() {
        this.authService.authState.subscribe((user: SocialUser) => {
            /* Save user and token */
            StorageHelper.setToken(user.idToken);

            /* Invoke user info service */
            this.usersService.fetchCurrentUserInfo().subscribe(data => {
                /* Merge social user and psy user */
                StorageHelper.setUser(Object.assign({}, data, user));

                /* Navigate */
                this.router.navigate(['/home']);
            }, err => {
                this.notificationsService.error(
                    'Error',
                    'Oops, something went wrong'
                );
            });
        });
    }
}
