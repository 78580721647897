import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {Location} from '@angular/common';

@Component({
    selector: 'app-tests-error',
    templateUrl: './tests-error.component.html',
    styleUrls: ['./tests-error.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TestsErrorComponent {
    @Input() error: string = null;

    constructor(private location: Location) {
    }

    back(): void {
        this.location.back();
    }
}
