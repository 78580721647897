import {Component, EventEmitter, Input, Output} from '@angular/core';

/* tslint:disable:component-selector */
@Component({
    selector: '[app-notification-page]',
    templateUrl: './notification-page.component.html',
    styleUrls: ['./notification-page.component.css']
})
export class NotificationPageComponent {
    @Input() icon: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() description: string;
    @Input() actionLabel: string;

    @Output() actionSelected = new EventEmitter<void>();

    action(): void {
        this.actionSelected.emit();
    }
}
