import {Injectable} from '@angular/core';
import {Endpoint} from '../constants/constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {State} from '../store';
import {
    CreateCandidateAction,
    DeleteCandidateAction,
    LoadCandidatesAction,
    LoadSearchCandidatesAction,
    UpdateCandidateAction
} from '../store/actions/candidates.actions';
import {Observable, of} from 'rxjs';
import {Candidate} from '../models/candidate';
import {LogUtils} from '../utils/log-utils';
import {NotificationsService} from './notifications.service';
import {Router} from '@angular/router';
import {Moment} from 'moment';

@Injectable({
    providedIn: 'root'
})
export class CandidatesService {
    constructor(private http: HttpClient,
                private store: Store<State>,
                private router: Router,
                private notificationsService: NotificationsService) {
    }

    prepareCandidateParams(startDate: Moment, endDate: Moment): HttpParams {
        const start = startDate.format('YYYY-MM-DD');
        const end = endDate.format('YYYY-MM-DD');
        return new HttpParams()
            .set('start_date', start)
            .set('end_date', end);
    }

    fetchCandidates(startDate: Moment, endDate: Moment): void {
        const params = this.prepareCandidateParams(startDate, endDate)
        this.http
            .get<Candidate[]>(Endpoint.CANDIDATES, {params})
            .subscribe((data: Candidate[]) => {
                this.store.dispatch(new LoadCandidatesAction(data));
            });
    }

    fetchSearchCandidates(): void {
        this.http.get<Candidate[]>(Endpoint.CANDIDATES_SEARCH)
            .subscribe((data: Candidate[]) => {
                this.store.dispatch(new LoadSearchCandidatesAction(data));
            });
    }

    exportCandidates(startDate: Moment, endDate: Moment): Observable<Blob> {
        const params = this.prepareCandidateParams(startDate, endDate)
        return this.http
            .get(Endpoint.CANDIDATES_EXPORT, {params: params, responseType: 'blob'});
    }

    fetchCandidate(id: string): Observable<Candidate> {
        return id ? this.http.get<Candidate>(Endpoint.CANDIDATES.concat(id)) : of(null);
    }

    createCandidate(candidate: Candidate): void {
        if (candidate) {
            this.http
                .post<Candidate>(Endpoint.CANDIDATES, candidate)
                .subscribe((data: Candidate) => {
                    this.store.dispatch(new CreateCandidateAction(data));
                    this.router.navigate(['/home', 'candidates', data.id, 'preview']);
                    this.notifyUser(data, 'created');
                });
        } else {
            LogUtils.error('Candidate object is empty');
        }
    }

    updateCandidate(candidate: Candidate): void {
        if (candidate && candidate.id) {
            this.http
                .put<Candidate>(Endpoint.CANDIDATES.concat(candidate.id.toString()), candidate)
                .subscribe((data: Candidate) => {
                    this.store.dispatch(new UpdateCandidateAction(data));
                    this.notifyUser(data, 'updated');
                });
        } else {
            LogUtils.error('Candidate id or object is empty');
        }
    }

    deleteCandidate(candidate: Candidate): void {
        if (candidate && candidate.id) {
            this.http
                .delete(Endpoint.CANDIDATES.concat(candidate.id.toString()))
                .subscribe(() => {
                    this.store.dispatch(new DeleteCandidateAction(candidate));
                    this.notifyUser(candidate, 'deleted');
                });
        } else {
            LogUtils.error('Candidate id or object is empty');
        }
    }

    notifyUser(candidate: Candidate, action: string): void {
        this.notificationsService.success('Success', `Candidate ${candidate.code} ${action}.`);
    }
}
