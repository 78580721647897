import {ActivatedRoute} from '@angular/router';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {CtiPiece} from '../../../shared/models/cti-piece';
import {CtiPuzzle} from 'src/app/shared/models/cti-puzzle';
import {CtiTestExamples, CtiTestExampleSolutions} from '../../../shared/constants/constants';
import {TestsService} from '../../../shared/services/tests.service';

@Component({
    selector: 'app-test-cti-examples',
    templateUrl: './test-cti-examples.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestCtiExamplesComponent implements OnInit {
    @Input() subtest = 0;
    @Output() selected = new EventEmitter<string>();

    constructor(private testsService: TestsService,
                private route: ActivatedRoute) {
    }

    get testExamples(): CtiPuzzle[] {
        return CtiTestExamples[this.subtest];
    }

    get testExampleSolutions(): CtiPiece[][] {
        return CtiTestExampleSolutions[this.subtest];
    }

    get code(): string {
        return this.route.snapshot.params.code;
    }

    ngOnInit(): void {
        window.scroll(0, 0);
    }

    nextStep(): void {
        this.selected.next('next');
    }
}
