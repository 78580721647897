<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div *ngIf="!isFinished" class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        EPQ Test
                    </h3>
                </div>

                <div class="kt-portlet__head-toolbar">
                    <div class="btn-group">
                        <div class="kt-infobox__badge">Step {{currentStep}} of 90</div>
                    </div>
                </div>
            </div>

            <!--begin::Form-->
            <form class="kt-form">
                <div class="kt-portlet__body">
                    <!-- Tests -->
                    <div class="form-group form-group-last">
                        <h5 class="epq-question kt-margin-b-20">
                            {{currentQuestion.title}}
                        </h5>

                        <div class="row">
                            <div class="col-lg-6">
                                <app-test-base-option
                                        (selectOption)="selectAnswer($event)"
                                        [label]="'Yes'"
                                        [value]="'yes'">
                                </app-test-base-option>
                            </div>
                            <div class="col-lg-6">
                                <app-test-base-option
                                        (selectOption)="selectAnswer($event)"
                                        [label]="'No'"
                                        [value]="'no'">
                                </app-test-base-option>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="kt-portlet__foot kt-portlet__foot--sm">
                <div class="kt-form__actions kt-form__actions--right">
                    <div class="row">
                        <div class="col kt-align-left">
                            <a (click)="previous()"
                               [class.disabled]="!isPreviousEnabled"
                               class="btn btn-clean">
                                <i class="la la-arrow-left"></i>
                                <span class="kt-hidden-mobile">Previous Question</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
