import {Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {StorageHelper} from '../../shared/helpers/storage-helper';
import {TestState} from '../../shared/models/test-state';
import {Store} from '@ngrx/store';
import {State} from '../../shared/store';
import {LogUtils} from '../../shared/utils/log-utils';
import {LoadTestSectionAction} from '../../shared/store/actions/tests.actions';
import {TestError} from '../../shared/enums/test-error';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class TestBaseQuestions<T> implements OnChanges {
    @Input() localState: TestState = null;
    @Output() selected = new EventEmitter<string>();

    protected currentIndex = 0;
    protected disablePrevious = false;
    protected answers: T[] = [];

    protected constructor(protected store: Store<State>) {

    }

    get currentStep(): number {
        return this.currentIndex + 1;
    }

    get isPreviousEnabled(): boolean {
        return this.currentIndex > 0 && !this.disablePrevious;
    }

    get isFinished(): boolean {
        return this.currentIndex === this.questionsLength;
    }

    abstract get code(): string;

    abstract get questionsLength(): number;

    loadLocalTestData(testState: TestState): void {
        if (testState.state !== 'error') {
            this.answers = this.localState.answers.slice();
            this.currentIndex = this.localState.question;
            this.disablePrevious = this.localState.disablePrevious;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.localState && changes.localState.currentValue) {
            this.loadLocalTestData(changes.localState.currentValue);
        }
    }

    updateTestState(disablePrevious: boolean = false): void {
        StorageHelper.setCurrentTestState({
            code: this.code,
            subtest: 0,
            answers: this.answers,
            question: this.currentIndex,
            state: 'questions',
            disablePrevious
        });
    }

    selectAnswer(answer: T): void {
        this.answers[this.currentIndex++] = answer;
        this.disablePrevious = false;
        this.updateTestState();

        if (this.isFinished) {
            this.save();
        }
    }

    previous(): void {
        this.disablePrevious = true;
        this.currentIndex--;
        this.updateTestState(true);
    }

    nextStep(): void {
        this.selected.next('next');
    }

    handleTestError(ex: Error): void {
        /* Output error */
        LogUtils.error(ex);

        /* Move to error screen */
        this.store.dispatch(new LoadTestSectionAction({
                test: null,
                error: TestError.TV_ERROR_STATE,
                section: 'error'
            }
        ));

        /* Clear local data */
        StorageHelper.clearTestData(this.code);
    }

    abstract save(): void;
}
