import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

export class FormHelper {
    public static validateAllFormFields(formGroup: UntypedFormGroup): UntypedFormGroup {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
        return formGroup;
    }
}
