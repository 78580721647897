import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-dashboard-statistic-box',
    templateUrl: './dashboard-statistic-box.component.html',
    styleUrls: ['./dashboard-statistic-box.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardStatisticBoxComponent {
    @Input() title: string;
    @Input() description: string;
}
