import {CommonUtils} from '../utils/common-utils';

export enum TestStatus {
    CREATED = 'CREATED',
    SENDING = 'SENDING',
    SENT = 'SENT',
    STARTED = 'STARTED',
    FINISHED = 'FINISHED',
    SCHEDULED = 'SCHEDULED',
    ERROR = 'ERROR'
}

export const TestStatusLabels = new Map<string, string>([
    [TestStatus.CREATED, 'Created'],
    [TestStatus.SENDING, 'Sending'],
    [TestStatus.SENT, 'Sent'],
    [TestStatus.STARTED, 'Started'],
    [TestStatus.FINISHED, 'Finished'],
    [TestStatus.ERROR, 'Error Occurred'],
    [TestStatus.SCHEDULED, 'Schuduled'],
]);

export const TestStatusItems = CommonUtils.getSelectOptions(TestStatusLabels);
