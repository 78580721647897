import {Action} from '@ngrx/store';
import {Candidate} from '../../models/candidate';

export enum CandidatesActionTypes {
    Load = '[Candidates] Load Candidates',
    Search = '[Candidates] Search Candidates',
    Create = '[Candidates] Create Candidate',
    Update = '[Candidates] Update Candidate',
    Delete = '[Candidates] Delete Candidate',
    Select = '[Candidates] Select Candidate',
    SelectBundle = '[Candidates] Select Candidate Test Bundle'
}

export class LoadCandidatesAction implements Action {
    readonly type = CandidatesActionTypes.Load;

    constructor(public payload: Candidate[]) {
    }
}

export class LoadSearchCandidatesAction implements Action {
    readonly type = CandidatesActionTypes.Search;

    constructor(public payload: Candidate[]) {
    }
}

export class CreateCandidateAction implements Action {
    readonly type = CandidatesActionTypes.Create;

    constructor(public payload: Candidate) {
    }
}

export class UpdateCandidateAction implements Action {
    readonly type = CandidatesActionTypes.Update;

    constructor(public payload: Candidate) {
    }
}

export class DeleteCandidateAction implements Action {
    readonly type = CandidatesActionTypes.Delete;

    constructor(public payload: Candidate) {
    }
}

export class SelectCandidateAction implements Action {
    readonly type = CandidatesActionTypes.Select;

    constructor(public payload: Candidate) {
    }
}

export class SelectCandidateBundleAction implements Action {
    readonly type = CandidatesActionTypes.SelectBundle;

    constructor(public payload: number) {
    }
}

export type Actions
    = LoadCandidatesAction
    | LoadSearchCandidatesAction
    | CreateCandidateAction
    | UpdateCandidateAction
    | DeleteCandidateAction
    | SelectCandidateAction
    | SelectCandidateBundleAction;

