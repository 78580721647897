import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {CtiPiece} from '../../../shared/models/cti-piece';

@Component({
    selector: 'app-test-cti-piece',
    templateUrl: './test-cti-piece.component.html',
    styleUrls: ['./test-cti-piece.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestCtiPieceComponent {
    @Input() piece: CtiPiece = null;
    @Input() correct = false;
    @Input() incorrect = false;
    @Input() selected = false;
    @Input() selectable = true;
    @Output() optionSelected = new EventEmitter<CtiPiece>();

    select(): void {
        if (this.selectable) {
            this.optionSelected.emit(this.piece);
        }
    }
}
