import {CtiPiece, CtiPieceType} from '../models/cti-piece';
import {CtiPuzzle, CtiPuzzleType} from '../models/cti-puzzle';
import {EpiOption} from '../models/epi-option';
import {EpqOption} from '../models/epq-option';
import {BfqOption} from '../models/bfq-option';

export const Key = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    ESCAPE: 27,
    SPACE: 32,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    NUMBER_2: 50,
    NUMBER_3: 51,
    HASH: 35
};

export const Endpoint = {
    CANDIDATES: '/api/candidates/',
    CANDIDATES_SEARCH: '/api/candidates/search/',
    CANDIDATES_EXPORT: '/api/candidates/export/',
    EMAILS: '/api/emails/',
    HUBS: '/api/hubs/',
    LOGIN: '/api/services/jwt/create/',
    NOTIFICATIONS: '/api/notifications/',
    DASHBOARD: '/api/dashboard/',
    TEST_BUNDLES: '/api/test-bundles/',
    TEST_BUNDLES_SENDING: '/api/test-bundles/sending/',
    TESTS_CTI_VALIDATE: '/api/tests/validate/cti/',
    TESTS_CTI_ANSWERS: '/api/tests/answers/cti/',
    TESTS_CTI_STATE: '/api/tests/state/cti/',
    TESTS_EPI_VALIDATE: '/api/tests/validate/epi/',
    TESTS_EPI_ANSWERS: '/api/tests/answers/epi/',
    TESTS_EPI_STATE: '/api/tests/state/epi/',
    TESTS_EPQ_VALIDATE: '/api/tests/validate/epq/',
    TESTS_EPQ_ANSWERS: '/api/tests/answers/epq/',
    TESTS_EPQ_STATE: '/api/tests/state/epq/',
    TESTS_BFQ_VALIDATE: '/api/tests/validate/bfq/',
    TESTS_BFQ_ANSWERS: '/api/tests/answers/bfq/',
    TESTS_BFQ_STATE: '/api/tests/state/bfq/',
    TESTS_VALIDATE_ACCESS: '/api/tests/validate/access/',
    TESTS_VALIDATE_CODE: '/api/tests/validate/code/',
    USER_INFO: '/api/auth/info',
    USERS: '/api/users/'
};

export const Route = {
    ROOT: '/',
    HOME: 'home',
    DASHBOARD: 'dashboard',
    HOME_ROOT: '/home',
    USERS: 'users',
    CANDIDATES: 'candidates',
    CANDIDATE_PREVIEW: 'candidates/:id/preview',
    SETTINGS: 'settings',
    LOGIN: 'login',
    TESTS: 'tests',
    TEST: 'test',
    TEST_ROOT: '/test',
    TEST_EPI: 'test/EPI/:code',
    TEST_EPQ: 'test/EPQ/:code',
    TEST_BFQ: 'test/BFQ/:code',
    TEST_CTI: 'test/CTI/:code',
    ERROR_404: '404',
    ERROR_404_ROOT: '/404',
    /* tests */
    CANDIDATE_TESTS: 'tests/:code',
};

export const ModalOptions = {
    CONFIRM: {
        width: '50vw',
        hasBackdrop: true,
        panelClass: 'confirm-panel'
    },
    EMAIL: {
        width: '50vw',
        hasBackdrop: true,
        panelClass: 'confirm-panel'
    },
    USER: {
        width: '80vw',
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'user-panel'
    },
    CANDIDATE: {
        width: '80vw',
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'user-panel'
    },
    TEST: {
        width: '80vw',
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'test-panel'
    },
};

export const MonthNames = [
    {id: 0, name: 'January'},
    {id: 1, name: 'February'},
    {id: 2, name: 'March'},
    {id: 3, name: 'April'},
    {id: 4, name: 'May'},
    {id: 5, name: 'June'},
    {id: 6, name: 'July'},
    {id: 7, name: 'August'},
    {id: 8, name: 'September'},
    {id: 9, name: 'October'},
    {id: 10, name: 'November'},
    {id: 11, name: 'December'}
];

export const DateLimit = {
    MIN: new Date(1960, 0, 1),
    MAX: new Date(2025, 0, 1)
};

export interface CtiTestInfo {
    examples: number;
    questions: number;
    samples: number;
    options: number;
    selections: number;
    timeSeconds: number;
}

export const CtiTests: CtiTestInfo[] = [
    {
        examples: 3,
        questions: 13,
        samples: 3,
        options: 6,
        selections: 1,
        timeSeconds: 180
    },
    {
        examples: 2,
        questions: 14,
        samples: 0,
        options: 5,
        selections: 2,
        timeSeconds: 240
    },
    {
        examples: 3,
        questions: 13,
        samples: 1,
        options: 6,
        selections: 1,
        timeSeconds: 180
    },
    {
        examples: 3,
        questions: 10,
        samples: 1,
        options: 5,
        selections: 1,
        timeSeconds: 150
    }
];

function rangeMap(length: number, func: (index: number) => any): any[] {
    return [...Array(length)].map((_, index) => func(index));
}

function buildCtiPiece(puzzle: CtiPuzzle, type: CtiPieceType, index: number): CtiPiece {
    const imageUrl = `../../assets/media/cti/test${puzzle.subtest}/${puzzle.type}${puzzle.index}/${type}${index}.svg`;
    return {puzzle, type, index, imageUrl};
}

export const ctiPieceEmpty: CtiPiece = {
    puzzle: null,
    type: CtiPieceType.SAMPLE,
    index: -1,
    imageUrl: '../../assets/media/cti/empty.svg'
};

function buildCtiRowGroup(subtest: number, type: CtiPuzzleType): CtiPuzzle[] {
    const test = CtiTests[subtest];
    return rangeMap(test[`${type}s`],
        index => {
            const puzzle: CtiPuzzle = {subtest, type, index, samples: [], options: []};
            puzzle.samples = rangeMap(test.samples, i => buildCtiPiece(puzzle, CtiPieceType.SAMPLE, i));
            puzzle.options = rangeMap(test.options, i => buildCtiPiece(puzzle, CtiPieceType.OPTION, i));
            return puzzle;
        }
    );
}

export const CtiTestExamples: CtiPuzzle[][] = CtiTests.map(
    (_, subtest) => buildCtiRowGroup(subtest, CtiPuzzleType.EXAMPLE)
);

export const CtiTestExampleSolutions: CtiPiece[][][] = [
    [[2], [4], [4]],
    [[1, 3], [2, 4]],
    [[1], [2], [5]],
    [[2], [3], [1]]
].map(
    (testExampleSolutions, subtest) => testExampleSolutions.map(
        (exampleSolution, exampleIndex) => exampleSolution.map(
            index => CtiTestExamples[subtest][exampleIndex].options[index]
        )
    )
);

export const CtiTestQuestions: CtiPuzzle[][] = CtiTests.map(
    (_, subtest) => buildCtiRowGroup(subtest, CtiPuzzleType.QUESTION)
);

export const Emotions = {
    SOCIABLE: {
        id: 'SOCIABLE',
        title: 'Sociable',
        description: 'Kind, friendly, usually enjoys spending time with people'
    },
    OBEDIENT: {
        id: 'OBEDIENT',
        title: 'Obedient',
        description: 'Usually complies with an order or request without objecting'
    },
    BROODING: {
        id: 'BROODING',
        title: 'Brooding',
        description: 'Silently stews in anger, keeping it to oneself'
    },
    GLOOMY: {
        id: 'GLOOMY',
        title: 'Gloomy',
        description: 'Disheartened, in a sad and dark type of mood'
    },
    IMPULSIVE: {
        id: 'IMPULSIVE',
        title: 'Impulsive',
        description: 'Prone to act on impulse without considering the effects'
    },
    QUARRELSOME: {
        id: 'QUARRELSOME',
        title: 'Quarrelsome',
        description: 'Starts arguments often, confrontational'
    },
    CAUTIOUS: {
        id: 'CAUTIOUS',
        title: 'Cautious',
        description: 'Careful, worried about what might happen'
    },
    ADVENTUROUS: {
        id: 'ADVENTUROUS',
        title: 'Adventurous',
        description: 'Often tries new activities for excitement'
    },
    SELF_CONSCIOUS: {
        id: 'SELF-CONSCIOUS',
        title: 'Self-conscious',
        description: 'Usually worried about the opinions of people around them'
    },
    SHY: {
        id: 'SHY',
        title: 'Shy',
        description: 'Timid in the company of other people and new situations'
    },
    RESENTFUL: {
        id: 'RESENTFUL',
        title: 'Resentful',
        description: 'Irritable, combative, becomes frustrated or upset easily'
    },
    AFFECTIONATE: {
        id: 'AFFECTIONATE',
        title: 'Affectionate',
        description: 'Readily feeling or showing fondness or tenderness'
    },
};

export const EmotionQuestions: EpiOption[][] = [
    /* Page one */
    [Emotions.AFFECTIONATE, Emotions.ADVENTUROUS],
    [Emotions.RESENTFUL, Emotions.SHY],
    [Emotions.IMPULSIVE, Emotions.ADVENTUROUS],
    [Emotions.GLOOMY, Emotions.RESENTFUL],
    [Emotions.IMPULSIVE, Emotions.CAUTIOUS],
    [Emotions.BROODING, Emotions.CAUTIOUS],
    [Emotions.ADVENTUROUS, Emotions.SOCIABLE],
    [Emotions.AFFECTIONATE, Emotions.IMPULSIVE],
    [Emotions.ADVENTUROUS, Emotions.GLOOMY],
    [Emotions.CAUTIOUS, Emotions.RESENTFUL],
    [Emotions.QUARRELSOME, Emotions.OBEDIENT],
    [Emotions.OBEDIENT, Emotions.SHY],
    [Emotions.SOCIABLE, Emotions.SELF_CONSCIOUS],
    [Emotions.ADVENTUROUS, Emotions.RESENTFUL],
    /* Page two */
    [Emotions.SELF_CONSCIOUS, Emotions.RESENTFUL],
    [Emotions.SELF_CONSCIOUS, Emotions.QUARRELSOME],
    [Emotions.QUARRELSOME, Emotions.AFFECTIONATE],
    [Emotions.OBEDIENT, Emotions.ADVENTUROUS],
    [Emotions.IMPULSIVE, Emotions.SELF_CONSCIOUS],
    [Emotions.SELF_CONSCIOUS, Emotions.GLOOMY],
    [Emotions.SOCIABLE, Emotions.GLOOMY],
    [Emotions.ADVENTUROUS, Emotions.QUARRELSOME],
    [Emotions.BROODING, Emotions.IMPULSIVE],
    [Emotions.OBEDIENT, Emotions.BROODING],
    [Emotions.BROODING, Emotions.SOCIABLE],
    [Emotions.BROODING, Emotions.ADVENTUROUS],
    [Emotions.SHY, Emotions.IMPULSIVE],
    [Emotions.CAUTIOUS, Emotions.GLOOMY],
    /* Page three */
    [Emotions.RESENTFUL, Emotions.AFFECTIONATE],
    [Emotions.SHY, Emotions.SELF_CONSCIOUS],
    [Emotions.CAUTIOUS, Emotions.SELF_CONSCIOUS],
    [Emotions.SOCIABLE, Emotions.QUARRELSOME],
    [Emotions.QUARRELSOME, Emotions.SHY],
    [Emotions.IMPULSIVE, Emotions.SOCIABLE],
    [Emotions.ADVENTUROUS, Emotions.CAUTIOUS],
    [Emotions.SHY, Emotions.BROODING],
    [Emotions.SOCIABLE, Emotions.CAUTIOUS],
    [Emotions.RESENTFUL, Emotions.OBEDIENT],
    [Emotions.SELF_CONSCIOUS, Emotions.OBEDIENT],
    [Emotions.CAUTIOUS, Emotions.AFFECTIONATE],
    [Emotions.BROODING, Emotions.SELF_CONSCIOUS],
    [Emotions.AFFECTIONATE, Emotions.SHY],
    [Emotions.GLOOMY, Emotions.QUARRELSOME],
    [Emotions.GLOOMY, Emotions.AFFECTIONATE],
    [Emotions.CAUTIOUS, Emotions.QUARRELSOME],
    /* Page four */
    [Emotions.AFFECTIONATE, Emotions.OBEDIENT],
    [Emotions.ADVENTUROUS, Emotions.SELF_CONSCIOUS],
    [Emotions.GLOOMY, Emotions.OBEDIENT],
    [Emotions.IMPULSIVE, Emotions.GLOOMY],
    [Emotions.RESENTFUL, Emotions.IMPULSIVE],
    [Emotions.SELF_CONSCIOUS, Emotions.AFFECTIONATE],
    [Emotions.AFFECTIONATE, Emotions.BROODING],
    [Emotions.SHY, Emotions.ADVENTUROUS],
    [Emotions.IMPULSIVE, Emotions.QUARRELSOME],
    [Emotions.GLOOMY, Emotions.SHY],
    [Emotions.SHY, Emotions.SOCIABLE],
    [Emotions.OBEDIENT, Emotions.CAUTIOUS],
    [Emotions.SOCIABLE, Emotions.RESENTFUL],
    [Emotions.QUARRELSOME, Emotions.BROODING],
    [Emotions.RESENTFUL, Emotions.BROODING],
    [Emotions.OBEDIENT, Emotions.IMPULSIVE],
    [Emotions.OBEDIENT, Emotions.SOCIABLE],
];

export const EpqQuestions: EpqOption[] = [
    {title: 'Do you have many different hobbies?'},
    {title: 'Do you give yourself enough time to think before you do something?'},
    {title: 'Does your mood change often?'},
    {title: 'Have you ever accepted praise even though you knew that someone else actually deserved it?'},
    {title: 'Are you a talkative person?'},
    {title: 'Would you worry about being in debt?'},
    {title: 'Do you ever feel “just miserable” for no reason?'},
    {title: 'Have you ever taken more than belonged to you?'},
    {title: 'Do you lock your door carefully in the evening?'},
    {title: 'Are you rather lively?'},
    {title: 'Would you be very upset to see a child or animal suffering?'},
    {title: 'Do you often worry about things you should not have done or said?'},
    {title: 'Do you always keep your promise, no matter how difficult it may be?'},
    {title: 'Can you usually let yourself go and enjoy yourself at a lively party?'},
    {title: 'Are you an irritable person?'},
    {title: 'Have you ever blamed another person, even though you knew that you were actually guilty?'},
    {title: 'Do you enjoy meeting new people?'},
    {title: 'Do you think it is wise to insure with an insurance company?'},
    {title: 'Are your feelings easily hurt?'},
    {title: 'Are all your habits good and desirable?'},
    {title: 'Do you tend to keep in the background on social occasions?'},
    {title: 'Would you use drugs that could have unknown or dangerous consequences?'},
    {title: 'Do you often feel “fed up”?'},
    {title: 'Have you ever taken something (even just a needle or a button) that wasn\'t yours?'},
    {title: 'Do you like going out a lot?'},
    {title: 'Does it make you happy to hurt the people you love?'},
    {title: 'Are you often riddled with guilt?'},
    {title: 'Do you sometimes talk about things you don\'t really know much about?'},
    {title: 'Do you prefer reading to meeting people?'},
    {title: 'Do you have an enemy who wants to harm you?'},
    {title: 'Would you call yourself a nervous person?'},
    {title: 'Do you have many friends?'},
    {title: 'Do you like jokes at someone else’s expense that can sometimes seriously hurt their feelings?'},
    {title: 'Are you almost always worried about something or someone?'},
    {title: 'As a child, did you always obey right away without complaining?'},
    {title: 'Would you call yourself happy-go-lucky?'},
    {title: 'Do cleanliness and good behavior mean a lot to you?'},
    {title: 'Do you worry about awful things that might happen?'},
    {title: 'Have you ever lost or damaged a thing that wasn\'t yours?'},
    {title: 'Do you usually take the initiative in making new friends?'},
    {title: 'Would you call yourself tense or “highly-strung”?'},
    {title: 'Are you mostly quiet when you are with other people?'},
    {title: 'Do you think marriage is an outdated thing and should be terminated?'},
    {title: 'Do you like to brag a little sometimes?'},
    {title: 'Can you easily get some life into a rather dull party?'},
    {title: 'Do careful drivers get on your nerves?'},
    {title: 'Do you worry about your health?'},
    {title: 'Have you ever said anything bad or rude about someone?'},
    {title: 'Do you like telling jokes and funny stories to your friends?'},
    {title: 'Do you think most things have the same taste?'},
    {title: 'Have you ever been insolent or rude to your parents as a child?'},
    {title: 'Do you like being around other people?'},
    {title: 'Do you worry if you know that there are mistakes in your work?'},
    {title: 'Do you suffer from insomnia?'},
    {title: 'Do you always wash your hands before eating?'},
    {title: 'Do you nearly always have ba “ready answer” for every remark?'},
    {title: 'Do you arrive for meetings long before the agreed time?'},
    {title: 'Have you often felt listless and tired for no reason?'},
    {title: 'Have you ever cheated in a game?'},
    {title: 'Do you like doing things in which you have to act quickly?'},
    {title: 'Is (was) your mother a good wife?'},
    {title: 'Do you often feel life is very dull?'},
    {title: 'Have you ever used anyone?'},
    {title: 'Do you often take on more activities than you have time for?'},
    {title: 'Are there people who persistently avoid you?'},
    {title: 'Do you worry a lot about your looks?'},
    {title: 'Do you think people spend too much time saving and giving up because of their worries about the future?'},
    {title: 'Have you ever wished that you were dead?'},
    {title: 'Would you avoid paying taxes if you knew you wouldn\'t be discovered?'},
    {title: 'Can you get a party going?'},
    {title: 'Do you try not to be rude to people?'},
    {title: 'Do you worry too long after an embarrassing experience?'},
    {title: 'Have you ever been too persistent in your position?'},
    {title: 'When you travel, do you often arrive at the train station at the last minute?'},
    {title: 'Do you suffer from “nerves”?'},
    {title: 'Are your friendships quickly broken up without your fault?'},
    {title: 'Do you often feel lonely?'},
    {title: 'Do you always do what you preach?'},
    {title: 'Do you like to tease animals sometimes?'},
    {title: 'Are you easily hurt when people find fault with you or the work you do?'},
    {title: 'Have you ever been late for a meeting or work?'},
    {title: 'Do you like plenty of bustle and excitement around you?'},
    {title: 'Would you like others to be afraid of you?'},
    {title: 'Are you sometimes bubbling over with energy and sometimes very sluggish?'},
    {title: 'Do you sometimes postpone things for tomorrow what you had to do today?'},
    {title: 'Do other people think of you as being very lively?'},
    {title: 'Do people lie to you a lot?'},
    {title: 'Are you touchy about some things?'},
    {title: 'Are you always ready to admit the mistake you made?'},
    {title: 'Would you feel sorry for a trapped animal?'}
]

export const BfqQuestions: BfqOption[] = [
    {title: 'I seem to be an active and strong person.'},
    {title: 'I don\'t like to think too much about what I do.'},
    {title: 'I\'m overly sympathetic when someone talks to me about their own worries.'},
    {title: 'I don\'t worry too much about the consequences of my actions for other people.'},
    {title: 'I am always informed about events in the world.'},
    {title: 'I never lied.'},
    {title: 'I do not like activities where it is necessary to strive for exhaustion.'},
    {title: 'I tend to be very thoughtful.'},
    {title: 'It doesn\'t happen often that I feel tense.'},
    {title: 'I understand when people need my help.'},
    {title: 'It\'s not easy for me to remember long phone numbers.'},
    {title: 'I always completely agreed with everyone.'},
    {title: 'I am more inclined to take my stand than to yield.'},
    {title: 'In the face of great obstacles, it makes no sense to insist on achieving our own goals.'},
    {title: 'I am quite sensitive.'},
    {title: 'It is not necessary to be polite to everyone.'},
    {title: 'I am not too attracted to new and unexpected situations.'},
    {title: 'I always immediately dealt with every problem I encountered.'},
    {title: 'I don\'t like work environments where I have a lot of competition.'},
    {title: 'I stick to the decisions I make until the end.'},
    {title: 'I do not lose patience for something or someone easily.'},
    {title: 'I love being among people.'},
    {title: 'I\'m fascinated by new things.'},
    {title: 'I was never scared of any danger, even when it was very big.'},
    {title: 'I tend to make quick decisions.'},
    {title: 'Before I do anything, I evaluate all the possible consequences.'},
    {title: 'I do not consider myself an anxious person.'},
    {title: 'When faced with the troubles of my friends, it happens that I do not know how to react.'},
    {title: 'I have a great memory.'},
    {title: 'I was always absolutely sure of all my actions.'},
    {title: 'In my business, it is not particularly important for me to do better than others.'},
    {title: 'I do not like to live in an overly systematic and orderly manner.'},
    {title: 'I am sensitive to the criticism of others.'},
    {title: 'I will not hesitate in a situation where I need to help an unknown person.'},
    {title: 'I\'m not at all attracted to situations that are constantly changing.'},
    {title: 'I was never disobedient when something was ordered to me, even when I was a kid.'},
    {title: 'I don\'t like activities that require constant change and movement.'},
    {title: 'I do not find it necessary to push beyond capabilities, even when I have to adhere to a deadline.'},
    {title: 'I\'m able to make the most of something just to stand out.'},
    {title: 'I refrain from criticizing others, especially when they deserve it.'},
    {title: 'I maintain that there are no values ​​or traditions that are valid "forever".'},
    {title: 'When dealing with a problem, it is not important to consider many different points of view.'},
    {title: 'I usually don\'t get upset, even in situations where there are strong reasons for it.'},
    {title: 'In any situation , it is easy for me to admit that I was wrong.'},
    {title: 'When I am angry, I openly display my bad mood.'},
    {title: 'I stick to my decisions, even when it involves unexpected commitments.'},
    {title: 'I do not waste time learning things that are not closely related to my area of ​​interest.'},
    {title: 'I almost always know how to meet the needs of others.'},
    {title: 'I continue to perform the activities I started even if the initial results seem poor.'},
    {title: 'I don\'t often feel lonely and sad.'},
    {title: 'I don’t like to do multiple things at once.'},
    {title: 'I am usually polite even to people whom I dislike.'},
    {title: 'I am often completely involved in my own obligations and activities.'},
    {title: 'When something blocks my plans, I do not insist on their realization, but move  on to others.'},
    {title: 'I do not mind “too demanding" television programs.'},
    {title: 'I\'m a person who is always looking for new experiences.'},
    {title: 'I really am bothered by the mess.'},
    {title: 'I usually do not respond impulsively.'},
    {title: 'I always find reasons that support my views and convince others of their value.'},
    {title: 'I like to be informed even about matters  in which I am not proficient.'},
    {title: 'I do not attach much importance to showing my own abilities.'},
    {title: 'I am prone to frequent mood swings.'},
    {title: 'Sometimes I get angry over unimportant matters.'},
    {title: 'I don\'t borrow my things easily, even to people I know well.'},
    {title: 'I do not like to be in a large (numerous) group .'},
    {title: 'I don\'t usually organize my life to the smallest detail.'},
    {title: 'I have never been interested in the lifestyle and traditions of other people.'},
    {title: 'I do not hesitate to say what I think.'},
    {title: 'It often happens to me that I get upset.'},
    {title: 'It generally makes no sense to be sensitive to other people\'s difficulties.'},
    {title: 'At meetings, I don\'t worry too much about attracting the attention of others.'},
    {title: 'I find that any problem can be solved in many different ways.'},
    {title: 'If I feel I’m right, I try to persuade others even if it takes time and energy.'},
    {title: 'I\'m usually not inclined to trust other people.'},
    {title: 'I have a hard time giving up the activity I started.'},
    {title: 'Usually, I do not lose my patience.'},
    {title: 'I don’t devote much time to reading.'},
    {title: 'When I travel, I don\'t usually talk to my companions.'},
    {title: 'Sometimes I\'m so conscientious that it seems boring.'},
    {title: 'I always acted completely impartially.'},
    {title: 'It is not difficult for me to control my feelings.'},
    {title: 'I\'ve never been a perfectionist.'},
    {title: 'In different circumstances, it has happened to me to act impulsively.'},
    {title: 'It never occurred to me that I raised my voice or had a conflict with someone.'},
    {title: 'It is useless to try too hard because perfection is never achieved.'},
    {title: 'I highly respect the views of my colleagues.'},
    {title: 'Science has always fascinated me.'},
    {title: 'I readily confide in others.'},
    {title: 'It usually doesn\'t occur to me to overreact, even when I feel strong emotions.'},
    {title: 'I don\'t think knowing history serves anything.'},
    {title: 'I usually do not respond to provocations.'},
    {title: 'There is nothing I have done that I could have done better.'},
    {title: 'I find that there is something good in every person.'},
    {title: 'It\'s easy for me to talk to people I don\'t know.'},
    {title: 'I do not believe it is an option to convince others if they think differently than we do.'},
    {title: 'If I fail at some task, I try until I succeed.'},
    {title: 'I have always been fascinated by cultures different from mine.'},
    {title: 'I am often nervous.'},
    {title: 'I\'m not a talkative person.'},
    {title: 'It is not very productive to adapt to the needs of my colleagues if this slows my pace.'},
    {title: 'I immediately understand everything I read.'},
    {title: 'I\'m always sure of myself.'},
    {title: 'I do not understand what encourages people to disobey the rules.'},
    {title: 'I get very annoyed when I\'m distracted while doing something that interests me.'},
    {title: 'I really like to watch cultural and/or scientific and informative programs.'},
    {title: 'Before I send in a job, I spend a lot of time checking how I did it.'},
    {title: 'If things don\'t go in the desired direction, I don\'t insist too much.'},
    {title: 'If necessary, I do not hesitate to tell others to mind their business .'},
    {title: 'If someone dislikes an activity of mine, I will definitely abandon it.'},
    {title: 'When I finish a job, I do not reevaluate  every single detail.'},
    {title: 'I am convinced that better results are achieved through collaboration rather than competition'},
    {title: 'I prefer reading than playing sports.'},
    {title: 'I have never criticized anyone.'},
    {title: 'I am very enthusiastic about each of my experiences.'},
    {title: 'I am only satisfied when I see the result of what I have planned.'},
    {title: 'When I am criticized by others, I cannot refrain from asking for reasons.'},
    {title: 'In life, nothing is accomplished without competition.'},
    {title: 'I always try to see everything from a different point of view.'},
    {title: 'Even in the most difficult situations, I do not lose control.'},
    {title: 'Sometimes, even minor difficulties bother me.'},
    {title: 'I generally do not behave openly with strangers.'},
    {title: 'I am not prone to sudden mood swings'},
    {title: 'I do not like risky activitie'},
    {title: 'I have never been too interested in scientific and/or philosophical content.'},
    {title: 'When I start doing something, I never know if I will finish it'},
    {title: 'I generally trust others and their intentions.'},
    {title: 'I always felt sympathy for every person I met.'},
    {title: 'Some people should not be too tolerant.'},
    {title: 'Generally, I pay attention to the smallest details.'},
    {title: 'Personal abilities are not best achieved by working in a group.'},
    {title: 'I\'m not looking for new solutions if a solution already exists.'},
    {title: 'I do not believe that it is useful to waste time checking what has already been done.'},
]

export const BFQResultMapping: object = {
    1: [
        1,
        7,
        25,
        37,
        51,
        53,
        68,
        78,
        94,
        99,
        114,
        121],
    2: [
        13,
        19,
        31,
        39,
        59,
        61,
        71,
        73,
        95,
        102,
        117,
        123
    ],
    3: [
        4,
        10,
        28,
        34,
        48,
        64,
        70,
        86,
        100,
        109,
        111,
        130
    ],
    4: [
        16,
        22,
        40,
        44,
        52,
        65,
        74,
        88,
        93,
        108,
        126,
        128
    ],
    5: [
        2,
        8,
        26,
        32,
        57,
        66,
        79,
        82,
        106,
        110,
        129,
        132
    ],
    6: [
        14,
        20,
        38,
        46,
        49,
        54,
        75,
        85,
        96,
        107,
        115,
        125
    ],
    7: [
        3,
        9,
        27,
        33,
        50,
        62,
        69,
        81,
        89,
        98,
        120,
        122
    ],
    8: [
        15,
        21,
        43,
        45,
        58,
        63,
        76,
        83,
        91,
        104,
        116,
        119
    ],
    9: [
        5,
        11,
        29,
        42,
        55,
        60,
        77,
        87,
        90,
        105,
        112,
        124
    ],
    10: [
        17,
        23,
        35,
        41,
        47,
        56,
        67,
        72,
        97,
        103,
        118,
        131
    ],
    11: [
        6,
        12,
        18,
        24,
        30,
        36,
        80,
        84,
        92,
        101,
        113,
        127
    ]
}
