<div class="kt-widget14">
    <div class="kt-widget14__header kt-margin-b-10">
        <h3 *ngIf="title" class="kt-widget14__title">
            {{title}}
        </h3>

        <span *ngIf="description" class="kt-widget14__desc">
            {{description}}
        </span>
    </div>

    <!-- Insert body here -->
    <div class="kt-widget14__chart">
        <ng-content select="[body]"></ng-content>
    </div>
</div>