import {Component, HostBinding, Input} from '@angular/core';

/* tslint:disable:component-selector */
@Component({
    selector: '[app-home-subheader]',
    templateUrl: './home-subheader.component.html',
    styleUrls: ['./home-subheader.component.css']
})
export class HomeSubheaderComponent {
    @Input() icon: string;
    @Input() header: string;
    @HostBinding('class') styleClass = 'kt-subheader kt-grid__item';
}
