import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-test-base-error',
    templateUrl: './test-base-error.component.html',
    styleUrls: ['./test-base-error.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestBaseErrorComponent {
    @Input() title: string = null;
    @Input() error: string = null;
    @Input() errorData: any = null;
}
