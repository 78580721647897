import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Candidate} from '../../../shared/models/candidate';
import {CommonUtils} from '../../../shared/utils/common-utils';
import {TestBundle} from '../../../shared/models/test-bundle';
import {TestBundleStatus} from '../../../shared/enums/test-bundle-status';

@Component({
    selector: 'app-candidate-bundle-menu',
    templateUrl: './candidate-bundle-menu.component.html',
    styleUrls: ['./candidate-bundle-menu.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateBundleMenuComponent {
    @Input() candidate: Candidate = null;
    @Input() bundle = 0;

    @Output() bundleSelected = new EventEmitter<number>();

    get testBundles(): TestBundle[] {
        if (this.candidate && CommonUtils.isNotEmpty(this.candidate.bundles)) {
            return this.candidate.bundles;
        }
        return [];
    }

    get showList(): boolean {
        return this.candidate && CommonUtils.isNotEmpty(this.candidate.bundles);
    }

    getClassName(testBundle: TestBundle, index: number): string {
        if (testBundle) {
            const marker = index === this.bundle ? 'selected' : '';
            switch (testBundle.status) {
                case TestBundleStatus.CREATED:
                case TestBundleStatus.SENDING:
                    return `kt-sticky-toolbar__item kt-sticky-toolbar__item--dark ${marker}`;
                case TestBundleStatus.SCHEDULED:
                case TestBundleStatus.SENT:
                    return `kt-sticky-toolbar__item kt-sticky-toolbar__item--brand ${marker}`;
                case TestBundleStatus.STARTED:
                    return `kt-sticky-toolbar__item kt-sticky-toolbar__item--primary ${marker}`;
                case TestBundleStatus.FINISHED:
                    return `kt-sticky-toolbar__item kt-sticky-toolbar__item--warning ${marker}`;
                case TestBundleStatus.ERROR:
                    return `kt-sticky-toolbar__item kt-sticky-toolbar__item--error ${marker}`;
            }
        }
        return '';
    }

    selectBundle(index: number) {
        this.bundleSelected.emit(index);
    }
}
