import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-candidate-test-cti-result',
    templateUrl: './candidate-test-cti-result.component.html',
    styleUrls: ['./candidate-test-cti-result.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateTestCtiResultComponent {
    @Input() counts: number;
    @Input() result: number;
}
