import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {TestType, TestTypeItems} from '../../../shared/enums/test-type';

@Component({
    selector: 'app-test-choice',
    templateUrl: './test-choice.component.html',
    styleUrls: ['./test-choice.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestChoiceComponent {
    @Input() selected: TestType[] = [];
    @Output() selectedChange: EventEmitter<TestType[]> = new EventEmitter();

    get tests(): { key: TestType, label: string }[] {
        return TestTypeItems;
    }

    isSelected(test: TestType): boolean {
        return this.selected.findIndex(item => item === test) > -1;
    }

    handleSelectionChange(event: MatCheckboxChange, test: TestType): void {
        if (event && test) {
            if (event.checked) {
                this.selectedChange.emit([...this.selected, test]);
            } else {
                this.selectedChange.emit(this.selected.filter(item => item !== test));
            }
        }
    }
}
