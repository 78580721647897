import {Candidate} from '../models/candidate';
import {MappingUtils} from '../utils/mapping-utils';

export class ReportHelper {
    public static mapCandidateData(candidate: Candidate): any {
        return [
            ['Code', candidate.code],
            ['Location', candidate.hub ? candidate.hub.name : ''],
            ['Status', MappingUtils.mapEnumValue(candidate.status, 'CandidateStatus')],
            ['Email', candidate.email],
            ['Gender', MappingUtils.mapEnumValue(candidate.gender, 'Gender')],
            ['Date of Birth', MappingUtils.mapDateValue(candidate.birth_date)],
        ];
    }
}
