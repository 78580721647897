<!-- Subheader -->
<div [header]="'Settings'" app-home-subheader>
    <div class="kt-subheader__breadcrumbs" header>
        <a class="kt-subheader__breadcrumbs-home">
            <i class="flaticon2-shelter"></i>
        </a>
        <span class="kt-subheader__breadcrumbs-separator"></span>
        <a class="kt-subheader__breadcrumbs-link">
            Preview Settings
        </a>
    </div>
</div>

<!-- Section -->
<div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <div class="row">
        <div class="col">
            <div class="alert alert-dark alert-elevate" role="alert">
                <div class="alert-icon">
                    <i class="flaticon-confetti kt-font-brand"></i>
                </div>

                <div class="alert-text">
                    Add something here
                </div>
            </div>
        </div>
    </div>
</div>