import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardStatistic, DashboardStatisticChunk} from '../../../shared/models/dashboard-statistic';
import {CommonUtils} from '../../../shared/utils/common-utils';
import {MappingUtils} from '../../../shared/utils/mapping-utils';
import {ColorUtils} from '../../../shared/utils/color-utils';

@Component({
    selector: 'app-dashboard-statistic',
    templateUrl: './dashboard-statistic.component.html',
    styleUrls: ['./dashboard-statistic.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardStatisticComponent {
    @Input() data: DashboardStatistic = null;

    public filter = 'last_month';

    get selectedChunk(): DashboardStatisticChunk {
        return this.data ? this.data[this.filter] : null;
    }

    get selectedHubData(): { data: number[], labels: string[], colors: string[] } {
        const hubData = this.selectedChunk ? this.selectedChunk.hub : [];
        return CommonUtils.isNotEmpty(hubData) ? {
            labels: hubData.map(hub => String(hub[0])),
            data: hubData.map(hub => hub[1]),
            colors: ColorUtils.interpolateColorsDefault(hubData.length)
        } : null;
    }

    get selectedStatusData(): { data: number[], labels: string[], colors: string[] } {
        const statusData = this.selectedChunk ? this.selectedChunk.status : [];
        return CommonUtils.isNotEmpty(statusData) ? {
            labels: statusData.map(status => MappingUtils.mapEnumValue(status[0], 'CandidateStatus')),
            data: statusData.map(status => status[1]),
            colors: ColorUtils.interpolateColorsDefault(statusData.length)
        } : null;
    }

    selectPeriod(marker: string): void {
        this.filter = marker;
    }
}