import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {EpqQuestions} from '../../../shared/constants/constants';
import {EpqOption} from '../../../shared/models/epq-option';
import {TestsService} from '../../../shared/services/tests.service';
import {ActivatedRoute} from '@angular/router';
import {StorageHelper} from '../../../shared/helpers/storage-helper';
import {TestBaseQuestions} from '../../test-base/test-base-questions';
import {Store} from '@ngrx/store';
import {State} from '../../../shared/store';

@Component({
    selector: 'app-test-epq-questions',
    templateUrl: './test-epq-questions.component.html',
    styleUrls: ['./test-epq-questions.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestEpqQuestionsComponent extends TestBaseQuestions<string> {
    constructor(private testsService: TestsService,
                private route: ActivatedRoute,
                store: Store<State>) {
        super(store);
    }

    get currentQuestion(): EpqOption {
        return EpqQuestions[this.currentIndex];
    }

    get code(): string {
        return this.route.snapshot.params.code;
    }

    get questionsLength(): number {
        return EpqQuestions.length;
    }

    save(): void {
        this.testsService
            .saveEpqTest(this.code, this.answers)
            .subscribe(x => {
                StorageHelper.clearTestData(this.code);
                this.nextStep();
            }, error => {
                this.handleTestError(error);
            });
    }
}
