import {Component, HostBinding, ViewEncapsulation} from '@angular/core';

@Component({
    selector: '[app-home]',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent {
    @HostBinding('class') styleClass = 'kt-grid kt-grid--hor kt-grid--root';
}
