import * as statistics from '../actions/statistics.actions';
import {DashboardStatistic} from '../../models/dashboard-statistic';

export interface State {
    statistics: DashboardStatistic;
}

const initialState: State = {
    statistics: null,
};

export function reducer(state = initialState, action: statistics.Actions): State {
    switch (action.type) {
        case statistics.StatisticsActionTypes.Load:
            return Object.assign({}, state, {statistics: action.payload});
        default:
            return state;
    }
}

export const getStatistics = (state: State) => state.statistics;
