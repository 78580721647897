import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
    transform(value: Date | moment.Moment): string {
        if (value) {
            const localDate: any = moment.utc(value).local();
            const localNow = moment();

            /* Format date */
            if (localNow.isSame(localDate, 'day')) {
                return localDate.format('h:mm a');
            } else {
                return localDate.format('DD.MM.YYYY');
            }
        }
        return null;
    }
}
