<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div *ngIf="!isFinished" class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        EPI Test
                    </h3>
                </div>

                <div class="kt-portlet__head-toolbar">
                    <div class="btn-group">
                        <div class="kt-infobox__badge">Step {{currentStep}} of 62</div>
                    </div>
                </div>
            </div>

            <!--begin::Form-->
            <form class="kt-form">
                <div class="kt-portlet__body">
                    <!-- Tests -->
                    <div class="form-group form-group-last">
                        <label>Select one that describes you best: &nbsp;</label>

                        <div class="row">
                            <div class="col-lg-6">
                                <app-test-base-option
                                        (selectOption)="selectAnswer($event)"
                                        [label]="currentQuestion[0].title"
                                        [value]="currentQuestion[0]">
                                </app-test-base-option>
                            </div>

                            <div class="col-lg-6">
                                <app-test-base-option
                                        (selectOption)="selectAnswer($event)"
                                        [label]="currentQuestion[1].title"
                                        [value]="currentQuestion[1]">
                                </app-test-base-option>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="kt-portlet__foot kt-portlet__foot--sm">
                <div class="kt-form__actions kt-form__actions--right">
                    <div class="row">
                        <div class="col kt-align-left">
                            <a (click)="previous()"
                               [class.disabled]="!isPreviousEnabled"
                               class="btn btn-clean">
                                <i class="la la-arrow-left"></i>
                                <span class="kt-hidden-mobile">Previous Question</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="kt-portlet no-select">
            <div class="kt-portlet__body">
                <!-- Definitions -->
                <div class="accordion">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title kt-label-font-color-3">
                                Definitions
                            </div>
                        </div>

                        <div class="collapse show">
                            <div class="card-body">
                                <app-test-epi-definitions></app-test-epi-definitions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
