<div class="{{mainClass}}">
    <div class="kt-portlet__head kt-portlet__head--lg {{headerClass}}">
        <div class="kt-portlet__head-label">
            <!-- Icon -->
            <span *ngIf="icon" class="kt-portlet__head-icon">
                <i [class]="icon"></i>
            </span>

            <!-- Title -->
            <h3 class="kt-portlet__head-title">
                {{header}}
            </h3>
        </div>

        <!-- Toolbar -->
        <div *ngIf="showToolbar" class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
                <ng-content select="[toolbar]"></ng-content>
            </div>
        </div>
    </div>

    <!-- Body -->
    <div [class]="bodyClass">
        <ng-content select="[body]"></ng-content>
    </div>
</div>