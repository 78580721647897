import * as loader from '../actions/loader.actions';

export interface State {
    loading: boolean;
}

export const initialState: State = {
    loading: false
};

export function reducer(state = initialState, action: loader.Actions): State {
    switch (action.type) {
        case loader.LoaderActionTypes.Start:
            return {
                loading: true
            };
        case loader.LoaderActionTypes.Stop:
            return {
                loading: false
            };
        default:
            return state;
    }
}

export const getLoading = (state: State) => state.loading;
