import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Candidate} from '../../../shared/models/candidate';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {
    getSelectedCandidate,
    getSelectedCandidateBundle,
    getShowSelectedCandidateBundle,
    State
} from '../../../shared/store';
import {Observable} from 'rxjs';
import {SelectCandidateAction, SelectCandidateBundleAction} from '../../../shared/store/actions/candidates.actions';
import {TestType} from 'src/app/shared/enums/test-type';

@Component({
    selector: 'app-candidate',
    templateUrl: './candidate.component.html',
    styleUrls: ['./candidate.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateComponent implements OnInit {
    public candidate: Observable<Candidate>;
    public selectedBundle: Observable<number>;
    public showBundleMenu: Observable<boolean>;

    public currentTestType: TestType = TestType.EPI;
    public testTypes = TestType;

    constructor(private route: ActivatedRoute,
                private store: Store<State>) {
        this.candidate = store.select(getSelectedCandidate);
        this.selectedBundle = store.select(getSelectedCandidateBundle);
        this.showBundleMenu = store.select(getShowSelectedCandidateBundle);
    }

    ngOnInit() {
        this.route.data
            .subscribe((data: { candidate: Candidate }) => {
                this.store.dispatch(new SelectCandidateAction(data.candidate));
            });
    }

    selectTestType(type: TestType): void {
        this.currentTestType = type;
    }

    selectTestBundle(index: number): void {
        this.store.dispatch(new SelectCandidateBundleAction(index));
    }
}
