import jwt_decode from 'jwt-decode';
import {LogUtils} from '../utils/log-utils';
import {TestState} from '../models/test-state';

export class StorageHelper {
    public static getToken(): string {
        return localStorage.getItem('token');
    }

    public static setToken(token: string): void {
        localStorage.setItem('token', token);
    }

    public static getIsTokenValid(): boolean {
        const token = StorageHelper.getToken();
        if (token) {
            try {
                const decoded: any = jwt_decode(token);
                return decoded.exp * 1000 > new Date().getTime();
            } catch (ex) {
                LogUtils.error(ex);
                return false;
            }
        }
        return false;
    }

    public static getUser(): any {
        return JSON.parse(localStorage.getItem('user'));
    }

    public static setUser(user: any): void {
        return localStorage.setItem('user', JSON.stringify(user));
    }

    public static getFormattedToken(): string {
        const token = StorageHelper.getToken();
        return token ? `Bearer ${token}` : null;
    }

    public static getFormattedName(): string {
        const user = StorageHelper.getUser();
        return user ? user.name : '';
    }

    public static getAccessCode(url: string): string {
        return localStorage.getItem(url);
    }

    public static setAccessCode(url: string, access: string): void {
        localStorage.setItem(url, access);
    }

    public static setCurrentTestState(state: TestState) {
        const key = StorageHelper.buildTestKey(state.code, state.subtest);
        localStorage.setItem(key, JSON.stringify(state));
    }

    public static getCurrentTestState(code: string, subtest: number): TestState {
        const key = StorageHelper.buildTestKey(code, subtest);
        return JSON.parse(localStorage.getItem(key));
    }

    public static createOrUpdateCurrentTestState(code: string, subtest: number, data: any): void {
        const testState = StorageHelper.getCurrentTestState(code, subtest);
        if (testState) {
            const updated = Object.assign({}, testState, data);
            StorageHelper.setCurrentTestState(updated);
        } else {
            const create = Object.assign({}, {code, subtest, answers: []}, data);
            StorageHelper.setCurrentTestState(create);
        }
    }

    public static clearTestData(code: string) {
        for (let subtest = 0; subtest < 5; subtest++) {
            localStorage.removeItem(StorageHelper.buildTestKey(code, subtest));
        }
    }

    public static clear() {
        /* Only clear user data */
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }

    private static buildTestKey(code: string, subtest: number = 0): string {
        return `${code}[${subtest}]`;
    }
}
