import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {StorageHelper} from '../helpers/storage-helper';
import {UsersService} from '../services/users.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private usersService: UsersService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!StorageHelper.getIsTokenValid()) {
            this.usersService.logout();
            return false;
        }
        return true;
    }
}
