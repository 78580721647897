import {ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation} from '@angular/core';

/* tslint:disable:component-selector */
@Component({
    selector: '[app-login]',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {
    @HostBinding('class') styleClass = 'kt-grid kt-grid--ver kt-grid--root';
}
