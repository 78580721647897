import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {EmotionQuestions} from 'src/app/shared/constants/constants';
import {EpiOption} from '../../../shared/models/epi-option';

@Component({
    selector: 'app-candidate-test-epi-answers',
    templateUrl: './candidate-test-epi-answers.component.html',
    styleUrls: ['./candidate-test-epi-answers.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateTestEpiAnswersComponent {
    static readonly DEFAULT = '../../../../assets/media/icons/circle-transparent.svg';
    static readonly SELECTED = '../../../../assets/media/icons/circle.svg';

    @Input() actions: { answer: string, increments: [] }[] = [];
    @Input() counts = {};
    @Input() result = {};

    get questions(): EpiOption[][] {
        return EmotionQuestions;
    }

    getIcon(increments, category): string {
        return increments && increments.includes(category) ?
            CandidateTestEpiAnswersComponent.SELECTED :
            CandidateTestEpiAnswersComponent.DEFAULT;
    }
}
