<mat-nav-list>
    <!-- Header -->
    <h3 matSubheader>Quick Actions</h3>

    <a (click)="createCandidate()" mat-list-item>
        <i class="kt-menu__link-icon flaticon-interface-3" mat-list-icon></i>
        <span mat-line>Create Candidate</span>
        <span mat-line>Register new candidate for questionnaires</span>
    </a>

    <a (click)="createTestBundle()" mat-list-item>
        <i class="kt-menu__link-icon flaticon-trophy" mat-list-icon></i>
        <span mat-line>Create Test Bundle</span>
        <span mat-line>Create new test bundle for candidate</span>
    </a>

    <a (click)="createUser()" mat-list-item>
        <i class="m-menu__link-icon flaticon-profile-1" mat-list-icon></i>
        <span mat-line>Create User</span>
        <span mat-line>Register new system user</span>
    </a>
</mat-nav-list>