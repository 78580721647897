import * as hubs from '../actions/hubs.actions';
import {Hub} from '../../models/hub';

export interface State {
    hubs: Hub[];
}

const initialState: State = {
    hubs: [],
};

export function reducer(state = initialState, action: hubs.Actions): State {
    switch (action.type) {
        case hubs.HubsActionTypes.Load:
            return Object.assign({}, state, {hubs: [...action.payload]});
        default:
            return state;
    }
}

export const getHubs = (state: State) => state.hubs;
