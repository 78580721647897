<!-- Title -->
<div class="candidate-modal-title" mat-dialog-title>
    <h5>Schedule Email</h5>
</div>

<!-- Content -->
<mat-dialog-content class="candidate-modal-content">
    <div [formGroup]="form" class="kt-form kt-form--label-align-right">
        <div class="form-group form-group-last">
            <div class="alert alert-secondary" role="alert">
                <div class="alert-icon">
                    <i class="flaticon-warning kt-font-brand"></i>
                </div>
                <div class="alert-text">
                    A test bundle with a uniquely generated URL code will be sent to the address below at a specified
                    date and time.
                </div>
            </div>
        </div>

        <!-- Email -->
        <div class="form-group kt-form__group">
            <input class="form-control kt-input "
                   formControlName="email"
                   maxlength="255"
                   placeholder="Enter Email"
                   type="email">

            <app-validation-error [control]="form.controls.email"></app-validation-error>
        </div>

        <!-- When to send email -->
        <div class="row">
            <div class="col-sm-6">
                <!-- Date -->
                <div class="form-group m-form__group">
                    <label class="m--font-boldest">When to Send</label>
                    <div class="input-group date">
                        <input [matDatepicker]="dateDatepicker"
                               [max]="maxDate"
                               [min]="minDate"
                               class="form-control m-input m-input--square"
                               formControlName="when_to_send">

                        <div class="input-group-append">
                    <span (click)="dateDatepicker.open()" class="input-group-text">
                        <i class="la la-calendar"></i>
                    </span>
                        </div>

                        <!-- Picker -->
                        <mat-datepicker #dateDatepicker></mat-datepicker>
                    </div>
                    <app-validation-error [control]="form.controls.when_to_send"></app-validation-error>
                </div>
            </div>

            <div class="col-sm-6">
                <!-- Time -->
                <div class="form-group kt-form__group">
                    <label class="m--font-boldest">&nbsp;</label>
                    <input class="form-control kt-input "
                           formControlName="when_to_send_time"
                           placeholder="Enter Email"
                           type="time">

                    <app-validation-error [control]="form.controls.when_to_send_time"></app-validation-error>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<!-- Footer -->
<mat-dialog-actions align="end">
    <button class="btn btn-secondary btn-wide" mat-dialog-close>Cancel</button>
    <button (click)="save()" class="btn btn-brand btn-wide">Schedule</button>
</mat-dialog-actions>