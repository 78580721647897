<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        EPI Test Instructions
                    </h3>
                </div>
            </div>

            <form class="kt-form">
                <div class="kt-portlet__body">
                    <div class="kt-section kt-margin-0">
                        <div class="kt-section__content kt-section__content__text kt-details-text">
                            <p>
                                In this questionnaire, there are word pairs that describe people. In each pair, circle
                                the one that describes you the best.
                                For example, if the description <b>Adventurous</b> suits you better than Affectionate,
                                then you have to mark it in the following way:
                            </p>
                        </div>

                        <div class="kt-section__content ">
                            <div class="row">
                                <div class="col-lg-6">
                                    <app-test-base-option [highlightHover]="false"
                                                          [label]="firstExample[0].title"
                                                          [value]="firstExample[0]">
                                    </app-test-base-option>
                                </div>
                                <div class="col-lg-6">
                                    <app-test-base-option [highlight]="true"
                                                          [label]="firstExample[1].title"
                                                          [value]="firstExample[1]">
                                    </app-test-base-option>
                                </div>
                            </div>
                        </div>

                        <div class="kt-section__content kt-section__content__text kt-details-text">
                            <br>
                            <p>
                                In the same way, if the description <b>Cautious</b> suits you better than Affectionate,
                                then mark it in the following way:
                            </p>
                        </div>

                        <div class="kt-section__content">
                            <div class="row">
                                <div class="col-lg-6">
                                    <app-test-base-option [highlight]="true"
                                                          [label]="secondExample[0].title"
                                                          [value]="secondExample[0]">
                                    </app-test-base-option>
                                </div>
                                <div class="col-lg-6">
                                    <app-test-base-option [highlightHover]="false"
                                                          [label]="secondExample[1].title"
                                                          [value]="secondExample[1]">
                                    </app-test-base-option>
                                </div>
                            </div>
                        </div>

                        <div class="kt-section__content kt-section__content__text kt-details-text">
                            <br>
                            <p>
                                You can read definitions below to have more clarity about the individual
                                adjectives/words. However, don't bother remembering the meanings of all the adjectives;
                                instead, feel free to look at individual adjectives in the form that you personally
                                perceive them. Sometimes, it can be really difficult to decide which word describes you
                                the best but try to choose one, even if there is just a slight difference.
                            </p>
                            <p>
                                Once you start the test, you can only go back once to a previous couple of words. We
                                suggest not going back if you don't have to, rather focusing as much as possible on a
                                couple of adjectives right in front of you.
                            </p>
                            <p>
                                <b>Time is not limited</b>, however, it should take approximately 10 minutes to
                                complete.
                            </p>
                        </div>

                        <div class="kt-separator kt-separator--border-dashed kt-separator--portlet-fit"></div>

                        <div class="kt-section__content kt-section__content__text">
                            <app-test-epi-definitions [choiceClass]="'col-6 kt-margin-0'"></app-test-epi-definitions>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__foot">
                    <div class="kt-form__actions kt-form__actions--right">
                        <div class="row">
                            <div class="col kt-align-left">
                                <button (click)="previousStep()"
                                        class="btn btn-label-danger btn-wide"
                                        type="button">
                                    Back
                                </button>
                            </div>
                            <div class="col kt-align-right">
                                <button (click)="nextStep()"
                                        class="btn btn-label-brand btn-wide"
                                        type="button">
                                    Start Test
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
