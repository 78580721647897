import {Injectable} from '@angular/core';
import {Endpoint} from '../constants/constants';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {State} from '../store';
import {NotificationsService} from './notifications.service';
import {UpdateTestBundleAction} from '../store/actions/test-bundles.actions';
import {TestBundle} from '../models/test-bundle';

@Injectable({
    providedIn: 'root'
})
export class EmailsService {
    constructor(private http: HttpClient,
                private store: Store<State>,
                private notificationsService: NotificationsService) {
    }

    notifyCandidate(bundleId: number, email: string, whenToSend: string): void {
        this.http
            .post<TestBundle>(Endpoint.EMAILS.concat(bundleId.toString()), {email, whenToSend})
            .subscribe((data: TestBundle) => {
                this.store.dispatch(new UpdateTestBundleAction(data));
                this.notifyUser(whenToSend);
            });
    }

    notifyUser(whenToSend: string): void {
        const msg = whenToSend ? 'An email has been scheduled' : 'An email has been sent to the candidate'
        this.notificationsService.success('Success', msg);
    }
}
