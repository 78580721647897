<!-- Title -->
<div class="confirm-modal-title" mat-dialog-title>
    <h5>{{data.title}}</h5>
</div>

<!-- Content -->
<mat-dialog-content class="confirm-modal-content">
    <span>{{data.message}}</span>
</mat-dialog-content>

<!-- Footer -->
<mat-dialog-actions align="end">
    <button class="btn btn-secondary btn-wide" mat-dialog-close>No</button>
    <button [mat-dialog-close]="'yes'" class="btn btn-brand btn-wide">Yes</button>
</mat-dialog-actions>