import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import Chart, {ChartOptions} from 'chart.js/auto';
import {ColorUtils} from '../../../shared/utils/color-utils';
import {BfqResult} from '../../../shared/models/bfq-result';

@Component({
    selector: 'app-candidate-test-bfq-diagram',
    templateUrl: './candidate-test-bfq-diagram.component.html',
    styleUrls: ['./candidate-test-bfq-diagram.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateTestBfqDiagramComponent implements OnChanges {
    public bfqBarChart: Chart = null;
    public bfqSubBarChart: Chart = null;

    @Input() result: BfqResult = null;

    @ViewChild('bfqBarChart', {static: true}) chart: ElementRef;
    @ViewChild('bfqSubBarChart', {static: true}) chartSub: ElementRef;

    drawDimensions(result: BfqResult): void {
        const ctx = this.chart.nativeElement.getContext('2d');
        const mappedData = [
            result.E_normed,
            result.U_normed,
            result.S_normed,
            result.ES_normed,
            result.MO_normed,
            result.L_normed
        ];
        if (this.bfqBarChart) {
            this.bfqBarChart.data.datasets[0].data = mappedData;
            this.bfqBarChart.update();
        } else {
            this.bfqBarChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['E', 'U', 'S', 'ES', 'MO', 'L',],
                    datasets: [
                        {
                            backgroundColor: ColorUtils.interpolateColorsDefault(6),
                            data: mappedData
                        }
                    ]
                },
                options: this.createOptions('Core Values')
            });
        }
    }

    drawSubdimensions(result: BfqResult): void {
        const ctx = this.chartSub.nativeElement.getContext('2d');
        const mappedData = [
            result.DI_normed,
            result.DO_normed,
            result.KP_normed,
            result.SR_normed,
            result.TM_normed,
            result.US_normed,
            result.EK_normed,
            result.KI_normed,
            result.OK_normed,
            result.OI_normed
        ];

        if (this.bfqSubBarChart) {
            this.bfqSubBarChart.data.datasets[0].data = mappedData;
            this.bfqSubBarChart.update();
        } else {
            this.bfqSubBarChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['DI', 'DO', 'Kp', 'Sr', 'Tm', 'Us', 'Ek', 'Ki', 'Ok', 'Oi'],
                    datasets: [
                        {
                            backgroundColor: ColorUtils.interpolateColorsDefault(12),
                            data: mappedData
                        }
                    ]
                },
                options: this.createOptions('Subdimensions')
            });
        }
    }

    createOptions(title: string): ChartOptions {
        return {
            indexAxis: 'y',
            responsive: true,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: title
                },
            },
            scales: {
                x: {
                    display: true,
                    position: 'top',
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.result && changes.result.currentValue) {
            this.drawDimensions(changes.result.currentValue);
            this.drawSubdimensions(changes.result.currentValue);
        }
    }
}
