import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {TestBundle} from '../../../shared/models/test-bundle';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {getTestsSectionBundle, getTestsSectionError, getTestsSectionSection, State} from '../../../shared/store';
import {TestBundleData} from '../../../shared/models/test-bundle-data';
import {LoadTestsSectionAction} from '../../../shared/store/actions/tests.actions';

@Component({
    selector: 'app-tests-container',
    templateUrl: './tests-container.component.html',
    styleUrls: ['./tests-container.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestsContainerComponent implements OnInit {
    public bundle: Observable<TestBundle>;
    public error: Observable<string>;
    public section: Observable<string>;

    constructor(private route: ActivatedRoute,
                private store: Store<State>) {
        this.bundle = store.select(getTestsSectionBundle);
        this.error = store.select(getTestsSectionError);
        this.section = store.select(getTestsSectionSection);
    }

    ngOnInit(): void {
        this.route.data
            .subscribe((data: { data: TestBundleData }) => {
                this.store.dispatch(new LoadTestsSectionAction(data.data));
            });
    }
}
