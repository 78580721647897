import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageHelper} from '../helpers/storage-helper';
import {Endpoint} from '../constants/constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public static IGNORE_URLS = [Endpoint.LOGIN];
    public static MATCH = '/api/';

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //noinspection TypeScriptUnresolvedFunction
        if (req.url.startsWith(AuthInterceptor.MATCH)
            && !AuthInterceptor.IGNORE_URLS.includes(req.url)
            && StorageHelper.getToken()) {
            const authReq = req.clone({
                headers: req.headers.set('Authorization', StorageHelper.getFormattedToken())
            });
            return next.handle(authReq);
        }
        return next.handle(req);
    }
}
