import {ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {FormHelper} from '../../../shared/helpers/form-helper';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TestBundlesService} from '../../../shared/services/test-bundles.service';
import {ActivatedRoute} from '@angular/router';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Store} from '@ngrx/store';
import {State} from '../../../shared/store';
import {StorageHelper} from '../../../shared/helpers/storage-helper';
import {RecaptchaModule} from 'ng-recaptcha';
import {NotificationsService} from '../../../shared/services/notifications.service';
import {LoadTestsSectionAction} from '../../../shared/store/actions/tests.actions';
import {ErrorHelper} from '../../../shared/helpers/error-helper';

@Component({
    selector: 'app-tests-validate',
    templateUrl: './tests-validate.component.html',
    styleUrls: ['./tests-validate.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TestsValidateComponent {
    public agree: boolean;
    public form: UntypedFormGroup;

    @ViewChild(RecaptchaModule, {static: false}) captcha: RecaptchaModule;

    constructor(private formBuilder: UntypedFormBuilder,
                private testBundlesService: TestBundlesService,
                private notificationsService: NotificationsService,
                private route: ActivatedRoute,
                private store: Store<State>) {
        this.form = this.formBuilder.group({
            pin: [null, Validators.required],
            captcha: [null, Validators.required],
        });
    }

    get code(): string {
        return this.route.snapshot.params.code;
    }

    get siteKey(): string {
        return environment.google.captchaSiteKey;
    }

    handleSelection(event: MatCheckboxChange): void {
        if (event) {
            this.agree = event.checked;
        }
    }

    leavePage(): void {
        window.location.assign(environment.homePage);
    }

    nextStep(): void {
        if (this.form.valid) {
            const data = this.form.getRawValue();
            this.testBundlesService
                .validateTestBundle(this.code, data.pin, data.captcha)
                .subscribe({
                    next: (bundle) => {
                        /* Set access code and emit object */
                        if (bundle && bundle.access_code) {
                            StorageHelper.setAccessCode(this.code, bundle.access_code);
                        }
                        this.store.dispatch(new LoadTestsSectionAction({
                            bundle,
                            error: null,
                            section: 'selection'
                        }));
                    },
                    error: (exception) => {
                        this.handleValidationError(exception);
                    }
                });
        } else {
            FormHelper.validateAllFormFields(this.form);
        }
    }

    handleValidationError(ex: any): void {
        /* Reset captcha */
        // TODO fix captcha reset
        // this.captcha.reset();
        this.form.reset();

        /* Show Error */
        const code = ErrorHelper.extractErrorCode(ex);
        const action = ErrorHelper.handleTestsValidationError(ex);

        if (action === 'success-page') {
            this.store.dispatch(new LoadTestsSectionAction({
                bundle: null,
                error: null,
                section: 'success'
            }));
        } else if (action === 'error-page') {
            this.store.dispatch(new LoadTestsSectionAction({
                bundle: null,
                error: code,
                section: 'error'
            }));
        } else if (action === 'notification') {
            this.notificationsService.error(
                'Error',
                ErrorHelper.getErrorMessage(code)
            );
        }
    }
}
