import * as notifications from '../actions/notifications.actions';
import {Notification} from '../../models/notification';

export interface State {
    notifications: Notification[];
}

const initialState: State = {
    notifications: [],
};

export function reducer(state = initialState, action: notifications.Actions): State {
    switch (action.type) {
        case notifications.NotificationsActionTypes.Load:
            return Object.assign({}, state, {notifications: [...action.payload]});
        case notifications.NotificationsActionTypes.Update: {
            const data = action.payload as Notification;
            const index = state.notifications.findIndex((item) => item.id === data.id);
            if (index > -1) {
                const updated = [
                    ...state.notifications.slice(0, index),
                    Object.assign({}, state.notifications[index], data),
                    ...state.notifications.slice(index + 1)
                ];
                return Object.assign({}, state, {notifications: updated});
            }
            return state;
        }
        default:
            return state;
    }
}

export const getNotifications = (state: State) => state.notifications;
