<!-- Title -->
<div class="candidate-modal-title" mat-dialog-title>
    <h5>Candidate</h5>
</div>

<!-- Content -->
<mat-dialog-content class="candidate-modal-content">
    <div [formGroup]="form" class="kt-form kt-form--label-align-right">
        <!-- Code -->
        <div class="form-group kt-form__group">
            <label class="m--font-boldest">Code</label>
            <input class="form-control kt-input"
                   formControlName="code"
                   maxlength="255"
                   placeholder="Enter Code"
                   type="text">

            <app-validation-error [control]="form.controls.code"></app-validation-error>
        </div>

        <!-- Email -->
        <div class="form-group kt-form__group">
            <label class="m--font-boldest">Email</label>
            <input class="form-control kt-input "
                   formControlName="email"
                   maxlength="255"
                   placeholder="Enter Email"
                   type="email">

            <app-validation-error [control]="form.controls.email"></app-validation-error>
        </div>

        <!-- Gender -->
        <div class="form-group kt-form__group">
            <label>Gender</label>
            <mat-select class="form-control kt-input "
                        formControlName="gender">
                <mat-option [value]="'FEMALE'">Female</mat-option>
                <mat-option [value]="'MALE'">Male</mat-option>
            </mat-select>

            <app-validation-error [control]="form.controls.gender"></app-validation-error>
        </div>

        <!-- Hub -->
        <div class="form-group kt-form__group">
            <label>Hub</label>
            <mat-select class="form-control kt-input "
                        formControlName="hub_id">
                <mat-option *ngFor="let hub of hubs | async" [value]="hub.id">
                    [{{hub.code}}] {{hub.name}}
                </mat-option>
            </mat-select>

            <app-validation-error [control]="form.controls.hub_id"></app-validation-error>
        </div>
    </div>
</mat-dialog-content>

<!-- Footer -->
<mat-dialog-actions align="end">
    <button class="btn btn-secondary btn-wide" mat-dialog-close>Cancel</button>
    <button (click)="save()" class="btn btn-brand btn-wide">Save</button>
</mat-dialog-actions>
