import * as candidates from '../actions/candidates.actions';
import {Candidate} from '../../models/candidate';
import {SortUtils} from "../../utils/sort-utils";

export interface State {
    candidates: Candidate[];
    searchCandidates: Candidate[];
    selected: Candidate;
    bundle: number;
}

const initialState: State = {
    candidates: [],
    searchCandidates: [],
    selected: null,
    bundle: 0
};

function checkSelected(state: State, candidate: Candidate): boolean {
    return state.selected && candidate && state.selected.id === candidate.id;
}

export function reducer(state = initialState, action: candidates.Actions): State {
    switch (action.type) {
        case candidates.CandidatesActionTypes.Load:
            return Object.assign({}, state, {candidates: SortUtils.sortCandidates([...action.payload])});
        case candidates.CandidatesActionTypes.Search:
            return Object.assign({}, state, {searchCandidates: SortUtils.sortCandidates([...action.payload])});
        case candidates.CandidatesActionTypes.Select:
            return Object.assign({}, state, {selected: action.payload, bundle: 0});
        case candidates.CandidatesActionTypes.Update: {
            const data = action.payload as Candidate;
            const index = state.candidates.findIndex((item) => item.id === data.id);
            if (index > -1) {
                const updated = [
                    ...state.candidates.slice(0, index),
                    Object.assign({}, state.candidates[index], data),
                    ...state.candidates.slice(index + 1)
                ];
                if (checkSelected(state, data)) {
                    const newSelected = Object.assign({}, state.selected, data);
                    return Object.assign({}, state, {candidates: updated, selected: newSelected});
                }
                return Object.assign({}, state, {candidates: updated});
            } else {
                if (checkSelected(state, data)) {
                    const newSelected = Object.assign({}, state.selected, data);
                    return Object.assign({}, state, {selected: newSelected});
                }
                return state;
            }
        }
        case candidates.CandidatesActionTypes.Delete: {
            const candidate = action.payload as Candidate;
            const filtered = state.candidates.filter(item => item.id !== candidate.id);
            return Object.assign({}, state, {candidates: filtered, selected: null, bundle: 0});
        }
        case candidates.CandidatesActionTypes.SelectBundle:
            return Object.assign({}, state, {bundle: action.payload});
        default:
            return state;
    }
}

export const getCandidates = (state: State) => state.candidates;
export const getSearchCandidates = (state: State) => state.searchCandidates;
export const getSelectedCandidate = (state: State) => state.selected;
export const getSelectedCandidateBundle = (state: State) => state.bundle;
