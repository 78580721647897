<div class="kt-details-text">
    <p>
        There are four smaller squares in a large square.
        Three have drawings, but the fourth square is empty. One of the squares below fits into the empty box.
        The correct answer here is picture 2 because it follows the logic set in the remaining three boxes the best.
    </p>

    <app-test-cti-puzzle
            [interactive]="false"
            [puzzle]="examples[0]"
            [solution]="solutions[0]"
            [subtest]="2">
    </app-test-cti-puzzle>

    <p class="kt-padding-t-20">
        <b>Example 2.</b> Which answer is correct? Please mark your answer
    </p>

    <app-test-cti-puzzle
            [puzzle]="examples[1]"
            [solution]="solutions[1]"
            [subtest]="2">
    </app-test-cti-puzzle>

    <p class="kt-padding-t-20">
        <b>Example 3.</b> Look at this one. Which answer is correct? Please mark your answer.
    </p>

    <app-test-cti-puzzle
            [puzzle]="examples[2]"
            [solution]="solutions[2]"
            [subtest]="2">
    </app-test-cti-puzzle>

    <p class="kt-padding-t-20">
        When you click “Start Test,” you will start working on the actual Test 3.
        Since you are no longer practicing, you will not be told whether you selected the correct or wrong answer.
        When you select an answer, it will turn blue, and you can change it as many times as you want.
        This test contains 14 tasks. The time allocated for this test is limited and will not be displayed while you’re
        taking the test.
        Remember, you have just a few minutes and you need to be as accurate and quick as possible.
    </p>

    <p>
        If the time expires, the test will alert you with the message:
    </p>

    <div class="alert alert-secondary" role="alert">
        <div class="alert-icon"><i class="flaticon-stopwatch"></i></div>
        <div class="alert-text">
            The time for this test has expired. Your answers are automatically submitted. Click
            <b>"Continue"</b> to go to the next test.
        </div>
    </div>

    <p>
        If you complete the test ahead of time, you can click <b>“Next”</b> to move on to the next test
    </p>
</div>
