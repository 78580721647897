import {ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation} from '@angular/core';

@Component({
    selector: '[app-tests-background]',
    templateUrl: './tests-background.component.html',
    styleUrls: ['./tests-background.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TestsBackgroundComponent {
    @HostBinding('class') styleClass = 'kt-portlet kt-sc-2 kt-portlet-test';
}
