import * as tests from '../actions/tests.actions';
import {TestBundleData} from '../../models/test-bundle-data';
import {TestData} from '../../models/test-data';

export interface State {
    testsSection: TestBundleData;
    testSection: TestData;
}

const initialState: State = {
    testsSection: null,
    testSection: null
};

export function reducer(state = initialState, action: tests.Actions): State {
    switch (action.type) {
        case tests.TestsActionTypes.Load:
            return Object.assign({}, state, {testsSection: action.payload});
        case tests.TestsActionTypes.LoadOne:
            return Object.assign({}, state, {testSection: action.payload});
        case tests.TestsActionTypes.SelectOne:
            const newSection = Object.assign({}, state.testSection, {section: action.payload});
            return Object.assign({}, state, {testSection: newSection});
        default:
            return state;
    }
}

/* Tests */
export const getTestsSectionBundle = (state: State) => state.testsSection ? state.testsSection.bundle : null;
export const getTestsSectionError = (state: State) => state.testsSection ? state.testsSection.error : null;
export const getTestsSectionSection = (state: State) => state.testsSection ? state.testsSection.section : null;

/* One Test */
export const getTestSectionTest = (state: State) => state.testSection ? state.testSection.test : null;
export const getTestSectionError = (state: State) => state.testSection ? state.testSection.error : null;
export const getTestSectionErrorData = (state: State) => state.testSection ? state.testSection.errorData : null;
export const getTestSectionSection = (state: State) => state.testSection ? state.testSection.section : null;
