import {ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../shared/store';
import {
    ToggleLeftMobileSidebarAction,
    ToggleRightMobileSidebarAction,
    ToggleTopMobileSidebarAction
} from '../../shared/store/actions/layout.actions';

@Component({
    selector: '[app-home-header-mobile]',
    templateUrl: './home-header-mobile.component.html',
    styleUrls: ['./home-header-mobile.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class HomeHeaderMobileComponent {
    @HostBinding('class') styleClass = 'kt-header-mobile  kt-header-mobile--fixed';

    constructor(private store: Store<State>) {

    }

    toggleLeftMobileSidebar(): void {
        this.store.dispatch(new ToggleLeftMobileSidebarAction());
    }

    toggleRightMobileSidebar(): void {
        this.store.dispatch(new ToggleRightMobileSidebarAction());
    }

    toggleTopMobileSidebar(): void {
        this.store.dispatch(new ToggleTopMobileSidebarAction());
    }
}
