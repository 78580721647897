import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {

    transform(value: moment.Duration): string {
        if (value) {
            return moment.utc(value.asMilliseconds()).format('HH:mm:ss');
        }
        return '';
    }

}
