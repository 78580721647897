import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {getSearchCandidates, State} from '../../../shared/store';
import {UserModalComponent} from '../../users/user-modal/user-modal.component';
import {FormHelper} from '../../../shared/helpers/form-helper';
import {Observable} from 'rxjs';
import {Candidate} from '../../../shared/models/candidate';
import {TestType} from '../../../shared/enums/test-type';

@Component({
    selector: 'app-test-modal',
    templateUrl: './test-modal.component.html',
    styleUrls: ['./test-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TestModalComponent {
    public form: UntypedFormGroup;
    public candidates: Observable<Candidate[]>;
    public selectedTests: TestType[] = [];

    constructor(private formBuilder: UntypedFormBuilder,
                private dialog: MatDialogRef<UserModalComponent>,
                private store: Store<State>,
                @Inject(MAT_DIALOG_DATA) private data: any) {
        this.candidates = store.select(getSearchCandidates);

        this.form = this.formBuilder.group({
            id: this.data.id,
            candidate: [this.data.candidate, Validators.required],
            tests: [this.data.tests, Validators.required],
        });
    }

    handleSelection(tests: any[]): void {
        this.selectedTests = tests;
        // tslint:disable-next-line:no-string-literal
        this.form.controls['tests'].setValue(tests);
    }

    save(): void {
        if (this.form.valid) {
            const data = this.form.getRawValue();
            this.dialog.close({
                result: 'yes',
                data: {
                    candidate: data.candidate ? data.candidate.id : null,
                    tests: data.tests
                }
            });
        } else {
            FormHelper.validateAllFormFields(this.form);
        }
    }
}
