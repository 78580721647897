<div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v5"
     style="background-image: url(../../assets/media/error/bg5.jpg);">
    <div class="kt-error_container">
        <span class="kt-error_title">
            <h1>404!</h1>
        </span>

        <p class="kt-error_subtitle">
            Sorry we can't seem to find the
            <br>
            page you're looking for.
        </p>

        <p class="kt-error_description">
            There may be a misspelling in the URL entered,
            <br>
            or the page you are looking for may no longer exist.
        </p>
    </div>
</div>