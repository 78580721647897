import * as fromLoader from './reducers/loader.reducer';
import * as fromLayout from './reducers/layout.reducer';
import * as fromUsers from './reducers/users.reducer';
import * as fromCandidates from './reducers/candidates.reducer';
import * as fromHubs from './reducers/hubs.reducer';
import * as fromTests from './reducers/tests.reducer';
import * as fromTestBundles from './reducers/test-bundles.reducer';
import * as fromNotifications from './reducers/notifications.reducer';
import * as fromStatistics from './reducers/statistics.reducer';
import {ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {LogUtils} from '../utils/log-utils';
import {CommonUtils} from '../utils/common-utils';

/* Global panel and reducer */
export interface State {
    loader: fromLoader.State;
    layout: fromLayout.State;
    users: fromUsers.State;
    candidates: fromCandidates.State;
    hubs: fromHubs.State;
    tests: fromTests.State;
    bundles: fromTestBundles.State;
    notifications: fromNotifications.State;
    statistics: fromStatistics.State;
}

export const reducers: ActionReducerMap<State> = {
    loader: fromLoader.reducer,
    layout: fromLayout.reducer,
    users: fromUsers.reducer,
    candidates: fromCandidates.reducer,
    hubs: fromHubs.reducer,
    tests: fromTests.reducer,
    bundles: fromTestBundles.reducer,
    notifications: fromNotifications.reducer,
    statistics: fromStatistics.reducer,
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state: State, action: any): State => {
        LogUtils.action(action);
        return reducer(state, action);
    };
}

export function reset(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state: State, action: any): State => {
        /* On login or logout clear data */
        // Todo fix this
        /*if (action.type === user || action.type === USER_LOGIN) {
            state = undefined;
            LogUtils.info('Application state reset.');
        }*/
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger, reset] : [reset];

/* Global selector functions */
export const getLoaderState = createFeatureSelector<fromLoader.State>('loader');
export const getLayoutState = createFeatureSelector<fromLayout.State>('layout');
export const getUsersState = createFeatureSelector<fromUsers.State>('users');
export const getCandidatesState = createFeatureSelector<fromCandidates.State>('candidates');
export const getHubsState = createFeatureSelector<fromHubs.State>('hubs');
export const getTestsState = createFeatureSelector<fromTests.State>('tests');
export const getTestBundlesState = createFeatureSelector<fromTestBundles.State>('bundles');
export const getNotificationsState = createFeatureSelector<fromNotifications.State>('notifications');
export const getStatisticsState = createFeatureSelector<fromStatistics.State>('statistics');

/* Loader selector functions */
export const getLoading = createSelector(getLoaderState, fromLoader.getLoading);

/* Layout selector functions */
export const getShowSidebar = createSelector(getLayoutState, fromLayout.getShowSidebar);
export const getShowLeftMobileSidebar = createSelector(getLayoutState, fromLayout.getShowLeftMobileSidebar);
export const getShowRightMobileSidebar = createSelector(getLayoutState, fromLayout.getShowRightMobileSidebar);
export const getShowTopMobileSidebar = createSelector(getLayoutState, fromLayout.getShowTopMobileSidebar);

/* Users selector functions */
export const getUsers = createSelector(getUsersState, fromUsers.getUsers);

/* Hubs selector functions */
export const getHubs = createSelector(getHubsState, fromHubs.getHubs);

/* Candidates selector functions */
export const getCandidates = createSelector(getCandidatesState, fromCandidates.getCandidates);
export const getSearchCandidates = createSelector(getCandidatesState, fromCandidates.getSearchCandidates);
export const getSelectedCandidate = createSelector(getCandidatesState, fromCandidates.getSelectedCandidate);
export const getSelectedCandidateBundle = createSelector(getCandidatesState, fromCandidates.getSelectedCandidateBundle);
export const getShowSelectedCandidateBundle = createSelector(getSelectedCandidate, (candidate) => {
    return candidate && CommonUtils.isNotEmpty(candidate.bundles) && candidate.bundles.length > 1;
});

/* Tests selector functions */
export const getTestsSectionBundle = createSelector(getTestsState, fromTests.getTestsSectionBundle);
export const getTestsSectionError = createSelector(getTestsState, fromTests.getTestsSectionError);
export const getTestsSectionSection = createSelector(getTestsState, fromTests.getTestsSectionSection);
export const getTestSectionTest = createSelector(getTestsState, fromTests.getTestSectionTest);
export const getTestSectionError = createSelector(getTestsState, fromTests.getTestSectionError);
export const getTestSectionErrorData = createSelector(getTestsState, fromTests.getTestSectionErrorData);
export const getTestSectionSection = createSelector(getTestsState, fromTests.getTestSectionSection);

/* Test bundles selector functions */
export const getTestBundles = createSelector(getTestBundlesState, fromTestBundles.getTestBundles);

/* Notifications selector functions */
export const getNotifications = createSelector(getNotificationsState, fromNotifications.getNotifications);

/* Statistics selector functions */
export const getStatistics = createSelector(getStatisticsState, fromStatistics.getStatistics);
