import {Action} from '@ngrx/store';
import {User} from '../../models/user';

export enum UsersActionTypes {
    Load = '[Users] Load Users',
    Create = '[Users] Create User',
    Update = '[Users] Update User',
    Delete = '[Users] Delete User'
}

export class LoadUsersAction implements Action {
    readonly type = UsersActionTypes.Load;

    constructor(public payload: User[]) {
    }
}

export class CreateUserAction implements Action {
    readonly type = UsersActionTypes.Create;

    constructor(public payload: User) {
    }
}

export class UpdateUserAction implements Action {
    readonly type = UsersActionTypes.Update;

    constructor(public payload: User) {
    }
}

export class DeleteUserAction implements Action {
    readonly type = UsersActionTypes.Delete;

    constructor(public payload: User) {
    }
}

export type Actions
    = LoadUsersAction
    | CreateUserAction
    | UpdateUserAction
    | DeleteUserAction;

