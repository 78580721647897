import {Action} from '@ngrx/store';

export enum LayoutActionTypes {
    ToggleSidebar = '[Layout] Toggle sidebar',
    ToggleLeftMobileSidebar = '[Layout] Toggle left mobile sidebar',
    ToggleRightMobileSidebar = '[Layout] Toggle right mobile sidebar',
    ToggleTopMobileSidebar = '[Layout] Toggle top mobile sidebar',
}

export class ToggleSidebarAction implements Action {
    readonly type = LayoutActionTypes.ToggleSidebar;
}

export class ToggleLeftMobileSidebarAction implements Action {
    readonly type = LayoutActionTypes.ToggleLeftMobileSidebar;
}

export class ToggleRightMobileSidebarAction implements Action {
    readonly type = LayoutActionTypes.ToggleRightMobileSidebar;
}

export class ToggleTopMobileSidebarAction implements Action {
    readonly type = LayoutActionTypes.ToggleTopMobileSidebar;
}

export type Actions
    = ToggleSidebarAction
    | ToggleLeftMobileSidebarAction
    | ToggleRightMobileSidebarAction
    | ToggleTopMobileSidebarAction;
