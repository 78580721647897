<!-- Subheader -->
<div [header]="'Users'" app-home-subheader>
    <div class="kt-subheader__breadcrumbs" header>
        <a class="kt-subheader__breadcrumbs-home">
            <i class="flaticon2-shelter"></i>
        </a>
        <span class="kt-subheader__breadcrumbs-separator"></span>
        <a class="kt-subheader__breadcrumbs-link">
            Preview Users
        </a>
    </div>
</div>

<!-- Content -->
<div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <!-- Section -->
    <app-portlet [headerClass]="'kt-portlet__head--noborder'"
                 [header]="'Users'"
                 [icon]="'kt-font-brand flaticon-profile-1'">

        <!-- Toolbar -->
        <div class="kt-portlet__head-actions" toolbar>
            <a (click)="createUser()"
               class="btn btn-brand btn-elevate btn-icon-sm kt-font-inverse-primary">
                <i class="la la-plus"></i>
                New User
            </a>
        </div>

        <!-- Body -->
        <div body>
            <div class="user-table-container">
                <mat-table [dataSource]="dataSource" class="user-table" matSort>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.email}}</mat-cell>
                    </ng-container>

                    <!-- Role Column -->
                    <ng-container matColumnDef="role">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.role | mapEnum:'UserRole'}}</mat-cell>
                    </ng-container>

                    <!-- Hub -->
                    <ng-container matColumnDef="hub">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.hub?.name}}</mat-cell>
                    </ng-container>

                    <!-- Status -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <span [innerHtml]="getStateTag(row)"></span>
                        </mat-cell>
                    </ng-container>

                    <!-- Actions -->
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <!-- Update -->
                            <a (click)="updateUser($event, row)"
                               [class.disabled]="!row.is_active"
                               [matTooltipPosition]="'above'"
                               [matTooltipShowDelay]="500"
                               [matTooltip]="'Edit User'"
                               class="btn btn-sm btn-clean btn-icon btn-icon-md btn-in-table">
                                <i class="la la-pencil"></i>
                            </a>

                            <!-- Disable -->
                            <a (click)="disableUser($event, row)"
                               *ngIf="row.is_active"
                               [class.disabled]="!row.is_active"
                               [matTooltipPosition]="'above'"
                               [matTooltipShowDelay]="500"
                               [matTooltip]="'Disable User'"
                               class="btn btn-sm btn-clean btn-icon btn-icon-md btn-in-table">
                                <i class="la la-unlock"></i>
                            </a>

                            <!-- Enable -->
                            <a (click)="enableUser($event, row)"
                               *ngIf="!row.is_active"
                               [matTooltipPosition]="'above'"
                               [matTooltipShowDelay]="500"
                               [matTooltip]="'Enable User'"
                               class="btn btn-sm btn-clean btn-icon btn-icon-md btn-in-table">
                                <i class="la la-unlock-alt"></i>
                            </a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <div [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'"
                     class="user-no-results">
                    No user found matching filter.
                </div>

                <mat-paginator #paginator
                               [length]="dataSource.filteredData.length"
                               [pageIndex]="0"
                               [pageSizeOptions]="[5, 10, 25, 100]"
                               [pageSize]="25">
                </mat-paginator>
            </div>
        </div>
    </app-portlet>
</div>
