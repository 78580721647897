import {HttpErrorResponse} from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import {StorageHelper} from '../helpers/storage-helper';

export const ERROR_ORIGINAL_ERROR = 'ngOriginalError';

export class ErrorUtils {
    static extractErrorDetails(response: HttpErrorResponse): string {
        /* If error is 404, resource is missing or unavailable */
        if (response.status === 404) {
            return 'Resource not found';
        }

        return response?.error?.message ? response.error.message : 'Unexpected error occurred';
    }

    static mapErrorMessage(response: HttpErrorResponse): string {
        const errorDetails = ErrorUtils.extractErrorDetails(response);

        if (errorDetails) {
            return errorDetails;
        }

        return 'An internal server error has occurred';
    }

    static reportSentryError(error: unknown): void {
        const extractedError = ErrorUtils.extractSentryError(error) || 'Handled unknown error';

        // Capture handled exception and send it to Sentry.
        Sentry.captureException(extractedError, scope => {
            const user = StorageHelper.getUser();

            if (user) {
                scope.setUser({
                    id: String(user.id),
                    username: user.email,
                    email: user.email,
                });
            }

            return scope;
        });
    }

    static getOriginalError(error: unknown): Error {
        return (error as any)[ERROR_ORIGINAL_ERROR];
    }

    static extractSentryError(errorCandidate: unknown): unknown {
        let error = errorCandidate;

        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && this.getOriginalError(error)) {
            error = this.getOriginalError(error);
        }

        // We don't have anything
        if (!error) {
            return null;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent && error.error.message) {
                return error.error.message;
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return `Server returned code ${error.status} with body "${error.error}"`;
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        // Nothing was extracted, fallback to default error message.
        return null;
    }
}
