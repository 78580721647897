import {LoadTestSectionAction, SelectTestSectionAction} from '../../shared/store/actions/tests.actions';
import {Directive} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    getTestSectionError,
    getTestSectionErrorData,
    getTestSectionSection,
    getTestSectionTest,
    State
} from '../../shared/store';
import {StorageHelper} from '../../shared/helpers/storage-helper';
import {TestData} from '../../shared/models/test-data';
import {BehaviorSubject, Observable} from 'rxjs';
import {Location} from '@angular/common';
import {Test} from '../../shared/models/test';
import {TestState} from '../../shared/models/test-state';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class TestBaseContainer {
    public error: Observable<string>;
    public errorData: Observable<any>;
    public section: Observable<string>;
    public test: Observable<Test>;
    public localState: BehaviorSubject<TestState> = new BehaviorSubject<TestState>(null);

    protected constructor(protected store: Store<State>,
                          protected location: Location) {
        this.error = store.select(getTestSectionError);
        this.errorData = store.select(getTestSectionErrorData);
        this.section = store.select(getTestSectionSection);
        this.test = store.select(getTestSectionTest);
    }

    handleQuestionsSelection(option: string): void {
        if (option) {
            if (option === 'next') {
                this.store.dispatch(new SelectTestSectionAction('done'));
            } else if (option === 'previous') {
                this.store.dispatch(new SelectTestSectionAction('instructions'));
            }
        }
    }

    handleInstructionsSelection(option: string): void {
        if (option) {
            if (option === 'next') {
                this.startTest().subscribe(() => {
                    this.store.dispatch(new SelectTestSectionAction('questions'));
                });
            } else if (option === 'previous') {
                this.location.back();
            }
        }
    }

    handleTestLoad(data: { data: TestData }, code: string): void {
        /* Load test data */
        this.store.dispatch(new LoadTestSectionAction(data.data));
        /* Check do we have something local. Load local test state if there is no error */
        if (!(data.data && data.data.error)) {
            const testState = StorageHelper.getCurrentTestState(code, 0);
            if (testState && testState.state === 'questions') {
                this.localState.next(testState);
                this.store.dispatch(new SelectTestSectionAction('questions'));
            }
        }
    }

    abstract startTest(): Observable<Test>;
}
