<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div (actionSelected)="navigate()"
             [actionLabel]="'Visit symphony.is'"
             [description]="description"
             [icon]="'fa flaticon-confetti kt-font-brand'"
             [title]="'Finished'"
             app-notification-page>
        </div>
    </div>
</div>