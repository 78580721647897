import {Action} from '@ngrx/store';
import {Notification} from '../../models/notification';

export enum NotificationsActionTypes {
    Load = '[Notifications] Load Notifications',
    Update = '[Notifications] Update Notification'
}

export class LoadNotificationsAction implements Action {
    readonly type = NotificationsActionTypes.Load;

    constructor(public payload: Notification[]) {
    }
}

export class UpdateNotificationAction implements Action {
    readonly type = NotificationsActionTypes.Update;

    constructor(public payload: Notification) {
    }
}


export type Actions
    = LoadNotificationsAction
    | UpdateNotificationAction;

