import {Injectable} from '@angular/core';
import {MatSnackBarConfig} from '@angular/material/snack-bar';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    public dispatcher: Subject<MatSnackBarConfig> = new Subject<MatSnackBarConfig>();

    public static get basicConfiguration(): MatSnackBarConfig {
        const config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        config.horizontalPosition = 'right';
        config.panelClass = ['notification-panel'];
        config.duration = 5000;
        return config;
    }

    public static configuration(severity: string, title: string, msg: string, override): MatSnackBarConfig {
        let config = NotificationsService.basicConfiguration;
        config.panelClass = ['notification-panel', severity];
        config.data = {
            title,
            message: msg,
            showSpinner: false
        };
        if (override) {
            config = Object.assign({}, config, override);
        }
        return config;
    }

    success(title: string, msg: string, override: object = null): void {
        this.dispatcher.next(NotificationsService.configuration('success', title, msg, override));
    }

    info(title: string, msg: string, override: object = null): void {
        this.dispatcher.next(NotificationsService.configuration('info', title, msg, override));
    }

    warning(title: string, msg: string, override: object = null): void {
        this.dispatcher.next(NotificationsService.configuration('alert', title, msg, override));
    }

    error(title: string, msg: string, override: object = null): void {
        this.dispatcher.next(NotificationsService.configuration('error', title, msg, override));
    }

    custom(severity: string, data: object, override: object = null): void {
        let config = NotificationsService.basicConfiguration;
        config.panelClass = ['notification-panel', severity];
        config.data = data;

        /* Override default */
        if (override) {
            config = Object.assign({}, config, override);
        }

        /* Dispatch message */
        this.dispatcher.next(config);
    }

    dismiss(): void {
        this.dispatcher.next(null);
    }
}
