import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {State} from '../store';
import {Notification} from '../models/notification';
import {Endpoint} from '../constants/constants';
import {LoadNotificationsAction, UpdateNotificationAction} from '../store/actions/notifications.actions';
import {LogUtils} from '../utils/log-utils';
import {DashboardStatistic} from '../models/dashboard-statistic';
import {LoadStatisticsAction} from '../store/actions/statistics.actions';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    constructor(private http: HttpClient,
                private store: Store<State>) {
    }

    fetchNotifications(): void {
        this.http
            .get<Notification[]>(Endpoint.NOTIFICATIONS)
            .subscribe((data: Notification[]) => {
                this.store.dispatch(new LoadNotificationsAction(data));
            });
    }

    fetchStatistics(): void {
        this.http
            .get<DashboardStatistic>(Endpoint.DASHBOARD)
            .subscribe((data: DashboardStatistic) => {
                this.store.dispatch(new LoadStatisticsAction(data));
            });
    }

    readNotification(id: number): void {
        if (id) {
            this.http
                .put<Notification>(Endpoint.NOTIFICATIONS.concat(id.toString()), {is_read: true})
                .subscribe((data: Notification) => {
                    this.store.dispatch(new UpdateNotificationAction(data));
                });
        } else {
            LogUtils.error('Notification id is empty.');
        }
    }
}
