import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {NotificationsService} from '../services/notifications.service';
import {LogUtils} from '../utils/log-utils';
import {UsersService} from '../services/users.service';
import {ErrorUtils} from '../utils/error-utils';
import {environment} from '../../../environments/environment';

@Injectable()
export class LoggingErrorHandler implements ErrorHandler {

    constructor(private notificationsService: NotificationsService,
                private usersService: UsersService,
                private zone: NgZone) {
    }

    handleError(error: any): void {
        /* Error is locally handled */
        if (!error) {
            return;
        }

        /* Handle error */
        if (error.status === 400) {
            const errorDetails = ErrorUtils.extractErrorDetails(error);
            this.zone.run(() => this.notificationsService.error('Error', errorDetails));
        } else if (error.status === 401 || error.status === 403) {
            this.usersService.logout();
            this.zone.run(() => this.notificationsService.error('Error', 'Invalid or expired token'));
        } else if (error.status === 405 || error.status === 500) {
            this.zone.run(() => this.notificationsService.error('Error', 'An internal server error has occurred'));
        } else if (error.status === 502) {
            this.zone.run(() => this.notificationsService.error('Error', 'Unable to reach server'));
        } else if (error.status === 503) {
            this.zone.run(() => this.notificationsService.error('Error', 'Service unavailable'));
        }

        /* Pass info to sentry */
        this.reportSentryError(error);

        /* Output error for user */
        LogUtils.error(error);
    }

    reportSentryError(error: unknown): void {
        // Capture handled exception and send it to Sentry.
        if (environment?.sentry?.enabled) {
            try {
                this.zone.run(() => ErrorUtils.reportSentryError(error));
            } catch (e) {
                LogUtils.error(e);
            }
        }
    }
}
