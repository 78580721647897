<div class="kt-pricing-1 kt-pricing-1--fixed move-top-position">
    <div class="kt-pricing-1__items row kt-padding-t-0">
        <div class="kt-pricing-1__item col-lg-8 offset-2">
            <div class="kt-pricing-1__visual">
                <span class="kt-pricing-1__icon kt-font-danger">
                    <i class="fa flaticon-information"></i>
                </span>
            </div>

            <span class="kt-pricing-1__price">
                {{error | mapEnum:'TestError'}}
            </span>

            <h2 class="kt-pricing-1__subtitle">{{title}}</h2>

            <span class="kt-pricing-1__description">
                <span>Please contact <a [href]="mailUrl"
                                        [matTooltipPosition]="'above'"
                                        [matTooltipShowDelay]="10000"
                                        [matTooltip]="'Maybe a better option is not to report anything. I want to keep my job'"
                                        class="kt-link--brand">assessment@symphony.is</a>
                    and someone from our profilers will come to you.</span>
            </span>

            <div class="kt-pricing-1__btn">
                <button (click)="back()"
                        class="btn btn-pill btn-danger btn-wide btn-uppercase btn-bolder"
                        type="button">
                    Back
                </button>
            </div>
        </div>
    </div>
</div>