import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonUtils} from '../../../shared/utils/common-utils';
import {LogUtils} from '../../../shared/utils/log-utils';
import {Notification} from '../../../shared/models/notification';
import {DashboardService} from '../../../shared/services/dashboard.service';

@Component({
    selector: 'app-dashboard-notifications',
    templateUrl: './dashboard-notifications.component.html',
    styleUrls: ['./dashboard-notifications.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DashboardNotificationsComponent {
    @Input() notifications: Notification[];

    public filter = 'new';

    constructor(private dashboardService: DashboardService) {
    }

    get filteredNotifications(): Notification[] {
        if (CommonUtils.isNotEmpty(this.notifications)) {
            return this.notifications.filter(function (notification) {
                if (this.count < 10 && notification.is_read === this.is_read) {
                    this.count++;
                    return true
                }
                return false;
            }, {
                count: 0,
                is_read: this.filter === 'read'
            })
        }
        return [];
    }

    get showNoData(): boolean {
        return CommonUtils.isEmpty(this.filteredNotifications);
    }

    markAsRead(notification: Notification): void {
        if (notification && notification.id) {
            this.dashboardService.readNotification(notification.id);
        } else {
            LogUtils.error('Notification id is empty');
        }
    }

    filterNotifications(value: string): void {
        this.filter = value;
    }
}
