<!-- Toolbar -->
<ul *ngIf="showList" class="kt-sticky-toolbar">
    <li *ngFor="let testBundle of testBundles; let i = index"
        [class]="getClassName(testBundle, i)"
        [matTooltipPosition]="'left'"
        [matTooltipShowDelay]="500"
        [matTooltip]="testBundle.search_code">
        <a (click)="selectBundle(i)">
            <i class="flaticon2-layers-1"></i>
        </a>
    </li>
</ul>
