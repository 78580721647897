import {CtiPiece} from '../models/cti-piece';
import {CtiTests, Endpoint} from '../constants/constants';
import {EpiOption} from '../models/epi-option';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NotificationsService} from './notifications.service';
import {Observable, of} from 'rxjs';
import {State} from '../store';
import {Store} from '@ngrx/store';
import {Test} from '../models/test';

@Injectable({
    providedIn: 'root'
})
export class TestsService {
    constructor(private http: HttpClient,
                private store: Store<State>,
                private notificationsService: NotificationsService) {
    }

    /* EPI Test */
    fetchEpiTest(code: string): Observable<Test> {
        return code ? this.http.get<Test>(Endpoint.TESTS_EPI_VALIDATE.concat(code)) : of(null);
    }

    startEpiTest(code: string): Observable<Test> {
        return this.http.post<Test>(Endpoint.TESTS_EPI_STATE.concat(code), {started: true});
    }

    saveEpiTest(code: string, answers: EpiOption[]): Observable<Test> {
        const answerIds = answers.map(answer => answer.id);
        return this.http.post<any>(Endpoint.TESTS_EPI_ANSWERS.concat(code), answerIds);
    }

    /* EPQ Test */
    fetchEpqTest(code: string): Observable<Test> {
        return code ? this.http.get<Test>(Endpoint.TESTS_EPQ_VALIDATE.concat(code)) : of(null);
    }

    startEpqTest(code: string): Observable<Test> {
        return this.http.post<Test>(Endpoint.TESTS_EPQ_STATE.concat(code), {started: true});
    }

    saveEpqTest(code: string, answers: string[]): Observable<Test> {
        const items = answers.map(answer => answer === 'yes');
        return this.http.post<any>(Endpoint.TESTS_EPQ_ANSWERS.concat(code), items);
    }

    /* BFQ Test */
    fetchBfqTest(code: string): Observable<Test> {
        return code ? this.http.get<Test>(Endpoint.TESTS_BFQ_VALIDATE.concat(code)) : of(null);
    }

    startBfqTest(code: string): Observable<Test> {
        return this.http.post<Test>(Endpoint.TESTS_BFQ_STATE.concat(code), {started: true});
    }

    saveBfqTest(code: string, answers: number[]): Observable<Test> {
        return this.http.post<any>(Endpoint.TESTS_BFQ_ANSWERS.concat(code), answers);
    }

    /* CTI Test */
    fetchCtiTest(code: string): Observable<Test> {
        return code ? this.http.get<Test>(Endpoint.TESTS_CTI_VALIDATE.concat(code)) : of(null);
    }

    startCtiTest(code: string, subtest: number): Observable<Test> {
        return this.http.post<Test>(Endpoint.TESTS_CTI_STATE.concat(code), {subtest, started: true});
    }

    saveCtiTest(code: string, subtest: number, answers: CtiPiece[][]): Observable<{ success: boolean, test: Test, finished: boolean }> {
        const answerIds: number[][] = [...Array(CtiTests[subtest].questions)].map(
            (_, i) => [...Array(CtiTests[subtest].selections)].map(
                (_, j) => Array.isArray(answers[i]) && answers[i][j] ? answers[i][j].index : -1
            ));
        return this.http.post<{ success: boolean, test: Test, finished: boolean }>(
            Endpoint.TESTS_CTI_ANSWERS.concat(code), {subtest, answerIds}
        );
    }

    /* Notify */
    notifyUser(test: Test, action: string): void {
        this.notificationsService.success('Success', `Test ${test.search_code} ${action}.`);
    }
}
