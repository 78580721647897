<div *ngIf="renderData" class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head--noborder">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                EPQ Test
                <small><i class="fa flaticon-stopwatch"></i> {{duration | formatDuration}}</small>
            </h3>
        </div>
    </div>

    <!-- Show data -->
    <div class="kt-portlet__body">
        <!-- Nav Bar -->
        <div class="navigation-items kt-margin-b-25">
            <a (click)="switchTab(0)"
               [class.active]="selectedTab == 0"
               class="navigation-item"
               role="tab">
                <div class="navigation-item-body">
                    <div class="navigation-item-label">
                        All answers
                    </div>
                    <div class="navigation-item-number-placeholder"></div>
                </div>
            </a>

            <a (click)="switchTab(1)"
               [class.active]="selectedTab == 1"
               class="navigation-item"
               role="tab">
                <div class="navigation-item-body">
                    <div class="navigation-item-label">
                        P
                    </div>

                    <div class="navigation-item-number">
                        {{ analysis.P }}
                    </div>
                </div>
            </a>

            <a (click)="switchTab(2)"
               [class.active]="selectedTab == 2"
               class="navigation-item"
               role="tab">
                <div class="navigation-item-body">
                    <div class="navigation-item-label">
                        E
                    </div>

                    <div class="navigation-item-number">
                        {{ analysis.E }}
                    </div>
                </div>
            </a>

            <a (click)="switchTab(3)"
               [class.active]="selectedTab == 3"
               class="navigation-item"
               role="tab">
                <div class="navigation-item-body">
                    <div class="navigation-item-label">
                        N
                    </div>

                    <div class="navigation-item-number">
                        {{ analysis.N }}
                    </div>
                </div>
            </a>

            <a (click)="switchTab(4)"
               [class.active]="selectedTab == 4"
               class="navigation-item"
               role="tab">
                <div class="navigation-item-body">
                    <div class="navigation-item-label">
                        L
                    </div>

                    <div class="navigation-item-number">
                        {{ analysis.L }}
                    </div>
                </div>
            </a>
        </div>


        <!-- Answers -->
        <div class="candidate-answers-tab">
            <app-candidate-test-epq-answers [answers]="answers"
                                            [selectedTab]="selectedTab">
            </app-candidate-test-epq-answers>
        </div>
    </div>
</div>

<div *ngIf="!renderData" class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head--noborder">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                EPQ Test
            </h3>
        </div>
    </div>

    <!-- No Data -->
    <div class="kt-portlet__body">
        <div class="alert alert-secondary alert--marginless" role="alert">
            <div class="alert-icon">
                <i class="flaticon-notes kt-font-dark"></i>
            </div>

            <div class="alert-text">
                No test data
            </div>
        </div>
    </div>
</div>