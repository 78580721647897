import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Candidate} from '../../../shared/models/candidate';
import {LogUtils} from '../../../shared/utils/log-utils';
import {Test} from '../../../shared/models/test';
import {TestType} from '../../../shared/enums/test-type';
import {CommonUtils} from '../../../shared/utils/common-utils';
import {TestBundle} from '../../../shared/models/test-bundle';
import {Duration} from 'moment';

@Component({
    selector: 'app-candidate-test-epq',
    templateUrl: './candidate-test-epq.component.html',
    styleUrls: ['./candidate-test-epq.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateTestEpqComponent {
    public selectedTab = 0;

    @Input() candidate: Candidate = null;
    @Input() selectedBundle = 0;

    get test(): Test {
        return this.bundle && this.bundle.tests ? this.bundle.tests.find(test => test.type === TestType.EPQ) : {} as Test;
    }

    get bundle(): TestBundle {
        if (this.candidate && CommonUtils.isNotEmpty(this.candidate.bundles)) {
            return this.candidate.bundles[this.selectedBundle];
        }
        return {} as TestBundle;
    }

    /**
     * result: { answers: object result: object}
     */
    get result(): { answers: object, result: object } {
        try {
            return JSON.parse(this.test.result);
        } catch (e) {
            LogUtils.error(`Parse error for results: ${e.message}`);
            return null;
        }
    }

    get answers(): string[] {
        if (this.test && CommonUtils.isNotEmpty(this.test.answers)) {
            const answers = this.test.answers.slice(1, -1).split(', ');
            if (this.selectedTab === 1) {
                return [
                    answers[1],
                    answers[5],
                    answers[8],
                    answers[10],
                    answers[17],
                    answers[21],
                    answers[25],
                    answers[29],
                    answers[32],
                    answers[36],
                    answers[42],
                    answers[45],
                    answers[49],
                    answers[52],
                    answers[56],
                    answers[60],
                    answers[64],
                    answers[66],
                    answers[70],
                    answers[73],
                    answers[75],
                    answers[78],
                    answers[82],
                    answers[86],
                    answers[89]
                ];
            } else if (this.selectedTab === 2) {
                return [
                    answers[0],
                    answers[4],
                    answers[9],
                    answers[13],
                    answers[16],
                    answers[20],
                    answers[24],
                    answers[28],
                    answers[31],
                    answers[35],
                    answers[39],
                    answers[41],
                    answers[44],
                    answers[48],
                    answers[51],
                    answers[55],
                    answers[59],
                    answers[63],
                    answers[69],
                    answers[81],
                    answers[85]
                ];
            } else if (this.selectedTab === 3) {
                return [
                    answers[2],
                    answers[5],
                    answers[11],
                    answers[14],
                    answers[18],
                    answers[22],
                    answers[26],
                    answers[30],
                    answers[33],
                    answers[37],
                    answers[40],
                    answers[46],
                    answers[53],
                    answers[57],
                    answers[61],
                    answers[65],
                    answers[67],
                    answers[71],
                    answers[74],
                    answers[76],
                    answers[79],
                    answers[83],
                    answers[87]
                ];
            } else if (this.selectedTab === 4) {
                return [
                    answers[3],
                    answers[7],
                    answers[12],
                    answers[15],
                    answers[19],
                    answers[23],
                    answers[27],
                    answers[34],
                    answers[38],
                    answers[43],
                    answers[47],
                    answers[50],
                    answers[54],
                    answers[58],
                    answers[62],
                    answers[68],
                    answers[72],
                    answers[77],
                    answers[80],
                    answers[84],
                    answers[88]
                ];
            }
            return answers;
        }
        return [];
    }

    get analysis(): any {
        return this.result ? this.result.result : {};
    }

    get renderData(): boolean {
        return this.answers && this.answers.length > 0;
    }

    get duration(): Duration {
        return CommonUtils.calculateDuration(this.test);
    }

    switchTab(index): void {
        this.selectedTab = index || 0;
    }
}
