import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {getHubs, State} from '../../../shared/store';
import {FormHelper} from '../../../shared/helpers/form-helper';
import {Observable} from 'rxjs';
import {Hub} from '../../../shared/models/hub';

@Component({
    selector: 'app-candidate-modal',
    templateUrl: './candidate-modal.component.html',
    styleUrls: ['./candidate-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CandidateModalComponent {
    public form: UntypedFormGroup;
    public hubs: Observable<Hub[]>;

    constructor(private formBuilder: UntypedFormBuilder,
                private dialog: MatDialogRef<CandidateModalComponent>,
                private store: Store<State>,
                @Inject(MAT_DIALOG_DATA) private data: any) {

        this.hubs = store.select(getHubs);

        this.form = this.formBuilder.group({
            id: this.data.id,
            email: [data.email, [Validators.required, Validators.email]],
            code: [data.code, Validators.required],
            gender: [data.gender, Validators.required],
            hub_id: [data.hub ? data.hub.id : null, Validators.required],
        });
    }

    save(): void {
        if (this.form.valid) {
            const tmp = this.form.getRawValue();
            this.dialog.close({
                result: 'yes',
                data: tmp
            });
        } else {
            FormHelper.validateAllFormFields(this.form);
        }
    }
}
