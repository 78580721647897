import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {Emotions} from '../../../shared/constants/constants';

@Component({
    selector: 'app-test-epi-definitions',
    templateUrl: './test-epi-definitions.component.html',
    styleUrls: ['./test-epi-definitions.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestEpiDefinitionsComponent {
    @Input() choiceClass = 'col-3 kt-margin-0';

    get emotions(): any[] {
        return Object.keys(Emotions).map(key => Emotions[key]);
    }
}
