import {map, startWith} from 'rxjs/operators';
import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Candidate} from '../../models/candidate';
import {SearchUtils} from '../../utils/search-utils';

@Component({
    selector: 'app-candidate-search',
    templateUrl: './candidate-search.component.html',
    styleUrls: ['./candidate-search.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => CandidateSearchComponent),
    }]
})
export class CandidateSearchComponent implements OnInit, ControlValueAccessor {
    @Input() candidates: Candidate[];
    @Input() required = false;
    @Input() readonly = false;
    public searchControl: UntypedFormControl;
    public filteredCandidates: Observable<Candidate[]>;

    // tslint:disable-next-line:variable-name
    @Input() _selected: Candidate;

    get selected() {
        return this._selected;
    }

    set selected(val) {
        this._selected = val;
        this.propagateChange(this._selected);
    }

    /* Noop */
    public propagateChange = (_: any) => {
    }
    public propagateTouch = (_: any) => {
    }

    ngOnInit() {
        this.searchControl = new UntypedFormControl();
        this.filteredCandidates = this.searchControl.valueChanges.pipe(
            startWith(null),
            map(search => this.filterCandidates(search))
        );
    }

    filterCandidates(search: string) {
        return this.candidates.filter(function (item) {
            if (this.count < 20) {
                if (search && typeof search === 'string') {
                    if (SearchUtils.searchCandidate(item, search)) {
                        this.count++;
                        return true;
                    }
                    return false;
                }
                this.count++;
                return true;
            }
            return false;
        }, {count: 0});
    }

    formatCandidateData(candidate: Candidate): string {
        return candidate ? `[${candidate.code}] ${candidate.email}` : '';
    }

    remove(candidate: Candidate): void {
        this.selected = null;
        this.searchControl.setValue(null);
    }

    select(event: MatAutocompleteSelectedEvent): void {
        this.selected = event.option.value;
        this.searchControl.setValue(null);
    }

    writeValue(obj: any): void {
        this.selected = obj;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }
}
