import * as moment from 'moment';
import {CommonUtils} from './common-utils';
import {Candidate} from '../models/candidate';

export class SortUtils {
    static dateComparator(d1: Date, d2: Date): number {
        if (d1 && d2) {
            const x = moment.utc(d1);
            const y = moment.utc(d2);
            if (y > x) {
                return 1;
            } else if (y < x) {
                return -1;
            }
            return 0;
        } else if (d1) {
            return -1;
        } else if (d2) {
            return 1;
        }
        return 0;
    }

    static stringComparator(s1: string, s2: string): number {
        if (!s1 && !s2 || s1 === s2) {
            return 0;
        } else if (!s1) {
            return -1;
        } else if (!s2) {
            return 1;
        }
        return s1 > s2 ? -1 : 1;
    }

    static numberComparator(n1: number, n2: number): number {
        return n1 === n2 ? 0 : n1 > n2 ? -1 : 1;
    }

    static sortCandidates(entries: Candidate[]): Candidate[] {
        return CommonUtils.isNotEmpty(entries) ? entries.slice().sort((a, b) => this.dateComparator(a.created, b.created)) : [];
    }
}
