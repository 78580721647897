<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        CTI Test
                    </h3>
                </div>

                <div class="kt-portlet__head-toolbar">
                    <div class="btn-group">
                        <div class="kt-infobox__badge">Test {{subtest + 1}} of 4</div>
                    </div>
                </div>
            </div>

            <!--begin::Form-->
            <form class="kt-form">
                <div class="kt-portlet__body">
                    <!-- Questions -->
                    <div class="form-group">
                        <app-test-cti-puzzle
                                (puzzleAnswered)="recordAnswer($event)"
                                *ngFor="let question of testQuestions"
                                [answers]="answers"
                                [puzzle]="question"
                                [subtest]="subtest">
                        </app-test-cti-puzzle>
                    </div>
                </div>

                <div class="kt-portlet__foot">
                    <div class="kt-form__actions kt-form__actions--right">
                        <div class="row">
                            <div class="col kt-align-right">
                                <button (click)="nextStep()"
                                        class="btn btn-label-brand btn-wide"
                                        type="button">
                                    {{lastStep ? 'Finish' : 'Next'}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
