<table class="table">
    <thead>
    <tr>
        <th>#</th>
        <th>Question</th>
        <th>Answer</th>
    </tr>
    </thead>

    <!-- Answers -->
    <tbody>
    <tr *ngFor="let question of selectedQuestionIndexes;">
        <td class="question-order" scope="row">{{question + 1}}</td>
        <td>{{mapQuestion(question)}}</td>
        <td class="question-answer">{{mapAnswer(question)}}</td>
    </tr>
    </tbody>
</table>