import {ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {EpiOption} from '../../../shared/models/epi-option';
import {EmotionQuestions} from '../../../shared/constants/constants';

@Component({
    selector: 'app-test-epi-instructions',
    templateUrl: './test-epi-instructions.component.html',
    styleUrls: ['./test-epi-instructions.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestEpiInstructionsComponent {
    @Output() selected = new EventEmitter<string>();

    get firstExample(): EpiOption[] {
        return EmotionQuestions[0];
    }

    get secondExample(): EpiOption[] {
        return EmotionQuestions[39];
    }

    nextStep(): void {
        this.selected.next('next');
    }

    previousStep(): void {
        this.selected.next('previous');
    }
}
