<div class="kt-grid kt-grid--hor kt-grid--root">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <!-- Background -->
                <div app-tests-background></div>

                <!-- Content -->
                <div class="kt-container  kt-grid__item kt-grid__item--fluid kt-padding-0">

                    <!-- Move up -->
                    <div class="kt-negative-spacing--9"></div>

                    <!-- Test Content Goes Here -->
                    <div [ngSwitch]="section | async">
                        <!-- Validate Screen -->
                        <app-tests-validate *ngSwitchCase="'validate'"></app-tests-validate>

                        <!-- Test Selection -->
                        <app-tests-selection *ngSwitchCase="'selection'"
                                             [bundle]="bundle | async">
                        </app-tests-selection>

                        <!-- Error -->
                        <app-tests-error *ngSwitchCase="'error'"
                                         [error]="error | async">
                        </app-tests-error>

                        <!-- Done -->
                        <app-tests-success *ngSwitchCase="'success'"></app-tests-success>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
