import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-test-cti-timeout',
    templateUrl: './test-cti-timeout.component.html',
    styleUrls: ['./test-cti-timeout.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestCtiTimeoutComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
