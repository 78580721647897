import * as users from '../actions/users.actions';
import {User} from '../../models/user';

export interface State {
    users: User[];
}

const initialState: State = {
    users: [],
};

export function reducer(state = initialState, action: users.Actions): State {
    switch (action.type) {
        case users.UsersActionTypes.Load:
            return Object.assign({}, state, {users: [...action.payload]});
        case users.UsersActionTypes.Create: {
            const data = action.payload as User;
            return Object.assign({}, state, {users: [...state.users, data]});
        }
        case users.UsersActionTypes.Update: {
            const data = action.payload as User;
            const index = state.users.findIndex((item) => item.id === data.id);
            if (index > -1) {
                const updated = [
                    ...state.users.slice(0, index),
                    Object.assign({}, state.users[index], data),
                    ...state.users.slice(index + 1)
                ];
                return Object.assign({}, state, {users: updated, selected: data});
            }
            return state;
        }
        default:
            return state;
    }
}

export const getUsers = (state: State) => state.users;
