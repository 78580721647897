<div class="row">
    <div class="col-lg-6 offset-lg-3 kt-margin-t-50">
        <div class="alert alert-danger" role="alert">
            <div class="alert-text">
                <h5 class="alert-heading">Validation Error</h5>
                <p>{{error | mapEnum:'TestError'}}</p>
            </div>

            <div class="alert-close">
                <button (click)="back()"
                        class="btn btn-light btn-elevate btn-wide btn-elevate-air"
                        type="button">
                    Back
                </button>
            </div>
        </div>
    </div>
</div>
