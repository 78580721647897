<div class="row">
    <div class="col-sm-8 offset-sm-2">
        <div (actionSelected)="back()"
             [actionLabel]="'Back to Test Selection'"
             [description]="'That\'s all for this test! We hope you enjoyed it and are satisfied with your performance. Thank you for your time!'"
             [icon]="icon"
             [subtitle]="title"
             [title]="'Finished'"
             app-notification-page>
        </div>
    </div>
</div>