import * as bundles from '../actions/test-bundles.actions';
import {TestBundle} from '../../models/test-bundle';

export interface State {
    bundles: TestBundle[];
}

const initialState: State = {
    bundles: [],
};

export function reducer(state = initialState, action: bundles.Actions): State {
    switch (action.type) {
        case bundles.TestBundlesActionTypes.Load:
            return Object.assign({}, state, {bundles: [...action.payload]});
        case bundles.TestBundlesActionTypes.Create: {
            const data = action.payload as TestBundle;
            return Object.assign({}, state, {bundles: [data, ...state.bundles]});
        }
        case bundles.TestBundlesActionTypes.Update: {
            const data = action.payload as TestBundle;
            const index = state.bundles.findIndex((item) => item.id === data.id);
            if (index > -1) {
                const updated = [
                    ...state.bundles.slice(0, index),
                    Object.assign({}, state.bundles[index], data),
                    ...state.bundles.slice(index + 1)
                ];
                return Object.assign({}, state, {bundles: updated});
            }
            return state;
        }
        case bundles.TestBundlesActionTypes.Delete: {
            const user = action.payload as TestBundle;
            const filtered = state.bundles.filter(item => item.id !== user.id);
            return Object.assign({}, state, {bundles: filtered});
        }
        default:
            return state;
    }
}

export const getTestBundles = (state: State) => state.bundles;
