<!-- Title -->
<div class="test-modal-title" mat-dialog-title>
    <h5>Test Bundle</h5>
</div>

<!-- Content -->
<mat-dialog-content class="test-modal-content">
    <div [formGroup]="form" class="kt-form kt-form--label-align-right">
        <!-- Code -->
        <div class="form-group kt-form__group">
            <label>Code</label>
            <div class="kt-label-font-color-1">Code will be generated.</div>
        </div>

        <!-- Candidate Search -->
        <div class="form-group m-form__group">
            <label>Candidate</label>
            <app-candidate-search [candidates]="candidates | async"
                                  formControlName="candidate">
            </app-candidate-search>

            <app-validation-error [control]="form.controls.candidate"></app-validation-error>
        </div>


        <!-- Type -->
        <div class="form-group kt-form__group">
            <label>Tests</label>
            <app-test-choice (selectedChange)="handleSelection($event)"
                             [selected]="selectedTests">
            </app-test-choice>

            <app-validation-error [control]="form.controls.tests"></app-validation-error>
        </div>
    </div>
</mat-dialog-content>

<!-- Footer -->
<mat-dialog-actions align="end">
    <button class="btn btn-secondary btn-wide" mat-dialog-close>Cancel</button>
    <button (click)="save()" class="btn btn-brand btn-wide">Save</button>
</mat-dialog-actions>
